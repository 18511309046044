import React from 'react';
import styled from 'styled-components';
import Tooltip from './Tooltip';
import { FlexMotionRow, mediumIconSize, smallIconSize } from '../../styles';

const ToolButton = styled(FlexMotionRow)`
    width: ${({ $big }) => $big ? mediumIconSize : smallIconSize}px;
    height: ${({ $big }) => $big ? mediumIconSize : smallIconSize}px;
    border-radius: 50%;
    margin: var(--xs);
    justify-content: center;
    cursor: pointer;
    background-color: ${({ $on }) => $on ? 'var(--stage-button-active)' : 'var(--stage-button-background)'} ;
    box-shadow: var(--glass-shadow-lite);
`;

export default function StageToolButton({ Icon, title, color, isOn, filled, bold, big, left, right, top, bottom, id, onPress }) {
    const tooltipPlacement = left ? 'right-center' : right ? 'left-center' : top ? 'bottom-center' : bottom ? 'top-center' : '';
    const buttonColor = color ?? (isOn ? 'var(--stage-button-active-color)' : 'var(--stage-button-color)');
    return (
        <Tooltip text={title} placement={tooltipPlacement}>
            <ToolButton
                id={id}
                $big={big}
                $on={isOn}
                whileHover={{
                    scale: 1.1,
                    transition: { duration: 0.1, ease: 'easeInOut' },
                }}
                whileTap={{
                    scale: 1,
                    transition: { duration: 0.1, ease: 'easeInOut' },
                    boxShadow: '2px 2px 3px 1px transparent',
                }}
                onClick={onPress}
            >
                <Icon size={big ? 24 : 18} weight={filled ? 'fill' : bold ? 'bold' : 'regular'} color={buttonColor} />
            </ToolButton>
        </Tooltip>
    );
};
