import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';
import Editor from '../../../uicontrols/CommentEditor/Editor';
import TextBoxMenu from './TextBoxMenu';
import { Avatar } from '../../../uicontrols/Avatar';
import { DotsThreeCircleVertical, UserCircle } from '@phosphor-icons/react';
import HoverClarifier from '../../../uicontrols/HoverClarifier';
import { FlexMotionRow, FlexRow } from '../../../../styles';
import Tooltip from '../../../uicontrols/Tooltip';
import { useProofXStore } from '../../../Store/ProofXStore';
import ReactionsPicker from '../../../uicontrols/ReactionsPicker';

// #region Styles

const Draggable = styled.div`
    position: absolute;
    visibility: ${({ $visible }) => $visible ? 'visible' : 'hidden'};
`;

const Container = styled(motion.div)`
    position: relative;
`;

const TextBoxFrame = styled(motion.div)`
    top: 0;
    left: 0;
    transform-origin: left top;    
    position: absolute;
    width: ${({ size }) => size.width}px;
    padding: 2px;
    backdrop-filter: var(--glass-blur);
    background-color: ${({ $complete }) => $complete ? 'var(--textbox-complete-background)' : 'var(--textbox-background)'};
    opacity: ${({ $internal }) => $internal ? 0.8 : 1};
    border: ${({ $highlighted }) => $highlighted ? '2px solid var(--highlighted-color)' : '1px solid var(--glass-border)'};
    border-radius: 8px;
    box-shadow: ${({ $highlighted }) => $highlighted ? 'var(--highlighted-shadow)' : 'var(--glass-shadow)'};
`;

const Header = styled(FlexRow)`
    border-radius: 5px;
    justify-content: flex-end;
    background-color: ${({ color }) => color}88;
    color: var(--contrast-text);
    border-bottom: 1px solid var(--glass-border);
`;

const DragHandle = styled.div`
    font-size: 10px;    
    text-align: end;
    padding: 2px;
    flex-grow: 1;
    cursor: ${({ dragging, dragEnabled }) => dragEnabled ? (dragging ? 'grabbing' : 'grab') : 'default'};
`;

const ResizeHandle = styled.div`
    position: absolute;
    width: 10px;
    overflow: hidden;
    height: 10px;
    border: 1px solid transparent;
    right: 0px;
    bottom: 0px;
    cursor: ew-resize;

    &::before {
        position: absolute;
        content: '';
        top: -1px;
        left: 2px;
        width: 2.4px;
        height: 20px;
        border-left: 1px solid var(--glass-border);
        border-right: 1px solid var(--glass-border);
        transform: rotate(45deg);
        transform-origin: center center;
    } 

    &::after {
        position: absolute;
        content: '';
        top: -2px;
        left: 2px;
        width: 2.4px;
        height: 20px;
        border-left: 1px solid var(--control-border);
        border-right: 1px solid var(--control-border);
        transform: rotate(45deg);
        transform-origin: center center;
    } 
`;

const MenuButton = styled(FlexMotionRow)`
    justify-content: center;  
    width: 14px;
    height: 14px;
    border-radius: 7px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        border-radius: 4px;
        width: 8px;
        height: 8px;
        top: 3px;
        bottom: 3px;
        z-index: -1;
        background-color: var(--colored-button-text);
    }
`;

const MessageDate = styled.div`

`;

const MessageText = styled.div`
    font-family: 'Montserrat Medium';
    font-size: 12px;
    padding: 4px 8px;
    cursor: ${({ editable }) => editable ? 'text' : 'default'};
    user-select: text;
    overflow-wrap: break-word;
`;

const ReactionsWrapper = styled.div`
    padding: 4px;
`;

const AvatarContainer = styled(motion.div).attrs(() => ({ layout: true }))`
    position: absolute;
    border-radius: 50%;
    bottom: -15px;
    left: -15px;    
    width: 30px;  
    height: 30px;
    border-radius: 50%;
    transform-origin: 50% 50%;    
    box-shadow: var(--glass-shadow-popup);
`;

const AvatarTapper = styled(motion.div).attrs(() => ({ layout: true }))`    
    background: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
`;

const ExternalUserIcon = styled.div`
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 10;

    &::before {
        position: absolute;
        content: "";
        border-radius: 15px;
        width: 30px;
        height: 30px;
        top: 4px;
        left: 4px;
        opacity: 0.4;
        z-index: -1;
        background-color: ${({ color }) => color ?? ''};
    }
`;

const BodyColumn = styled.div`
    display: flex;
    flex-direction: column;    
`;

// #endregion

const textBoxVariants = {
    expanded: { scale: 1, transformOrigin: 'top left' },
    hovered: { scale: 1, filter: 'brightness(1.15)', transformOrigin: 'top left' },
    collapsed: { scale: 0, transformOrigin: 'top left' },
};

const avatarVariants = {
    hidden: { scale: 0 },
    visible: {
        scale: 1,
        boxShadow: '0px 0px 15px 2px var(--glass-shadow-color)',
        transition: {
            type: 'spring', stiffness: 500, damping: 20, duration: 0.05,
        },
    },
    hovered: {
        scale: 1.1,
        boxShadow: '0px 0px 15px 4px var(--glass-shadow-color)',
        transition: {
            type: 'spring', stiffness: 500, damping: 20, bounce: 0.35, duration: 0.05,
        },
    },
    tapped: {
        scale: 1,
        boxShadow: '0px 0px 15px 2px transparent',
        transition: {
            type: 'spring', stiffness: 500, damping: 20, duration: 0.05,
        },
    },
};

export default observer(function TextBox({ cubit }) {
    const strings = useProofXStore((state) => state.strings);
    const draggableRef = React.useRef();
    const containerRef = React.useRef();
    const handleClass = `textbox${cubit.id}-drag-handle`;
    const frameRef = React.useRef();

    return (
        <Draggable ref={draggableRef} $visible={cubit.visible}>
            <Container
                ref={containerRef}
                onMouseEnter={() => cubit.hover(true, true)}
                onMouseLeave={() => cubit.hover(false, true)}
                animate={cubit.dragging ? { scale: 1.05 } : { scale: 1 }}
                transition={{
                    duration: 0.2,
                    type: 'spring',
                    bounce: 0.35,
                }}
            >
                <TextBoxFrame
                    ref={frameRef}
                    size={cubit.size}
                    color={cubit.color}
                    variants={textBoxVariants}
                    initial='collapsed'
                    animate={cubit?.textFrameState}
                    exit='collapsed'
                    $complete={cubit.commentComplete}
                    $internal={cubit.commentInternal}
                    $highlighted={cubit.highlighted}
                    transition={{
                        duration: 0.5,
                        type: 'spring',
                        bounce: 0.35,
                    }}
                >
                    <Header color={cubit.color}>
                        <DragHandle
                            dragEnabled={!cubit.editMode && !cubit.isNew}
                            dragging={cubit.dragging}
                            onPointerDown={(e) => cubit.startDragging(
                                draggableRef.current,
                                { x: e.clientX, y: e.clientY },
                            )}
                        >
                            <MessageDate>{cubit.date}</MessageDate>
                        </DragHandle>
                        {cubit.editMenu && !cubit.isNew && (
                            <TextBoxMenu cubit={cubit.editMenu} trigger={(
                                <Tooltip text={strings.annotationActions}>
                                    <HoverClarifier initialOpacity={0.7} scaleFactor={0.1}>
                                        <MenuButton onClick={() => cubit.toggleEditMenu()}>
                                            <DotsThreeCircleVertical size={16} color={cubit.colorVariants.darker} weight='fill' style={{ padding: -2 }} />
                                        </MenuButton>
                                    </HoverClarifier>
                                </Tooltip>
                            )} />
                        )}
                    </Header>
                    {
                        cubit.editMode
                            ? (
                                <Editor padding='4px 8px' cubit={cubit.editorCubit} />
                            )
                            : (
                                <BodyColumn>
                                    <MessageText
                                        onClick={() => cubit.editable && cubit.toggleEditMode(true)}
                                        editable={cubit.editable}
                                        dangerouslySetInnerHTML={{ __html: cubit.text }}
                                    />
                                    <ReactionsWrapper>
                                        <ReactionsPicker reactions={cubit.reactions ?? []} onChange={(reaction) => cubit.toggleReaction(reaction)} />
                                    </ReactionsWrapper>
                                </BodyColumn>
                            )
                    }
                    {cubit.editable && (
                        <ResizeHandle onPointerDown={(e) => cubit.startResizing(
                            frameRef.current, e.clientX,
                        )} />
                    )}
                </TextBoxFrame>

                <AvatarContainer
                    className={handleClass}
                    variants={avatarVariants}
                    animate={cubit?.state ?? 'hidden'}
                    initial='hidden'
                    exit='hidden'
                    transition={{
                        duration: 0.5,
                        type: 'spring',
                        bounce: 0.35,
                    }}
                >
                    <Tooltip text={cubit.userName ?? ''}>
                        <AvatarTapper
                            animate={cubit.state}
                            variants={avatarVariants}
                            onMouseDown={(e, info) => {
                                cubit.animateTap();
                            }}
                            onMouseUp={() => {
                                cubit.hover(true, true);
                                if (!cubit.dragging) cubit.toggleExpanded();
                            }}
                            transition={{
                                duration: 0.1,
                                type: 'spring',
                                bounce: 0.55,
                            }}
                        >
                            {cubit.isExternalUser
                                ? (
                                    <ExternalUserIcon color={cubit.colorVariants?.hover}>
                                        <UserCircle size={38} weight='fill' color={cubit.color} />
                                    </ExternalUserIcon>
                                )
                                : (
                                    <Avatar
                                        size={26}
                                        uid={cubit.userUid}
                                        color={cubit.color}
                                    />
                                )
                            }

                        </AvatarTapper>
                    </Tooltip>
                </AvatarContainer>
            </Container>
        </Draggable>
    );
});
