import { useProofXStore } from '../Store/ProofXStore';

export let debug = document.cookie.includes('pryingOpenMyThirdEye=true');
export let hubConnecting = true;

window.makeMeSee = () => {
    document.cookie = 'pryingOpenMyThirdEye=true';
    debug = true;
    window.ProofXStore = () => useProofXStore?.getState();
    window.stopHub = () => {
        hubConnecting = false;
    };
};

window.makeMeForget = () => {
    document.cookie = 'pryingOpenMyThirdEye=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
    console.clear();
    debug = false;
    window.ProofXStore = undefined;
};

export default function log(...args) {
    if (!debug) return;

    console.groupCollapsed(...args);
    console.trace();
    console.groupEnd();
}

export function logGroupStart(...args) {
    debug && console.groupCollapsed(...args);
}

export function logGroupEnd() {
    debug && console.groupEnd();
}
