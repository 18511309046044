import React, { useCallback } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Comment from './Comment';

const Wrapper = styled.div`
    padding: 0 8px 8px 8px;
`;

export default observer(function Discussion({ cubit: discussionCubit }) {
    const downScrollerRef = useCallback((node) => {
        if (node) {
            node.scrollIntoView({ behavior: 'smooth', block: 'start' });
            discussionCubit.setShouldScrollDown(false);
        }
    }, [discussionCubit]);

    const renderTree = (cubitsArray) =>
        cubitsArray.map(cubit =>
            (
                <Comment {...{ cubit }}
                    key={cubit?.comment.commentUid}
                >
                    {renderTree(cubit.replies)}
                </Comment>
            ),
        );

    return (
        <Wrapper>
            {renderTree(discussionCubit.commentsTree)}
            {discussionCubit.shouldScrollDown && <div ref={downScrollerRef} />}
        </Wrapper>
    );
});
