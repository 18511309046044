import { observable, computed, action, makeObservable } from 'mobx';
import { api } from '../../Api/ProofXApi';
import { useProofXStore } from '../../Store/ProofXStore';
import { validateEmail } from '../../Viewer/AnnotationLayer/Utils';

export default class EmailPickerCubit {
    // #region Fields

    _isOpen = false;
    _isFetching = false;
    _allContacts = null;
    _visibleContacts = null;
    _allGroups = null;
    _visibleGroups = null;
    _addedContacts = null;
    _addedGroups = null;
    _inputText = '';
    _selectionIndex = 0;
    _recordModalCubit = null;

    // #endregion

    constructor() {
        makeObservable(this, {
            _isOpen: observable,
            _isFetching: observable,
            _allContacts: observable,
            _allGroups: observable,
            _visibleContacts: observable,
            _visibleGroups: observable,
            _addedContacts: observable,
            _addedGroups: observable,
            _inputText: observable,
            _selectionIndex: observable,

            isOpen: computed,
            emails: computed,
            selectionIndex: computed,
            isFetching: computed,
            visibleContacts: computed,
            addedContacts: computed,
            addedGroups: computed,
            inputText: computed,
            isAddressBookEnabled: computed,

            open: action,
            close: action,
            load: action,
            complete: action,
            setSelectionIndex: action,
            editRecord: action,
            openAddressBook: action,
            removeRecord: action,
            removeGroup: action,
            addEmailsFromInput: action,
            handleChange: action,
            handleInputBlur: action,
            handleKeyDown: action,

            _setAddedContacts: action,
            _setAddedGroups: action,
        });
        this.load();
    };

    // #region Interface

    // #region getters

    get isOpen() { return this._isOpen; }
    get inputText() { return this._inputText; }
    get selectionIndex() { return this._selectionIndex; }
    get isFetching() { return this._isFetching; }
    get visibleContacts() { return this._visibleContacts; }
    get visibleGroups() { return this._visibleGroups; }
    get addedContacts() { return this._addedContacts; }
    get addedGroups() { return this._addedGroups; }
    get store() { return useProofXStore.getState(); }
    get recordModalCubit() { return this.store.proofX?.addressBookRecordModal; }
    get addressBookModalCubit() { return this.store.proofX?.addressBookModal; }
    get isAddressBookEnabled() { return this.store.proofX?.environment?.flags.addressBookEnabled; }
    get isLimitReached() {
        const isOnTrial = this.store.proofX?.environment?.flags?.isClientOnTrial ?? true;
        return this.addedContacts.length >= (isOnTrial ? 1 : 20);
    }

    get emails() {
        const emailsArray = [];
        if ((this._addedContacts?.length ?? 0) + (this._addedGroups?.length ?? 0) > 0 && this._inputText === '') {
            emailsArray.push(...this._addedContacts.map(e => e.email));
            emailsArray.push(...this._addedGroups.map(g => g.emails.split(',')).flat());
        }
        return [...new Set(emailsArray)].join(',');
    }

    get emailsValid() {
        if (this._inputText.trim() !== '') return false;
        return this._addedContacts?.length > 0
            ? this._addedContacts.every(e => validateEmail(e.email))
            : this._addedGroups?.length > 0;
    }

    // #endregion

    // #region actions

    async load() {
        const env = this.store.proofX.environment;

        this._isFetching = true;
        const clientUid = env.clientUid;
        const { contacts, groups } = env?.flags?.demoMode
            ? { contacts: [], groups: [] }
            : await api.getAddressBook(clientUid);
        this._isFetching = false;
        this._allContacts = contacts;
        this._visibleContacts = contacts;
        this._allGroups = groups;
        this._visibleGroups = groups;
        this._setAddedContacts(this._addedContacts?.map(r => contacts.find(x => x.email === r.email) || r) ?? []);
        this._setAddedGroups(this._addedGroups?.map(r => groups.find(x => x.groupId === r.groupId) || r) ?? []);
        if (this.addressBookModalCubit?.isOpen) {
            this.addressBookModalCubit.update({ contacts, groups });
        }
    }

    open() {
        this._isOpen = true;
        const previousEmails = this._addedContacts.map(e => e.email.toLowerCase());
        const previousGroups = this._addedGroups.map(g => g.name.toLowerCase());

        this._visibleContacts = this._allContacts?.filter(v =>
            !previousEmails.includes(v?.email.toLowerCase() ?? ''),
        ) ?? [];

        this._visibleGroups = this._allGroups.filter(g =>
            !previousGroups.includes(g.name.toLowerCase()),
        ) ?? [];
    }

    close() {
        this._isOpen = false;
    }

    complete(record, event) {
        const index = this._selectionIndex;
        const contacts = this._visibleContacts;
        const groups = this._visibleGroups;

        if (this.isOpen && contacts.length + groups.length > 0 && (groups[index] || contacts[index - groups.length])) {
            if (!record) {
                record = index < groups.length ? groups[index] : contacts[index - groups.length];
            }
            if (event && event.keyCode === 32) { // if space is pressed, check if there's visible items with spaces
                const inputText = this._inputText.toLowerCase() + ' ';
                const matchingItems = [
                    ...groups.filter(e => e.name.toLowerCase().startsWith(inputText)),
                    ...contacts.filter(e => e.name.toLowerCase().startsWith(inputText)),
                ];
                if (matchingItems.length > 1) {
                    return;
                }
            }
            if (index < groups.length) {
                this._setAddedGroups([...this._addedGroups, record]);
            } else {
                this._setAddedContacts([...this._addedContacts, record]);
            }
            this.close();
        } else {
            this.addEmailsFromInput();
        }
        if (event) {
            this._haltEvent(event);
        }
        this._inputText = '';
        this._visibleContacts = [];
        this._visibleGroups = [];
    }

    addEmailsFromInput() {
        const previous = this._addedContacts.map(e => e.email);
        const list = this._inputText.split(/[,; ]/).map(e => e.trim());
        const invalidEntries = [];
        list.forEach(newEmail => {
            if (!this._validateEmail(newEmail)) {
                if (newEmail.length > 0) {
                    invalidEntries.push(newEmail);
                }
                return;
            }
            if (!previous.includes(newEmail)) {
                this._setAddedContacts([...this._addedContacts, { email: newEmail }]);
            }
        });
        this._inputText = invalidEntries.join(', ');
    }

    editRecord(record) {
        this.recordModalCubit?.setOnChangedHandler(this.load.bind(this));
        this.recordModalCubit?.show(record);
    }

    removeRecord(email) {
        this._setAddedContacts(this._addedContacts.filter(r => r.email !== email));
    }

    removeGroup(groupId) {
        this._setAddedGroups(this._addedGroups.filter(g => g.groupId !== groupId));
    }

    openAddressBook() {
        this.addressBookModalCubit?.show({
            contacts: this._allContacts,
            groups: this._allGroups,
            selectedEmails: this._addedContacts.map(e => e.email),
            selectedGroupIds: this._addedGroups.map(g => g.groupId),
            onEditRecord: this.editRecord.bind(this),
            onApply: this._addRecordsFromAddressBook.bind(this),
        });
    }

    _addRecordsFromAddressBook(emails, groupIds) {
        const specialContacts = this._addedContacts.filter(contact => !this._allContacts.find(c => c.email === contact.email));
        console.log('🍀', specialContacts);

        this._setAddedContacts([...this._allContacts.filter(c => emails.includes(c.email)), ...specialContacts]);
        this._setAddedGroups(this._allGroups.filter(g => groupIds.includes(g.groupId)));
    }

    setSelectionIndex(index) {
        this._selectionIndex = index;
    }

    handleChange(newValue) {
        const token = newValue.toLowerCase();
        const previousEmails = this._addedContacts.map(e => e.email.toLowerCase());
        const previousGroups = this._addedGroups.map(g => g.name.toLowerCase());

        this._visibleContacts = (token && this._allContacts)
            ? this._allContacts.filter(v => {
                const name = v?.name?.toLowerCase() ?? '';
                const email = v?.email.toLowerCase() ?? '';
                return (name.includes(token) || email.includes(token)) && !previousEmails.includes(email);
            })
            : [];

        this._visibleGroups = (token && this._allGroups)
            ? this._allGroups.filter(g => g.name.toLowerCase().includes(token) && !previousGroups.includes(g.name.toLowerCase()))
            : [];
        this._inputText = token;
    }

    handleInputBlur() {
        this.addEmailsFromInput();
        setTimeout(() => this.close(), 200);
    }

    handleKeyDown(e) {
        this._isOpen = true;
        const listLength = this._visibleContacts.length + this._visibleGroups.length;
        switch (e.keyCode) {
            case 9: // tab
            case 13: // enter
            case 32: // space
            case 186: // comma
            case 188: // parenthesis
                this.complete(null, e);
                break;
            case 8: // backspace
                if (this._inputText.length === 0) {
                    if (this._addedContacts.length > 0) {
                        this._setAddedContacts(this._addedContacts.slice(0, -1));
                    } else if (this._addedGroups.length > 0) {
                        this._setAddedGroups(this._addedGroups.slice(0, -1));
                    }
                }
                break;
            case 27: // esc
                this.close();
                break;
            case 38: // up arrow
                this._selectionIndex = this._selectionIndex > 0 ? this._selectionIndex - 1 : listLength - 1;
                this._haltEvent(e);
                break;
            case 40: // down arrow
                this._selectionIndex = this._selectionIndex < listLength - 1 ? this._selectionIndex + 1 : 0;
                this._haltEvent(e);
                break;
        }
    };

    // #endregion

    // #endregion Interface

    // #region private methods

    _setAddedContacts(records) {
        this._addedContacts = records;
    }

    _setAddedGroups(groups) {
        this._addedGroups = groups;
    }

    _validateEmail(email) {
        if (!email) return false;
        const emailRegex = /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])([;,])?)+$/i;
        return emailRegex.test(email.toLowerCase());
    }

    _haltEvent(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    // #endregion
}
