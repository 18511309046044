import { useProofXStore } from '../Store/ProofXStore';
import { TourCloseButton } from './TourCloseButton';

export default class TourCubit {
    _tourLabels = {
        internalUser: [
            { selector: '#viewer-center', label: 'tourInternalIntro', position: 'center', padding: { mask: 0 } },
            { selector: '#zoom-toolbar', label: 'tourZoom', action: () => this.mainStage._osdViewer.setControlsEnabled(true) },
            { selector: '#approve-button', label: 'tourApprove' },
            { selector: '#annotation-button', label: 'tourAnnotate' },
            { selector: '#compare-button', label: 'tourCompare' },
            { selector: '#request-changes-button', label: 'tourRequestChanges' },
            { selector: '#project-discussion-button', label: 'tourProjectDiscussion' },
            { selector: '#extra-tools', label: 'tourTools' },
            { selector: '#share-button', label: 'tourShare' },
            { selector: '#download-button', label: 'tourDownload' },
        ],
        externalUser: [
            { selector: '#viewer-center', label: 'tourExternalIntro', position: 'center', padding: { mask: 0 } },
            { selector: '#zoom-toolbar', label: 'tourZoom', action: () => this.mainStage._osdViewer.setControlsEnabled(true) },
            { selector: '#approve-button', label: 'tourApprove' },
            { selector: '#annotation-button', label: 'tourAnnotate' },
            { selector: '#request-changes-button', label: 'tourRequestChanges' },
            { selector: '#download-button', label: 'tourDownload' },
            { selector: '#compare-button', label: 'tourCompare' },
        ],
    };

    get proofX() { return useProofXStore.getState()?.proofX; }
    get strings() { return useProofXStore.getState()?.strings; }
    get mainStage() { return useProofXStore.getState()?.stages.main; }

    get props() {
        return {
            components: { Close: TourCloseButton },
            styles: {
                popover: (base) => ({
                    ...base,
                    boxShadow: 'var(--glass-shadow)',
                    backgroundColor: 'var(--popover-background)',
                    borderRadius: 10,
                    lineHeight: '1.5em',
                }),
                highlightedArea: (base, { x, y }) => ({
                    ...base,
                    borderRadius: 10,
                    x: x + 10,
                    y: y + 10,
                }),
                badge: (base) => ({
                    ...base,
                    backgroundColor: 'var(--primary-color)',
                }),
                close: (base) => ({
                    ...base,
                    color: 'var(--secondary-color)',
                    right: 8,
                    top: 8,
                }),
                dot: (base, { current }) => {
                    return ({
                        ...base,
                        backgroundColor: current ? 'var(--primary-color)' : '',
                    });
                },
            },
        };
    }

    get steps() {
        if (!this.strings) return;
        const steps = [];
        const labels = this.proofX?.environment?.flags?.isExternalReview ? this._tourLabels.externalUser : this._tourLabels.internalUser;
        labels.forEach(l => {
            if (document.querySelector(l.selector)) {
                steps.push({ content: this.strings[l.label], ...l });
            }
        });
        console.log('🦶', steps);
        return steps;
    }
}
