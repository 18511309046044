import create from 'zustand';
import { debug } from '../ProofX/Logger';

export const AssetTypes = {
    main: 'main',
    compare: 'compare',
};

export const useProofXStore = create((set) => ({
    proofX: null,
    strings: null,
    splitView: null,
    mainThumbnailsPanel: null,
    compareThumbnailsPanel: null,
    rightPanel: null,
    discussionPanel: null,
    fileInfo: null,
    annotationManager: null,
    fabricLayer: null,
    compareModesManager: null,
    viewer: null,
    stages: {
        main: null,
        compare: null,
        middle: null,
    },
    assets: {
        main: null,
        compare: null,
    },
    assetComments: [],
    demoComments: {},
    projectComments: [],

    // actions

    setStage: (stageType, stage) => {
        set(prev => ({
            stages: {
                ...prev.stages,
                [stageType]: stage,
            },
        }));
    },

    setAsset: (assetType, asset) => {
        set(prev => ({
            assets: {
                ...prev.assets,
                [assetType]: asset,
            },
        }));
    },
}));

if (debug) window.ProofXStore = () => useProofXStore?.getState();
