import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../../Store/ProofXStore';
import SelectBox from '../../uicontrols/SelectBox';
import { Wrapper } from '../ToolboxStyles';
import { ImageUnits } from './RulerCubit';
import { FlexRow, tinyIconSize } from '../../../styles';
import { Lightbulb } from '@phosphor-icons/react';

const Label = styled.div`
    font-size: var(--font-size-md);
    margin-right: var(--sm);
`;

const Row = styled(FlexRow)`
    margin-bottom: var(--sm);
    justify-content: space-between;
    font-size: var(--font-size-md);
`;

const Bold = styled.strong`
    padding: 0 var(--xs);
`;

export default observer(function RulerPanel() {
    const strings = useProofXStore((state) => state.strings);
    const viewer = useProofXStore((state) => state.viewer);
    const unitsMap = {
        [strings.inches]: 0,
        [strings.centimeters]: 1,
        [strings.millimeters]: 3,
    };
    const rulerUnits = Object.keys(unitsMap);
    const hintParts = strings.rulerMultilineHint.split('**');

    return (
        <Wrapper>
            {viewer?.ruler.units !== ImageUnits.pixels && (
                <Row>
                    <Label>{strings.rulerUnits}</Label>
                    <SelectBox
                        options={rulerUnits}
                        value={rulerUnits.find(u => unitsMap[u] === viewer.ruler.units) ?? rulerUnits[0]}
                        valueHolderStyle={{ fontSize: '10px', padding: '4px 6px', marginLeft: '8px' }}
                        onChange={(value) => viewer?.ruler.setUnits(unitsMap[value])}
                    />
                </Row>
            )}
            <Row>
                <Label><Lightbulb size={tinyIconSize} /></Label>
                {hintParts[0]}<Bold>{hintParts[1]}</Bold>{hintParts[2]}
            </Row>
        </Wrapper>
    );
});
