/* global fabric */

import FigureCubit, { styles } from '../FigureCubit';
import { getImageDimensions, getRotatedDimensions } from '../Utils';

export default class CircleFigure extends FigureCubit {
    createShape() {
        this._figure = new fabric.Ellipse({
            type: 'figure',
            figureType: 'circle',
            fill: 'transparent',
            strokeWidth: styles.figureStroke / this._layer.getZoom(),
            stroke: this._annotationCubit.color,
            selectable: false,
            perPixelTargetFind: true,
            hasControls: false,
            hasBorders: false,
            hasRotatingPoint: false,
            opacity: styles.opacity.default,
            noScaleCache: false,
        });
        this._layer.add(this._figure);
        return this._figure;
    }

    _continueDraw(pointer) {
        const startingPoint = this._drawingProps.startingPoint;
        pointer = pointer ?? startingPoint;
        const r = {
            x: Math.abs(startingPoint.x - pointer.x) / 2,
            y: Math.abs(startingPoint.y - pointer.y) / 2,
        };
        const freePosition = {
            left: Math.min(startingPoint.x, pointer.x),
            top: Math.min(startingPoint.y, pointer.y),
            rx: r.x,
            ry: r.y,
        };
        const imageSize = getImageDimensions();
        this._position = {
            x: Math.max(0, freePosition.left),
            y: Math.max(0, freePosition.top),
        };
        this._dimensions = {
            x: Math.min(freePosition.rx * 2, (imageSize.x - freePosition.left)),
            y: Math.min(freePosition.ry * 2, (imageSize.y - freePosition.top)),
        };
        this.placeShape(this._position, this._dimensions);
    };

    placeShape(position, dimensions) {
        this._position = position;
        this._dimensions = { x: Math.max(1, dimensions.x), y: Math.max(1, dimensions.y) };
        const mod = getRotatedDimensions(position, this._dimensions);
        this._figure.set({
            left: mod.position.x,
            top: mod.position.y,
            rx: mod.dimensions.x / 2,
            ry: mod.dimensions.y / 2,
        });
    }

    findLineEnd(startPoint) {
        const rx = this._figure.width / 2;
        const ry = this._figure.height / 2;
        const c = new fabric.Point(
            this._figure.left + rx,
            this._figure.top + ry,
        );
        const a1 = new fabric.Point(startPoint.x, startPoint.y);
        const a2 = c;
        let intersection = fabric.Intersection.intersectEllipseLine(c, rx, ry, a1, a2);
        if (intersection.status === 'Intersection') {
            const { x, y } = intersection.points[0];
            return { x, y };
        }
        // if no intersection found, return top left point of the ellipse
        const basePoint2D = new fabric.Point(this._figure.left, this._figure.top);
        intersection = fabric.Intersection.intersectEllipseLine(c, rx, ry, c, basePoint2D);
        if (intersection.points.length > 0) {
            const { x, y } = intersection.points[0];
            return { x, y };
        } else {
            return c;
        }
    };
}
