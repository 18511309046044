import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useProofXStore } from '../Store/ProofXStore';
import Stage from '../Viewer/Stage';
import { StageTypes } from '../Viewer/StageCubit';
import { StageContainer, StageSlider } from '../Viewer/Viewer';

export default observer(function SideBySideLayout({ containerHeight }) {
    const mainStageContainerRef = useRef();
    const compareStageContainerRef = useRef();
    const viewer = useProofXStore((state) => state.viewer);
    const mainAsset = useProofXStore((state) => state.assets.main);
    const compareAsset = useProofXStore((state) => state.assets.compare);
    const compareModesManager = useProofXStore((state) => state.compareModesManager);
    if (!viewer || !mainAsset) return;

    return (<>
        <StageContainer sideBySide ref={mainStageContainerRef} isLeft>
            <StageSlider {...{ assetUid: mainAsset.uid, containerHeight }} direction={mainAsset.appearenceDirection}>
                <Stage key={`stage-${mainAsset.uid}-${mainAsset.page ?? 0}-${compareModesManager.showDifference ? 'diff' : ''}`}
                    asset={mainAsset}
                    wrapperRef={mainStageContainerRef}
                    stageType={StageTypes.main}
                    hasFabricLayer={true}
                    showControls={true}
                />
            </StageSlider>
        </StageContainer>
        <StageContainer sideBySide ref={compareStageContainerRef} isRight>
            <StageSlider {...{ assetUid: compareAsset?.uid, containerHeight }} direction={compareAsset?.appearenceDirection ?? 1}>
                <Stage key={`compare-stage-${mainAsset.uid}-${mainAsset.page ?? 0}-${compareAsset?.uid}-${compareAsset?.page ?? 0}`}
                    asset={compareAsset}
                    wrapperRef={compareStageContainerRef}
                    stageType={StageTypes.compare}
                    showControls={true}
                />
            </StageSlider>
        </StageContainer>
    </>);
});
