import React from 'react';
import { observer } from 'mobx-react-lite';
import { useProofXStore } from '../Store/ProofXStore';
import styled from 'styled-components';
import ThumbnailsPanel from '../ThumbnailsPanel/ThumbnailsPanel';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;    
`;

export default observer(function CompareToolsPanel() {
    const compareThumbnailsPanel = useProofXStore((state) => state.compareThumbnailsPanel);
    return (
        <Wrapper>
            <ThumbnailsPanel cubit={compareThumbnailsPanel} height='calc(var(--app-height) - var(--appbar-height) - var(--right-panel-header-height));' />
        </Wrapper>
    );
});
