import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: ${({ direction }) => direction === 'row' ? 'row' : 'column'};
    align-items: center;  
`;

const columnVariants = {
    expanded: { height: 'auto', overflow: 'visible', opacity: 1 },
    collapsed: { height: 0, overflow: 'hidden', opacity: 0 },
};

const rowVariants = {
    expanded: { width: 'auto', overflow: 'visible', opacity: 1 },
    collapsed: { width: 0, overflow: 'hidden', opacity: 0 },
};

export default function Collapsable({ row, column, isOpen, children }) {
    if ((!row && !column) || (row && column)) return children;
    return (
        <Wrapper
            direction={row ? 'row' : 'column'}
            initial='collapsed'
            animate={isOpen ? 'expanded' : 'collapsed'}
            variants={row ? rowVariants : columnVariants}
            transition={{
                duration: 0.25,
                ease: 'easeInOut',
            }}
        >
            {children}
        </Wrapper>
    );
};
