import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../../Store/ProofXStore';
import { FlexColumn, FlexRow } from '../../../styles';
import SelectBox from '../../uicontrols/SelectBox';
import { ColorModels } from './EyedropperCubit';
import Button from '../../uicontrols/Button';
import Spinner from '../../uicontrols/Spinner';
import { Row } from '../ToolboxStyles';

const ToolButton = styled(Button).attrs(() => ({
    primary: true,
    fontSize: 10,
}))`
`;

const Wrapper = styled(FlexColumn)`
    padding: var(--sm);
    min-width: 200px;
`;

const Form = styled(FlexColumn)`
    align-items: flex-start;
    width: 100%;
`;

const Prompt = styled.div`
    font-size: 12px;
    margin-right: 8px;
    font-family: var(--medium);
`;

const ProgressPrompt = styled(FlexRow)`
    margin-left: 8px;
`;

const Value = styled.div`
    font-size: 12px;
    padding: 4px 6px;
    user-select: text;
`;

export default observer(function EyedropperPanel() {
    const strings = useProofXStore((state) => state.strings);
    const viewer = useProofXStore((state) => state.viewer);
    const cubit = viewer?.eyedropper;

    return (
        <Wrapper>
            {cubit?.ready
                ? cubit.areColorsGenerated
                    ? (<Form>
                        <Row>
                            <Prompt>{strings.colorModel}:</Prompt>
                            <SelectBox grow
                                options={ColorModels}
                                value={cubit?.colorModel ?? ColorModels[0]}
                                valueHolderStyle={{ fontSize: '10px', padding: '4px 6px', marginRight: '8px' }}
                                onChange={(value) => cubit?.setColorModel(value)}
                            />
                        </Row>
                        <Row>
                            <Prompt>{strings.colorValue}:</Prompt>
                            <Value>{cubit?.isGettingColor
                                ? <Spinner size={16} />
                                : cubit?.value || ''}
                            </Value>
                        </Row>
                        {cubit && !cubit.isGettingColor && !cubit.value && <Row>
                            <Value>{strings.pickColorFromAsset}</Value>
                        </Row>}
                    </Form>)
                    : (<Row>
                        <ToolButton onClick={() => cubit.generateColors()}>{strings.generateColors}</ToolButton>
                    </Row>)
                : <Row>
                    <Spinner size={16} /><ProgressPrompt>{strings.processing}</ProgressPrompt>
                </Row>
            }
        </Wrapper>
    );
});
