import { action, computed, makeObservable, observable, override } from 'mobx';
import ModalCubit from './ModalCubit';

export default class AddressBookModalCubit extends ModalCubit {
    _contacts = [];
    _groups = [];
    _tab = 'contacts'
    _selectedEmails = [];
    _selectedGroupIds = [];
    _applyHandler = () => { };
    _editContactHandler = () => { };

    constructor(caller) {
        super();
        makeObservable(this, {
            _contacts: observable,
            _groups: observable,
            _tab: observable,
            _selectedEmails: observable,
            _selectedGroupIds: observable,
            _applyHandler: observable,
            _editContactHandler: observable,

            contacts: computed,
            groups: computed,
            tab: computed,
            selectedEmails: computed,
            selectedGroupIds: computed,

            switchTab: action,
            createContact: action,
            toggleEmailSelected: action,
            toggleGroupSelected: action,
            update: action,

            show: override,
        });
        this._callerCubit = caller;
    }

    get contacts() { return this._contacts; }
    get groups() { return this._groups; }
    get tab() { return this._tab; }
    get selectedEmails() { return this._selectedEmails; }
    get selectedGroupIds() { return this._selectedGroupIds; }

    show({ contacts, groups, selectedEmails, selectedGroupIds, onEditRecord, onApply }) {
        this._contacts = contacts;
        this._groups = groups;
        this._selectedEmails = selectedEmails ?? [];
        this._selectedGroupIds = selectedGroupIds ?? [];
        this._tab = 'contacts';
        this._applyHandler = onApply;
        this._editContactHandler = onEditRecord;
        super.show();
    }

    update({ contacts, groups }) {
        this._contacts = contacts;
        this._groups = groups;
    }

    switchTab(tab) {
        this._tab = tab;
    }

    createContact() {
        this._editContactHandler();
    }

    toggleEmailSelected(email) {
        this._selectedEmails = this._selectedEmails.includes(email)
            ? this._selectedEmails.filter(e => e !== email)
            : [...this._selectedEmails, email];
    }

    toggleGroupSelected(groupId) {
        this._selectedGroupIds = this._selectedGroupIds.includes(groupId)
            ? this._selectedGroupIds.filter(id => id !== groupId)
            : [...this._selectedGroupIds, groupId];
    }

    editContact(contact) {
        this._editContactHandler(contact);
    }

    apply() {
        this._applyHandler(this._selectedEmails, this._selectedGroupIds);
        this.hide();
    }
}
