import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import ModalBase, { Footer, FooterButton } from './ModalBase';
import { useProofXStore } from '../Store/ProofXStore';
import { motion } from 'framer-motion';
import Spinner from '../uicontrols/Spinner';
import { DownloadSimple } from '@phosphor-icons/react';
import { FlexRow } from '../../styles';

// #region Styles

const Wrapper = styled.div``;
const Title = styled.div`
    font-size: 18px;
    color: var(--contrast-text);
    font-weight: 500;
    margin: 0;
`;
const Body = styled(FlexRow)`
    padding: 24px 0px;
    max-width: 600px;
    font-size: 13px;
    justify-content: center;
`;

const Image = styled(motion.img).attrs(({ uid }) => ({
    src: uid ? `/api/GetCommentAttachmentPreview/${uid}` : '',
}))`
    max-width: 100%;
`;

const DistributedFooter = styled(Footer)`
    justify-content: space-between;
`;

// #endregion

export default observer(function AttachmentViewerModal({ cubit }) {
    const strings = useProofXStore.getState().strings;
    const imageRef = useCallback((node) => {
        if (!cubit) return;
        if (node) {
            node.addEventListener('load', () => {
                cubit.setImageLoaded(true);
            });
        } else {
            cubit.setImageLoaded(false);
        }
    }, [cubit]);

    return cubit && (
        <ModalBase cubit={cubit}>
            <Wrapper>
                <Title>{cubit.title}</Title>
                <Body>
                    {!cubit.imageLoaded && <Spinner />}
                    <Image ref={imageRef} uid={cubit.attachmentUid}
                        animate={cubit.imageLoaded ? { opacity: 1 } : { opacity: 0 }}
                        transition={{
                            duration: 0.5,
                            type: 'spring',
                            bounce: 0.35,
                        }}
                    />
                </Body>
                <DistributedFooter>
                    <FooterButton secondary onClick={() => cubit.download()}>
                        <DownloadSimple size={16} style={{ marginRight: 4 }}/>
                        {strings.download}
                    </FooterButton>
                    <FooterButton link onClick={() => cubit.hide()}>
                        {strings.close}
                    </FooterButton>
                </DistributedFooter>
            </Wrapper>
        </ModalBase>
    );
});
