import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
// import { motion } from 'framer-motion';

import { useProofXStore } from '../Store/ProofXStore';

// #region Styles

const Wrapper = styled.div`
    height: 100%;
    overflow: ${({ isMobile }) => isMobile ? 'auto' : 'hidden'};
    backdrop-filter: var(--glass-blur);
`;

const Content = styled.div`
    height: 100%;
    width: ${({ isOpened }) => isOpened ? 'auto' : 0};
    background-color: var(--glass-background);
`;

// #endregion

const DrawerSides = {
    left: 'left',
    right: 'right',
};

export default observer(function Drawer({ side, children }) {
    const splitView = useProofXStore((state) => state.splitView);
    const isMobile = useProofXStore((state) => state.proofX?.isMobile ?? false);
    if (!splitView) return;

    const isOpened = (side === DrawerSides.left) ? splitView.leftDrawerOpened : splitView.rightDrawerOpened;

    return (
        <Wrapper isMobile={isMobile}>
            <Content {...{ isOpened }}>
                {children}
            </Content>
        </Wrapper>
    );
});
