import { action, computed, makeObservable, observable } from 'mobx';
import { api } from '../../Api/ProofXApi';
import { useProofXStore } from '../../Store/ProofXStore';

export default class AIAssistantCubit {
    _chatUid = null;
    _question = '';
    _isWaitingForResponse = false;
    _chatHistory = [];
    _shouldScrollDown = false;

    constructor() {
        makeObservable(this, {
            _isWaitingForResponse: observable,
            _chatHistory: observable,
            _question: observable,
            _shouldScrollDown: observable,

            isWaitingForResponse: computed,
            chatHistory: computed,
            question: computed,

            setQuestion: action,
            askQuestion: action,
            setWaitingForResponse: action,
            addToHistory: action,
            setShouldScrollDown: action,
        });
    }

    get chatUid() { return this._chatUid; }
    get isWaitingForResponse() { return this._isWaitingForResponse; }
    get chatHistory() { return this._chatHistory; }
    get question() { return this._question; }
    get shouldScrollDown() { return this._shouldScrollDown; }
    get strings() { return useProofXStore.getState().strings; }

    setQuestion(question) {
        this._question = question;
    }

    setWaitingForResponse(isWaitingForResponse) {
        this._isWaitingForResponse = isWaitingForResponse;
    }

    addToHistory(message) {
        this._chatHistory.push(message);
    }

    setShouldScrollDown(shouldScrollDown) {
        this._shouldScrollDown = shouldScrollDown;
    }

    async askQuestion() {
        if (this._question.trim() === '' || this._isWaitingForResponse) return;
        this.addToHistory({ isUser: true, message: this._question });
        const question = this._question;
        this.setQuestion('');
        this.setWaitingForResponse(true);
        this.setShouldScrollDown(true);
        try {
            const result = await api.getChatGPTResponse(this._chatUid || null, question);
            if (result?.response) {
                this._chatUid = result.id;
                this.addToHistory({ isUser: false, message: result?.response });
            } else {
                this.addToHistory({ isUser: false, isError: true, message: this.strings.aiAssistantError });
            }
        } catch (e) {
            this.addToHistory({ isUser: false, isError: true, message: this.strings.aiAssistantError });
        }
        this.setWaitingForResponse(false);
        this.setShouldScrollDown(true);
    }
}
