import { action, computed, makeObservable, observable } from 'mobx';
import { useProofXStore } from '../../Store/ProofXStore';
import { api } from '../../Api/ProofXApi';

export default class FileInfoCubit {
    _fileInfo = {};
    _assetUid = null;
    _originalExists = false;

    constructor() {
        makeObservable(this, {
            _fileInfo: observable,
            _assetUid: observable,
            _originalExists: observable,

            fileInfo: computed,
            originalExists: computed,

            _setFileInfo: action,
            setAssetUid: action,
            setOriginalExists: action,
        });
    }

    get store() { return useProofXStore.getState(); }

    get fileInfo() {
        if (this._fileInfo[this._assetUid]) {
            return this._fileInfo[this._assetUid];
        }
        this._loadFileInfo(this._assetUid);
        return '';
    }

    get originalExists() {
        return this._originalExists;
    }

    setAssetUid(assetUid) {
        this._assetUid = assetUid;
    }

    setOriginalExists(originalExists) {
        this._originalExists = originalExists;
    }

    async _loadFileInfo() {
        const assetUid = this._assetUid;
        if (!assetUid) return;
        const result = await api.getFileInfo(assetUid);
        if (result) {
            this._setFileInfo(assetUid, result);
        }
    }

    _setFileInfo(assetUid, fileInfo) {
        this._fileInfo[assetUid] = fileInfo;
    }
}
