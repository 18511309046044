import { observable, computed, makeObservable, action } from 'mobx';
import ThumbnailCubit from './ThumbnailCubit';

export default class ThumbnailsPanelCubit {
    _assets = [];
    _thumbnailCubits = [];
    _isComparePanel = false;

    constructor(thumbnails, isComparePanel) {
        makeObservable(this, {
            _assets: observable,
            _thumbnailCubits: observable.ref,

            assets: computed,
            thumbnailCubits: computed,

            updateAssets: action,
        });

        this._assets = thumbnails;
        this._isComparePanel = isComparePanel ?? false;
        this._thumbnailCubits = thumbnails.map(a => new ThumbnailCubit(a, isComparePanel));
    };

    get assets() { return this._assets; }
    get thumbnailCubits() { return this._thumbnailCubits; }
    get isComparePanel() { return this._isComparePanel; }

    selectFirstAssetPage(pageNum) {
        if (this._thumbnailCubits.length === 0) return;
        this._thumbnailCubits[0].onPageClicked(parseInt(pageNum) + 1);
    }

    dropSelection() {
        this._thumbnailCubits?.forEach(c => c.setSelectedPage(0));
    }

    clearLoadedLayer() {
        this._thumbnailCubits?.forEach(c => c.setLoadedLayer(null));
    }

    updateAssets(assets) {
        this._thumbnailCubits.forEach(c => {
            const asset = assets.find(a => a.uid === c.asset.uid);
            c.updateAsset(asset);
        });
    }
}
