import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useLayer } from 'react-laag-v18';
import styled from 'styled-components';

import { useProofXStore } from '../Store/ProofXStore';
import { ColorButton } from '../Viewer/AnnotationLayer/TextBox/TextBoxMenu';
import Tooltip from './Tooltip';
import { FlexMotionRow, mediumIconSize } from '../../styles';
import { observer } from 'mobx-react-lite';

const Flyout = styled(motion.div)`
    position: relative;
    border-radius: 8px;        
    padding: 6px;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    background-color: var(--popover-background);
    box-shadow: 0px 0px 15px 2px var(--glass-shadow-color);
    transform-origin: bottom center;
`;

const ToolButton = styled(FlexMotionRow)`
    width: ${mediumIconSize}px;
    height: ${mediumIconSize}px;
    border-radius: 50%;
    margin: var(--xs);
    justify-content: center;
    cursor: pointer;
    background-color: var(--stage-button-background);
    box-shadow: var(--glass-shadow-lite);

`;

const Pattern = styled.div`
    width: ${({ trigger }) => trigger ? '18px' : '20px'};
    height: ${({ trigger }) => trigger ? '18px' : '20px'};
    border-radius: 1px;
    border: 1px solid var(--checkbox-color);
    border-radius: 2px;
    margin: 1px;
    background: ${({ img, pattern }) => img ? `url(data:image/jpeg;base64,${img})` : `var(--bg-pattern-${pattern})`};
    background-size: ${({ img }) => img ? 'contain' : ''};
`;

export const BackgroundPatterns = [
    'default',
    'black',
    'white',
    'red',
    'gray-cells',
    'white-cells',
    'red-cells',
];

export default observer(function BackgroundSelector() {
    const [isOpen, setOpen] = useState(false);
    const viewer = useProofXStore((state) => state.viewer);
    const strings = useProofXStore((state) => state.strings);
    const environment = useProofXStore((state) => state.proofX?.environment);

    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen,
        onOutsideClick: () => setOpen(false), // close the menu when the user clicks outside
        onDisappear: () => setOpen(false), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => setOpen(false), // close the menu when the parent closes (e.g. a modal)
        overflowContainer: false, // keep the menu positioned inside the container
        auto: true, // automatically find the best placement
        placement: 'bottom-center', // we prefer to place the menu "top-end"
        possiblePlacements: ['bottom-center'],
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 4, // give the menu some room to breath relative to the container
    });

    const pickPattern = (pattern) => {
        viewer.setBackgroundPattern(pattern);
        setOpen(false);
    };

    const img = viewer?.backgroundPattern === 'custom' ? environment?.customBackgroundImg : null;

    return (<>
        <div {...triggerProps}>
            <Tooltip text={strings.pickBackground} placement='bottom-center'>
                <ToolButton
                    whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.1, ease: 'easeInOut' },
                    }}
                    whileTap={{
                        scale: 1,
                        transition: { duration: 0.1, ease: 'easeInOut' },
                        boxShadow: '2px 2px 3px 1px transparent',
                    }}
                    onClick={() => setOpen(true)}
                >
                    <Pattern trigger img={img} pattern={viewer.backgroundPattern ?? 'default'} />
                </ToolButton>
            </Tooltip>
        </div>

        {renderLayer(
            <div {...layerProps}>
                <AnimatePresence>
                    {isOpen && (
                        <Flyout
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}
                            transition={{
                                duration: 0.25,
                                type: 'spring',
                                bounce: 0.35,
                            }}
                        >
                            {BackgroundPatterns.map(pattern => (
                                <ColorButton key={pattern} onClick={() => pickPattern(pattern)}>
                                    <Pattern
                                        pattern={pattern}
                                        onClick={() => setOpen(true)}
                                    />
                                </ColorButton>
                            ))}
                            {environment?.customBackgroundImg && (
                                <ColorButton onClick={() => pickPattern('custom')}>
                                    <Pattern
                                        img={environment?.customBackgroundImg}
                                        onClick={() => setOpen(true)}
                                    />
                                </ColorButton>
                            )}
                        </Flyout>
                    )}
                </AnimatePresence>
            </div>,
        )}
    </>);
});
