import { action, computed, makeObservable, observable } from 'mobx';
import log from '../ProofX/Logger';
import { useProofXStore } from '../Store/ProofXStore';

// @abstract
export default class ExtraToolCubit {
    _keyboardEvents = {};
    _active = false;
    _name = '❌ ExtraToolCubit is abstract ❌';

    constructor(name, viewerMode, eventHandlers) {
        if (this.constructor === ExtraToolCubit) {
            throw new Error('You should not instantiate a ExtraToolCubit constructor');
        }
        makeObservable(this, {
            _active: observable,

            active: computed,

            activate: action,
            deactivate: action,
        });
        this._name = name;
        const viewer = useProofXStore.getState().viewer;
        viewer?.addEvents(viewerMode, eventHandlers?.mouse ?? {});
        this._keyboardEvents = eventHandlers?.keyboard ?? {};
    }

    get active() { return this._active; }
    get name() { return this._name; }

    activate() {
        this._active = true;
        Object.keys(this._keyboardEvents).forEach(key => document.addEventListener(key, this._keyboardEvents[key]));
        log(`${this._name} is activated`);
    }

    deactivate() {
        this._active = false;
        Object.keys(this._keyboardEvents).forEach(key => document.removeEventListener(key, this._keyboardEvents[key]));
    }
}
