/* global fabric */

import FigureCubit, { styles } from '../FigureCubit';
import { getRotatedPoint, getUnrotatedPoint } from '../Utils';

export default class ArrowFigure extends FigureCubit {
    get figureType() {
        return 'arrow';
    }

    createShape() {
        this._brush = new fabric.PencilBrush(this._layer);
        this._brush.type = 'figure';

        this._brush.color = this._annotationCubit.color;
        this._brush.width = styles.figureStroke / this._layer.getZoom();
    }

    _continueDraw(pointer) {
        const startPoint = this._drawingProps.startingPoint;
        const endPoint = pointer ?? startPoint;
        this._position = {
            x: Math.min(startPoint.x, endPoint.x),
            y: Math.min(startPoint.y, endPoint.y),
        };
        this._dimensions = {
            x: Math.abs(startPoint.x - endPoint.x),
            y: Math.abs(startPoint.y - endPoint.y),
            start: startPoint,
            end: endPoint,
        };
        this.placeShape(this._position, this._dimensions);
    };

    placeShape() {
        const visible = this._figure?.visible ?? true;
        this._figure?.remove();
        this._figure = this._createSvgPath();
        this._figure.visible = visible;
        this._layer.add(this._figure);
        const start = getRotatedPoint(this._dimensions.start);
        const end = getRotatedPoint(this._dimensions.end);

        if (!this._startResizePoint || !this._endResizePoint) return;
        this._startResizePoint.set({
            left: start.x,
            top: start.y,
        });
        this._endResizePoint.set({
            left: end.x,
            top: end.y,
        });
        this._startResizePoint.bringToFront();
        this._endResizePoint.bringToFront();
    }

    _createSvgPath() {
        const pathData = [];
        if (!this._dimensions?.start || !this._dimensions?.end) {
            throw new Error('Invalid dimensions');
        }
        const startPoint = getRotatedPoint(this._dimensions.start);
        const endPoint = getRotatedPoint(this._dimensions.end);
        const basePoint = { x: Math.min(startPoint.x, endPoint.x), y: Math.min(startPoint.y, endPoint.y) };
        const relStartPoint = { x: startPoint.x - basePoint.x, y: startPoint.y - basePoint.y };
        const relEndPoint = { x: endPoint.x - basePoint.x, y: endPoint.y - basePoint.y };
        const arrowheadLength = 20 / this._layer.getZoom();
        const arrowheadAngle = Math.PI / 8;
        const angle = Math.atan2(relEndPoint.y - relStartPoint.y, relEndPoint.x - relStartPoint.x);
        const arrowheadPoints = [
            relEndPoint,
            {
                x: relEndPoint.x - arrowheadLength * Math.cos(angle + arrowheadAngle),
                y: relEndPoint.y - arrowheadLength * Math.sin(angle + arrowheadAngle),
            }, {
                x: relEndPoint.x - arrowheadLength * 0.7 * Math.cos(angle),
                y: relEndPoint.y - arrowheadLength * 0.7 * Math.sin(angle),
            }, {
                x: relEndPoint.x - arrowheadLength * Math.cos(angle - arrowheadAngle),
                y: relEndPoint.y - arrowheadLength * Math.sin(angle - arrowheadAngle),
            },
            relEndPoint,
        ];
        pathData.push(['M', relStartPoint.x, relStartPoint.y]);
        for (let i = 0; i < arrowheadPoints.length; i++) {
            pathData.push(['L', arrowheadPoints[i].x, arrowheadPoints[i].y]);
        }
        const path = this._brush.createPath(pathData);
        path.set({ left: basePoint.x, top: basePoint.y });
        path.type = 'figure';
        path.originX = 'left';
        path.originY = 'top';
        path.figureType = 'arrow';
        path.hasControls = false;
        path.hasRotatingPoint = false;
        path.hasBorders = false;
        path.selectable = false;
        path.perPixelTargetFind = true;
        path.figureCubit = this;
        path.opacity = styles.opacity.default;
        path.noScaleCache = false;
        path.fill = this._annotationCubit.color;
        path.stroke = this._annotationCubit.color;
        path.strokeWidth = styles.figureStroke / this._layer.getZoom();
        return path;
    }

    handleEndDrawing() {
        this._mountResizeControls();
        this._annotationCubit.finalizeFigure(this);
    }

    findLineEnd(startPoint) {
        return getRotatedPoint(this._dimensions.start);
    };

    handleReposition() {
        const start = getUnrotatedPoint({ x: this._startResizePoint.left, y: this._startResizePoint.top });
        const end = getUnrotatedPoint({ x: this._endResizePoint.left, y: this._endResizePoint.top });
        this._position = {
            x: Math.min(start.x, end.x),
            y: Math.min(start.y, end.y),
        };
        this._dimensions = {
            x: Math.abs(start.x - end.x),
            y: Math.abs(start.y - end.y),
            start,
            end,
        };
        this.placeShape();
        this._updateBoundingRect();
        this._renderConnectingLine();
    }

    endReposition() {
        this._annotationCubit.save();
    }

    _calculateNewPosition() {
        const positionBefore = this._position;
        super._calculateNewPosition();
        const positionAfter = this._position;
        const delta = {
            x: positionAfter.x - positionBefore.x,
            y: positionAfter.y - positionBefore.y,
        };
        this._dimensions = {
            ...this._dimensions,
            start: {
                x: this._dimensions.start.x + delta.x,
                y: this._dimensions.start.y + delta.y,
            },
            end: {
                x: this._dimensions.end.x + delta.x,
                y: this._dimensions.end.y + delta.y,
            },
        };
    }

    _finalizeShape() {
        this._layer.on('path:created', this._closePath);
        this._brush.onMouseUp();
        this._layer.off('path:created', this._closePath);
        this.placeShape(this._position, this._dimensions);
    };

    _mountResizeControls() {
        super._mountResizeControls();
        const computed = getComputedStyle(document.body);
        const controlProps = {
            type: 'resizeControl',
            radius: 5 / this._layer.getZoom(),
            fill: computed.getPropertyValue('--figure-resizer-background'),
            stroke: computed.getPropertyValue('--primary-color'),
            strokeWidth: 1,
            originX: 'center',
            originY: 'center',
            hasControls: false,
            hasBorders: false,
            hasRotatingPoint: false,
            noScaleCache: false,
            cursor: 'pointer',
            selectable: true,
            visible: false,
            figureCubit: this,
        };
        this._startResizePoint = new fabric.Circle({
            ...controlProps,
            left: this._dimensions.start.x,
            top: this._dimensions.start.y,
        });
        this._endResizePoint = new fabric.Circle({
            ...controlProps,
            left: this._dimensions.end.x,
            top: this._dimensions.end.y,
        });
        this._layer.add(this._startResizePoint);
        this._layer.add(this._endResizePoint);
    }

    _updateZoom() {
        this.placeShape();
        this._startResizePoint.set({
            radius: 5 / this._layer.getZoom(),
            strokeWidth: 1 / this._layer.getZoom(),
        });
        this._endResizePoint.set({
            radius: 5 / this._layer.getZoom(),
            strokeWidth: 1 / this._layer.getZoom(),
        });
    }

    _closePath(props) {
        props.path.remove();
    }
}
