import CompareModeCubit from './CompareModeCubit';

export default class DiffCubit extends CompareModeCubit {
    assignDependentStages() {

    }

    async loadCompareResult() {
        const {
            stages: { compare: compareStage },
            viewer: { mainAsset, compareAsset },
        } = this.store;

        if (!mainAsset || !compareAsset) return;

        const differenceUrl = `/api/GetDZICompareResult/${mainAsset.uid}/${mainAsset.page}/${compareAsset.uid}/${compareAsset.page}/diff/`;
        await compareStage.loadImage(compareAsset, differenceUrl);
    }
}
