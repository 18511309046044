/* global fabric */

import FigureCubit, { styles } from '../FigureCubit';
import {
    rotationMatrix,
    examinePointCloser,
    getRotationAngle,
    getRotatedPoint,
    getImageDimensions,
    getUnrotatedPoint,
} from '../Utils';

export default class FreeFigure extends FigureCubit {
    get figureType() {
        return 'free';
    }

    createShape() {
        this._brush = new fabric.PencilBrush(this._layer);
        this._brush.type = 'figure';

        this._brush.color = this._annotationCubit.color;
        this._brush.width = styles.figureStroke / this._layer.getZoom();
    }

    _continueDraw(pointer) {
        const startingPoint = this._drawingProps.startingPoint;
        const isFirstPoint = !pointer;
        pointer = pointer ?? startingPoint;

        const rotatedPoint = getRotatedPoint(pointer);
        const point = new fabric.Point(
            rotatedPoint.x,
            rotatedPoint.y,
        );
        if (isFirstPoint) {
            this._brush.onMouseDown(point);
        } else {
            this._brush.onMouseMove(point);
        }
    };

    placeShape(position, dimensions) {
        if (!this._brush) {
            this.createShape();
        }
        const visible = this._figure?.visible ?? true;
        const activeObject = this._layer.getActiveObject();
        const shouldSelect = this._figure && activeObject === this._figure;

        this._figure?.remove();
        this._position = position;
        this._dimensions = dimensions;

        this._figure = this._createSvgPath();
        this._figure.annotationCubit = this._annotationCubit;
        this._figure.visible = visible;
        this._layer.add(this._figure);
        this._brush = null;

        if (shouldSelect) {
            this._layer.setActiveObject(this._figure);
        }
    }

    _finalizeShape() {
        this._layer.on('path:created', this._closePath);
        this._brush.onMouseUp();
        this._layer.off('path:created', this._closePath);
        const dimensions = { points: [] };
        const brushPoints = this._brush._points;
        let position = getImageDimensions();

        const oppositePoint = { x: 0, y: 0 };
        brushPoints.forEach(point => {
            const nextPoint = getUnrotatedPoint(point);
            position.x = Math.min(position.x, nextPoint.x);
            position.y = Math.min(position.y, nextPoint.y);
            oppositePoint.x = Math.max(oppositePoint.x, nextPoint.x);
            oppositePoint.y = Math.max(oppositePoint.y, nextPoint.y);
        });

        dimensions.x = oppositePoint.x - position.x;
        dimensions.y = oppositePoint.y - position.y;

        const strokeWidth = styles.figureStroke / this._layer.getZoom();
        position = {
            x: position.x - strokeWidth / 2,
            y: position.y - strokeWidth / 2,
        };

        brushPoints.forEach(point => {
            const nextPoint = getUnrotatedPoint(point);
            dimensions.points.push({
                x: nextPoint.x - position.x,
                y: nextPoint.y - position.y,
            });
        });
        this.placeShape(position, dimensions);
    };

    _createSvgPath() {
        const angle = getRotationAngle();
        const points = this._dimensions.points;
        if (points.length < 2) {
            return;
        }
        const pathData = [];
        pathData.push(['M', points[0].x, points[0].y]);
        for (let i = 1; i < points.length - 2; i++) {
            const p1 = new fabric.Point(points[i].x, points[i].y);
            const p2 = new fabric.Point(points[i + 1].x, points[i + 1].y);
            if (!p1.eq(p2)) {
                const midPoint = p1.midPointFrom(p2);
                pathData.push(['Q', p1.x, p1.y, midPoint.x, midPoint.y]);
            }
        }
        pathData.push(['L', points[points.length - 1].x, points[points.length - 1].y]);
        const rotatedBasePoint = getRotatedPoint(this._position);

        const path = this._brush.createPath(pathData);
        path.set({ left: rotatedBasePoint.x, top: rotatedBasePoint.y });
        path.type = 'figure';
        path.originX = 'left';
        path.originY = 'top';
        path.angle = angle;
        path.figureType = 'free';
        path.hasControls = false;
        path.hasRotatingPoint = false;
        path.hasBorders = false;
        path.selectable = false;
        path.perPixelTargetFind = true;
        path.figureCubit = this;
        path.opacity = styles.opacity.default;
        path.noScaleCache = false;
        return path;
    }

    _closePath(props) {
        props.path.remove();
    }

    findLineEnd(startPoint) {
        const stage = this.store.annotationManager.stage;
        let len = NaN;
        let result = null;
        const zoom = this._layer.getZoom();
        const angle = stage.angle;

        const boundingRect = this._figure.getBoundingRect(true, true);
        const strokeWidth = styles.figureStroke / zoom;

        const rotatedBasePoint = {
            x: boundingRect.left + rotationMatrix[angle].x * (boundingRect.width - strokeWidth),
            y: boundingRect.top + rotationMatrix[angle].y * (boundingRect.height - strokeWidth),
        };
        let unrotatedBasePoint = getUnrotatedPoint(rotatedBasePoint);
        unrotatedBasePoint = {
            x: unrotatedBasePoint.x - strokeWidth * rotationMatrix[angle].y,
            y: unrotatedBasePoint.y - strokeWidth * rotationMatrix[angle].x,
        };

        for (let i = 0; i < this._figure.path.length; i++) {
            const part = this._figure.path[i];
            const unrotated = {
                x: part[1] + unrotatedBasePoint.x,
                y: part[2] + unrotatedBasePoint.y,
            };
            const rotated = getRotatedPoint(unrotated);
            const point = new fabric.Point(rotated.x, rotated.y);
            let exam = examinePointCloser(point, startPoint, result, len);
            result = exam.point;
            len = exam.len;

            if (part[0] === 'Q') {
                const rotated = getRotatedPoint({ x: part[3] + unrotatedBasePoint.x, y: part[4] + unrotatedBasePoint.y });
                const point = new fabric.Point(rotated.x, rotated.y);
                exam = examinePointCloser(point, startPoint, result, len);
                result = exam.point;
                len = exam.len;
            }
        }
        return result;
    };

    _calculateNewPosition() {
        super._calculateNewPosition();
        const zoom = this._layer.getZoom();
        const angle = getRotationAngle();
        const strokeWidth = styles.figureStroke / zoom;
        this._position = {
            x: this._position.x - strokeWidth * rotationMatrix[angle].y,
            y: this._position.y - strokeWidth * rotationMatrix[angle].x,
        };
    }

    _calculateNewDimensions() {
        super._calculateNewDimensions();
        const angle = getRotationAngle();
        const boundingRect = this._boundingRect;
        const scaling = {
            x: rotationMatrix[angle].flip ? boundingRect.scaleY : boundingRect.scaleX,
            y: rotationMatrix[angle].flip ? boundingRect.scaleX : boundingRect.scaleY,
        };
        const originalPoints = this._scalingProps.originalDimensions?.points;
        if (!originalPoints) return;

        this._dimensions = {
            ...this._dimensions,
            points: originalPoints.map(p => ({
                x: p.x * scaling.x,
                y: p.y * scaling.y,
            })),
        };
    }
}
