import { CaretCircleDown, CaretCircleUp, PaperPlaneRight, X } from '@phosphor-icons/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { createPortal } from 'react-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import styled from 'styled-components';
import { useProofXStore } from '../../Store/ProofXStore';
import Editor from '../../uicontrols/CommentEditor/Editor';
import FlippingToggle from '../../uicontrols/FlippingToggle';
import Tooltip from '../../uicontrols/Tooltip';
import { CommentBody, CommentFooter, FooterButton } from './Comment';
import Discussion from './Discussion';
import { discussionSources } from './DiscussionPanelCubit';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;    
    height: calc(100% - var(--right-panel-header-height));
`;

const ScrollWrapper = styled.div`
    position: relative;
    flex-grow: 1;
    overflow: ${({ isMobile }) => isMobile ? 'auto' : 'hidden'};
`;

const NewComment = styled.div`
    border-top: 1px solid var(--control-border);
    background-color: var(--discussion-comment-background);    
    padding: 8px;
    max-width: 234px;
    display: flex;
    flex-direction: column;
`;

export default observer(function DiscussionPanel() {
    const cubit = useProofXStore((state) => state.discussionPanel);
    const strings = useProofXStore((state) => state.strings);
    const isForAsset = cubit.source === discussionSources.asset;
    const isMobile = useProofXStore((state) => state.proofX?.isMobile ?? false);
    const discussionCubit = cubit?.discussionCubit;

    const iconSize = 24;
    const shouldCollapse = discussionCubit?.hasExpandedReplies;

    return (
        <Wrapper onClick={() => cubit?.discussionCubit?.highlightComment(null)}>
            <ScrollWrapper isMobile={isMobile}>
                {isMobile
                    ? <Discussion cubit={cubit?.discussionCubit} />
                    : (
                        <SimpleBar style={{ width: 250, maxHeight: '100%', display: 'flex' }}>
                            <Discussion cubit={cubit?.discussionCubit} />
                        </SimpleBar>
                    ) }
            </ScrollWrapper>
            {!isForAsset && (
                <NewComment>
                    <CommentBody>
                        <Editor padding='4px 0' cubit={cubit.newCommentEditor} />
                    </CommentBody>
                    <CommentFooter>
                        {cubit.newCommentEditor?.text
                            ? (
                                <FooterButton link fontSize={10} onClick={() => cubit.cleanNewProjectComment()}>
                                    <X size={12} weight='bold' color='var(--button-link-color)' style={{ marginRight: 2 }} />
                                    {strings.cancel}
                                </FooterButton>
                            )
                            : <div />}
                        <FooterButton link fontSize={10} onClick={() => cubit.createNewProjectComment()}>
                            {strings.send}
                            <PaperPlaneRight size={12} weight='bold' color='var(--button-link-color)' style={{ marginLeft: 2 }} />
                        </FooterButton>
                    </CommentFooter>
                </NewComment>
            )}
            {discussionCubit?.hasExpandableReplies && createPortal(
                <Tooltip text={shouldCollapse ? strings.hideAllReplies : strings.showAllReplies} >
                    <FlippingToggle size={iconSize}
                        isOn={shouldCollapse}
                        OnIcon={<CaretCircleDown size={iconSize} weight='fill' />}
                        OffIcon={<CaretCircleUp size={iconSize} weight='fill' />}
                        onClick={() => discussionCubit.toggleCollapseAll()}
                    />
                </Tooltip>,
                document.getElementById('header-tool'),
            )}
        </Wrapper>
    );
});
