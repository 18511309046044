import { observable, computed, action, makeObservable } from 'mobx';
import { useProofXStore } from '../Store/ProofXStore';

export const SplitViewState = {
    loading: 0,
    ready: 1,
};

export default class SplitViewCubit {
    constructor() {
        makeObservable(this, {
            _leftDrawerOpened: observable,
            _rightDrawerOpened: observable,

            leftDrawerOpened: computed,
            rightDrawerOpened: computed,

            openLeftDrawer: action,
            closeLeftDrawer: action,
            openRightDrawer: action,
            closeRightDrawer: action,
        });
    };

    _leftDrawerOpened = false;
    _rightDrawerOpened = false;

    get isMobile() { return useProofXStore.getState()?.proofX?.isMobile ?? false; }
    get leftDrawerOpened() {
        return this._leftDrawerOpened;
    }

    get rightDrawerOpened() {
        return this._rightDrawerOpened;
    }

    openLeftDrawer() {
        this._leftDrawerOpened = true;
        if (this.isMobile) {
            this._rightDrawerOpened = false;
        }
    }

    closeLeftDrawer() {
        this._leftDrawerOpened = false;
    }

    openRightDrawer() {
        this._rightDrawerOpened = true;
        if (this.isMobile) {
            this._leftDrawerOpened = false;
        }
    }

    closeRightDrawer() {
        this._rightDrawerOpened = false;
    }
}
