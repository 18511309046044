import { Info, WarningCircle } from '@phosphor-icons/react';
import parse from 'html-react-parser';
import React from 'react';
import styled from 'styled-components';
import { FlexRow } from '../../styles';

const AlertFrame = styled(FlexRow)`
    background-color: ${({ danger }) => danger ? 'var(--alert-danger)' : 'var(--alert-default)'};
    color:  ${({ danger }) => danger ? 'var(--alert-danger-text)' : 'var(--button-contrast-color)'};
    font-size: 14px;
    width: 300px;
    padding: 18px;
    cursor: pointer;
    font-family: var(--medium);    
`;

const Icon = styled.div`
    margin-right: 12px;
`;

const Text = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default function Alert({ message, severity, onClick }) {
    const isError = severity === 'error';
    return (
        <AlertFrame danger={isError} onClick={onClick}>
            <Icon >
                {isError
                    ? <WarningCircle size={30} weight='fill' />
                    : <Info size={30} weight='fill' />
                }
            </Icon>
            <Text>{parse(message)}</Text>
        </AlertFrame>
    );
};
