import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';
import { useLayer } from 'react-laag-v18';
import { useProofXStore } from '../../../Store/ProofXStore';
import {
    ArrowFatRight, X, MinusCircle, Trash,
    Square, Circle, ArrowUpRight, ScribbleLoop,
} from '@phosphor-icons/react';
import { annotationColors } from '../AnnotationCubit';
import Tooltip from '../../../uicontrols/Tooltip';
import { FlexRow } from '../../../../styles';
import { ViewerModes } from '../../ViewerCubit';

const Trigger = styled.div`
    ${({ styles }) => styles}
`;

const Menu = styled(motion.div).attrs(() => ({ layout: true }))`
    border-radius: 8px;
    background-color: var(--popover-background);  
    box-shadow: var(--glass-shadow-popup);
    font-size: 12px;
    display: flex;
    flex-direction: column; 
    transform-origin: top left;
`;

const MenuItem = styled(FlexRow)`
    padding: 6px 12px;
    
    :hover {
        background-color: var(--highlighted-background);
    }

    :first-child {
        border-radius: 8px 8px 0 0;
        padding-top: 8px;
    }
    :last-child {
        border-radius: 0 0 8px 8px;
        padding-bottom: 8px;
    }
`;

const Section = styled.div`
    padding: 6px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid var(--control-border);
`;

const SectionTitle = styled.div`
    font-size: 13px;
    font-family: var(--medium);
    margin-bottom: 8px;    
`;

const FigureButton = styled(FlexRow)`
    padding: 4px;
    justify-content: center;
    width: 18px;
    height: 18px;  

    :hover {
        background-color: var(--highlighted-background);
    }
`;
export const ColorButton = styled.div`
    padding: 1px;
    display: flex;    

    :hover {
        background-color: var(--highlighted-background);
    }
`;

const Icon = styled.div`
    display: flex;
    margin-right: 6px;
`;

const variants = {
    opened: { opacity: 1, scale: 1 },
    closed: { opacity: 0, scale: 0 },
};

export default observer(function TextBoxMenu({ cubit, trigger }) {
    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen: cubit.isOpen,
        onOutsideClick: () => cubit.close(), // close the menu when the user clicks outside
        onDisappear: () => cubit.close(), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => cubit.close(),
        overflowContainer: true, // keep the menu positioned outside the container
        auto: true, // automatically find the best placement
        placement: cubit.placement ?? 'bottom-start', // we prefer to place the menu "top-end"
        possiblePlacements: cubit.possiblePlacements,
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 8, // give the menu some room to breath relative to the container
        // arrowOffset: 16 // let the arrow have some room to breath also
    });
    const textBox = cubit?.controller;
    const strings = useProofXStore((state) => state.strings);
    const isSimpleMode = useProofXStore((state) => state.proofX?.environment?.task?.simpleMode ?? false);
    const viewer = useProofXStore((state) => state.viewer);

    return (<>
        <Trigger {...triggerProps} styles={cubit.triggerStyles ?? ''} className='trigger'>
            {trigger}
        </Trigger>
        {cubit?.isOpen && renderLayer(
            <div {...layerProps} onClick={() => { !cubit.stayOnClick && cubit.close(); }}>
                <Menu
                    variants={variants}
                    initial='closed'
                    animate={cubit?.isOpen ? 'opened' : 'closed'}
                    exit='closed'
                    transition={{
                        duration: 0.5,
                        type: 'spring',
                        bounce: 0.35,
                    }}
                >
                    {textBox?.editable && <>
                        {textBox.editMode && (
                            <MenuItem onClick={() => textBox.cancelEdit()}>
                                <Icon> <X size={14} color='var(--contrast-text)' /> </Icon>
                                {strings.cancelEditing}
                            </MenuItem>
                        )}
                        {textBox.hasActiveFigureToDelete && (
                            <MenuItem onClick={() => textBox.removeSelectedFigure()}>
                                <Icon> <MinusCircle size={14} color='var(--contrast-text)' /> </Icon>
                                {strings.removeSelectedFigure}
                            </MenuItem>
                        )}
                    </>}
                    <MenuItem onClick={() => textBox.revealInDiscussionPanel()}>
                        <Icon> <ArrowFatRight size={14} color='var(--contrast-text)' /> </Icon>
                        {strings.revealInDiscussionPanel}
                    </MenuItem>
                    {textBox?.deletable && (
                        <MenuItem onClick={() => textBox.deleteAnnotation()}>
                            <Icon> <Trash size={14} color='var(--contrast-text)' /> </Icon>
                            {strings.delete}
                        </MenuItem>
                    )}
                    {!isSimpleMode && textBox?.editable && <>
                        {/* ADD FIGURE */}
                        {viewer.currentMode !== ViewerModes.compare && (
                            <Section>
                                <SectionTitle>{strings.addFigure}</SectionTitle>
                                <FlexRow>
                                    <Tooltip text={strings.arrow}>
                                        <FigureButton onClick={() => textBox.addFigure('arrow')}>
                                            <ArrowUpRight size={18} />
                                        </FigureButton>
                                    </Tooltip>
                                    <Tooltip text={strings.point}>
                                        <FigureButton onClick={() => textBox.addFigure('point')}>
                                            <Circle size={6} weight='fill' />
                                        </FigureButton>
                                    </Tooltip>
                                    <Tooltip text={strings.square}>
                                        <FigureButton onClick={() => textBox.addFigure('square')}>
                                            <Square size={18} />
                                        </FigureButton>
                                    </Tooltip>
                                    <Tooltip text={strings.circle}>
                                        <FigureButton onClick={() => textBox.addFigure('circle')}>
                                            <Circle size={18} />
                                        </FigureButton>
                                    </Tooltip>
                                    <Tooltip text={strings.free}>
                                        <FigureButton onClick={() => textBox.addFigure('free')}>
                                            <ScribbleLoop size={18} />
                                        </FigureButton>
                                    </Tooltip>
                                </FlexRow>
                            </Section>
                        )}
                        {/* CHANGE COLOR */}
                        <Section>
                            <SectionTitle>{strings.changeColor}</SectionTitle>
                            <FlexRow>
                                {Object.keys(annotationColors).map(color => (
                                    <ColorButton key={color} onClick={() => textBox.changeColor(color)}>
                                        <Square size={18} weight='fill' color={annotationColors[color]} />
                                    </ColorButton>
                                ))}
                            </FlexRow>
                        </Section>
                    </>}
                </Menu>
            </div>,
        )}
    </>);
});
