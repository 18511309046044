import { observable, computed, action, makeObservable } from 'mobx';
import { api } from '../Api/ProofXApi';
import { useProofXStore } from '../Store/ProofXStore';
import DiffCubit from './DiffCubit';
import FaderCubit from './FaderCubit';
import SideBySideCubit from './SideBySideCubit';
import ToggleCubit from './ToggleCubit';
import log from '../ProofX/Logger';
import sendGTMEvent from '../GTM';

export const CompareModes = {
    sideBySide: 'side_by_side',
    fader: 'fader',
    diff: 'diff',
    toggle: 'toggle',
};

export default class CompareModesManager {
    _activeMode = null;
    _compareAsset = null;
    _showDifference = false;
    _currentModeCubit = null
    _faderValue = 50;
    _blinkingTimer = null;

    constructor() {
        makeObservable(this, {
            _activeMode: observable,
            _showDifference: observable,
            _compareAsset: observable.ref,
            _currentModeCubit: observable.ref,
            _faderValue: observable,
            _blinkingTimer: observable.ref,

            activeMode: computed,
            compareAsset: computed,
            isSideBySideMode: computed,
            isFaderMode: computed,
            isDiffMode: computed,
            isToggleMode: computed,
            showDifference: computed,
            faderValue: computed,

            activateMode: action,
            exit: action,
            setShowDifference: action,
            setFaderValue: action,
        });
    };

    get activeMode() { return this._activeMode; }
    get compareAsset() { return this._compareAsset; }
    get isSideBySideMode() { return this._activeMode === CompareModes.sideBySide; }
    get isFaderMode() { return this._activeMode === CompareModes.fader; }
    get isDiffMode() { return this._activeMode === CompareModes.diff; }
    get isToggleMode() { return this._activeMode === CompareModes.toggle; }
    get allStagesLoaded() { return this._allStagesLoaded; }
    get showDifference() { return this._showDifference; }
    get faderValue() { return this._faderValue; }
    get store() { return useProofXStore.getState(); }

    activateMode(mode) {
        log('👯‍♀️ activating compare mode: ', mode);
        this._activeMode = mode;
        this._allStagesLoaded = false;

        this._killBlinkingTimer();
        switch (mode) {
            case CompareModes.sideBySide:
                this._currentModeCubit = new SideBySideCubit();
                sendGTMEvent(`Compare-SideBySide${this.showDifference ? '-Difference' : ''}`);
                break;
            case CompareModes.fader:
                this._currentModeCubit = new FaderCubit();
                sendGTMEvent('Compare-Fader');
                break;
            case CompareModes.diff:
                this._currentModeCubit = new DiffCubit();
                sendGTMEvent('Compare-Difference');
                break;
            case CompareModes.toggle:
                this._currentModeCubit = new ToggleCubit();
                sendGTMEvent('Compare-Toggle');
                break;
        }
    }

    exit() {
        this._activeMode = null;
        this._allStagesLoaded = false;
        this._currentModeCubit = null;
        this._killBlinkingTimer();
    }

    _killBlinkingTimer() {
        if (this._blinkingTimer) {
            window.clearInterval(this._blinkingTimer);
            this._blinkingTimer = null;
        }
    }

    setShowDifference(isOn) {
        this._showDifference = isOn;
        this.loadCompareResult();
    }

    setFaderValue(e) {
        this._faderValue = e.target.value;
        this.updateFader();
    }

    updateFader() {
        const mainStageCanvas = document.querySelector('#main-stage-container .openseadragon-canvas');
        const compareStageCanvas = document.querySelector('#compare-stage-container .openseadragon-canvas');
        const middleStageCanvas = document.querySelector('#middle-stage-container .openseadragon-canvas');
        if (!mainStageCanvas || !middleStageCanvas || !compareStageCanvas) return;

        const sliderValue = this._faderValue / 100;
        const mainStageOpacity = 1 - sliderValue;
        const middleStageOpacity = (sliderValue > 0.9 || sliderValue < 0.1) ? 0 : 0.5;
        const compareStageOpacity = sliderValue;

        mainStageCanvas.style.opacity = mainStageOpacity;
        compareStageCanvas.style.opacity = compareStageOpacity;
        middleStageCanvas.style.opacity = middleStageOpacity;
    }

    setBlinkingTimer(timer) {
        this._killBlinkingTimer();
        this._blinkingTimer = timer;
    }

    async loadCompareResult() {
        const { proofX, viewer, viewer: { mainAsset, compareAsset }, strings } = this.store;
        if (mainAsset && !compareAsset) {
            await viewer.updateMainStage();
            return;
        }

        if (!this._currentModeCubit || !mainAsset || !compareAsset) return;
        this._allStagesLoaded = false;

        const resultExists = await this._checkResultExists();
        if (!resultExists) {
            proofX.setWaitingMessage(strings.generatingCompareResult);
            await api.generateCompareImage(mainAsset.uid, mainAsset.page, compareAsset.uid, compareAsset.page, this._activeMode);
            proofX.setReady();
        }
        await this._currentModeCubit.loadCompareResult();
        this.assignDependentStages();
    }

    async _checkResultExists() {
        if ((this.isSideBySideMode && !this.showDifference) || this.isToggleMode) return true;
        const { viewer: { mainAsset, compareAsset } } = this.store;
        const resultExists = await api.checkCompareResultExists(mainAsset.uid, mainAsset.page, compareAsset.uid, compareAsset.page, this._activeMode);
        return resultExists.result;
    }

    assignDependentStages() {
        if (this._currentModeCubit) {
            this._currentModeCubit.assignDependentStages();
        } else {
            const { stages: { main: mainStage, compare: compareStage, middle: middleStage } } = this.store;
            mainStage?.setDependentStages([]);
            compareStage?.setDependentStages([]);
            middleStage?.setDependentStages([]);
        }
    }
}
