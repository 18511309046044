import React, { useCallback } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useProofXStore } from '../Store/ProofXStore';
import { ArrowFatRight } from '@phosphor-icons/react';
import { StageTypes } from './StageCubit';
import { FlexRow } from '../../styles';

// #region Styles

const Wrapper = styled.div`      
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
`;

const Underlay = styled.div`
    background-image: url(${({ thumbnail }) => `data:image/jpeg;base64,${(thumbnail?.replace(/(\r\n|\n|\r)/gm, '') ?? '')}`});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: ${({ width }) => width || '0'}px;
    height: 100%;
    opacity: ${({ visible }) => visible ? 1 : 0};
`;

const StageContainer = styled.div`
    width: 100%;
    height: 100%;
    opacity: ${({ visible }) => visible ? 1 : 0};
`;

const CompareAssetPrompt = styled(FlexRow)`
    border-radius: 8px;        
    padding: 24px;
    font-size: 14px;
    font-family: var(--medium);
    background-color: var(--popover-background);
    box-shadow: var(--glass-shadow-popup);
`;

// #endregion

export default observer(function Stage({ asset, thumbnail, wrapperRef, stageType, showControls }) {
    const stage = useProofXStore((state) => state.stages[stageType]);
    const viewer = useProofXStore((state) => state.viewer);
    const strings = useProofXStore((state) => state.strings);
    const size = {
        width: wrapperRef?.current?.clientWidth ?? 0,
        height: wrapperRef?.current?.clientHeight ?? 0,
    };

    const stageRef = useCallback(async (element) => {
        if (viewer && element) {
            await viewer.initializeStage(asset, element, stageType, !!showControls);
        }
    }, [asset, viewer, stageType, showControls]);

    return (
        <Wrapper>
            {asset?.uid && (<>
                <Underlay
                    key={`underlay-${asset.uid}-${asset.page ?? 0}-${(thumbnail ?? '').substring(0, 100)}`}
                    width={size?.width ?? 0}
                    height={size.height ?? 0}
                    thumbnail={thumbnail}
                    visible={!stage?.isTileLoaded}
                />
                <StageContainer
                    ref={stageRef}
                    id={`stage-${asset.uid}-${asset.page ?? 0}`}
                    visible={true}
                />
            </>)}
            {!asset && stageType === StageTypes.compare && (
                <CompareAssetPrompt>
                    {strings.selectCompareAsset}
                    <ArrowFatRight size={24} weight='fill' color='var(--primary-color)' style={{ marginLeft: 8 }} />
                </CompareAssetPrompt>
            )}
        </Wrapper>
    );
});
