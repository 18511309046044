import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../Store/ProofXStore';
import { FlexColumn } from '../../styles';
import { ViewerModes } from './ViewerCubit';
import RulerTool from '../RightPanel/Ruler/RulerTool';
import BarcodeScannerTool from '../RightPanel/BarcodeScanner/BarcodeScannerTool';

// #region Styles
const Wrapper = styled(FlexColumn)`
    justify-content: center;
    padding: var(--md) 0;
    pointer-events: auto;
`;

// #endregion

export default observer(function ViewerTopToolbar() {
    const viewer = useProofXStore((state) => state.viewer);

    if (!viewer) return null;

    return (
        <Wrapper>
            {viewer?.currentMode === ViewerModes.ruler && (
                <RulerTool />
            )}
            {viewer?.currentMode === ViewerModes.barcodeScanner && (
                <BarcodeScannerTool />
            )}
        </Wrapper>
    );
});
