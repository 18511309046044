import CompareModeCubit from './CompareModeCubit';

export default class ToggleCubit extends CompareModeCubit {
    assignDependentStages() {
        const { stages: { main: mainStage, compare: compareStage } } = this.store;
        if (!mainStage || !compareStage) return;
        compareStage.setDependentStages([mainStage]);
        compareStage.zoomToFitScreen();
        compareStage.synchronizeDependentStages();
    }

    async loadCompareResult() {
        const {
            stages: { main: mainStage, compare: compareStage },
            viewer: { mainAsset, compareAsset },
            compareModesManager,
        } = this.store;

        if (!mainAsset || !compareAsset) return;

        const mainUrl = `/api/GetDZIFile/${mainAsset.uid}/${mainAsset.page}/dz.dzi/`;
        const compareUrl = `/api/GetDZIFile/${compareAsset.uid}/${compareAsset.page}/dz.dzi/`;

        await Promise.all([
            mainStage.loadImage(mainAsset, mainUrl),
            compareStage.loadImage(compareAsset, compareUrl),
        ]);

        const interval = 1000;
        let blinker = 0;
        const timer = window.setInterval(function () {
            document.querySelector('#main-stage-container .openseadragon-canvas').style.opacity = blinker;
            document.querySelector('#compare-stage-container .openseadragon-canvas').style.opacity = 1 - blinker;
            blinker = 1 - blinker;
        }, interval / 2);
        compareModesManager.setBlinkingTimer(timer);
    }
}
