import React from 'react';
import styled from 'styled-components';
import { ArrowClockwise, ArrowCounterClockwise, FrameCorners, Minus, Plus } from '@phosphor-icons/react';
import { FlexRow } from '../../styles';
import { useProofXStore } from '../Store/ProofXStore';
import StageToolButton from '../uicontrols/StageToolButton';

// #region Styles
const Wrapper = styled(FlexRow)`
    justify-content: center;
    padding: 8px;
`;

// #endregion

export default function ZoomToolbar({
    PercentageIndicator,
    onRotateLeft,
    onRotateRight,
    onZoomIn,
    onZoomOut,
    onZoomToFit,
}) {
    const strings = useProofXStore((state) => state.strings);
    return (
        <Wrapper id='zoom-toolbar'>
            <StageToolButton bottom Icon={FrameCorners} title={strings.fitToViewport} onPress={onZoomToFit} />
            <StageToolButton bottom Icon={Plus} title={strings.zoomIn} onPress={onZoomIn} />
            {PercentageIndicator}
            <StageToolButton bottom Icon={Minus} title={strings.zoomOut} onPress={onZoomOut} />
            <StageToolButton bottom Icon={ArrowCounterClockwise} title={strings.rotateLeft} onPress={onRotateLeft} />
            <StageToolButton bottom Icon={ArrowClockwise} title={strings.rotateRight} onPress={onRotateRight} />
        </Wrapper>
    );
}
