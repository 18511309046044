import React from 'react';
import styled from 'styled-components';
import Tooltip from '../uicontrols/Tooltip';
import { FlexMotionRow, largeIconSize, mediumIconSize } from '../../styles';

const ToolButton = styled(FlexMotionRow)`
    width: ${({ $small }) => $small ? mediumIconSize : largeIconSize}px;
    height: ${({ $small }) => $small ? mediumIconSize : largeIconSize}px;
    border-radius: 50%;
    margin: var(--xs);
    justify-content: center;
    cursor: pointer;
    background-color: ${({ $on }) => $on ? 'var(--toolbar-button-active-background)' : 'var(--toolbar-button-background)'};
    box-shadow: ${({ $on }) => $on ? 'var(--glass-shadow-lite-inset)' : 'var(--glass-shadow-lite)'};
    visibility: ${({ $visible }) => $visible ? 'visible' : 'hidden'};
`;

export default function AppTool({ Icon, title, isOn, hidden, color, filled, small, id, onPress }) {
    const shadow = isOn ? 'var(--glass-shadow-lite-inset)' : 'var(--glass-shadow-lite)';
    const buttonColor = color ?? 'var(--stage-button-color)';
    return (
        <Tooltip text={title} placement='bottom-center' style={{ display: 'flex', marginBottom: 2 }} disabled={hidden}>
            <ToolButton
                id={hidden ? null : id}
                $on={isOn}
                $visible={!hidden}
                $small={small}
                animate={{ boxShadow: shadow }}
                whileHover={!isOn && {
                    scale: 1.1,
                    transition: { duration: 0.1, ease: 'easeInOut' },
                }}
                whileTap={!isOn && {
                    scale: 1,
                    transition: { duration: 0.1, ease: 'easeInOut' },
                    boxShadow: '2px 2px 3px 1px transparent',
                }}
                onClick={onPress}
            >
                <Icon size={24} weight={filled ? 'fill' : 'regular'} color={buttonColor} />
            </ToolButton>
        </Tooltip>
    );
};
