import styled from 'styled-components';

export const Avatar = styled.img.attrs(({ uid }) => ({
    src: `/Api/GetUserAvatar/${uid}`,
}))`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    padding:  ${({ borderSize }) => borderSize ?? 2}px;
    margin-right: ${({ marginRight }) => marginRight ?? 0}px;
    pointer-events: none;
    border-radius: 50%;
    box-shadow: 0px 0px 15px 2px ${({ color }) => color} inset;
`;
