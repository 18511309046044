import React from 'react';
import styled from 'styled-components';
import HoverClarifier from './HoverClarifier';

export const StyledButton = styled.button`
    font-family: 'Montserrat', sans-serif;
    font-size: ${({ fontSize }) => fontSize ?? 13}px;
    text-transform: ${({ lowercase }) => lowercase ? '' : 'uppercase'};
    outline: none;
    display: flex;
    align-items: center;
    color: ${({ primary, secondary, danger }) => primary || secondary || danger ? 'var(--button-contrast-color)' : 'var(--button-link-color)'};
    border-radius: ${({ link }) => link ? '' : '10px'};
    padding: ${({ link }) => link ? '6px 12px' : '8px 12px'};
    margin: 0 1px 1px 0;
    border: none;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    background-color: ${({ primary, secondary, danger, link }) =>
        (primary
            ? 'var(--primary-color)'
            : (secondary
                ? 'var(--secondary-color)'
                : (danger
                    ? 'var(--button-danger-background)'
                    : (link ? 'transparent' : 'var(--button-default-background)')
                )))};
    box-shadow: ${({ link }) => link ? '' : 'var(--glass-shadow)'};
    
    :active {
        outline: none;
        color: ${({ link }) => link ? 'var(--modal-footer-link-active-color)' : ''};
        box-shadow: none;
    }

    :hover {
        opacity: ${({ disabled }) => disabled ? '1' : '0.8'};    
        color: ${({ link }) => link ? 'var(--contrast-text)' : ''};
        background-color: ${({ primary, secondary, danger, link }) =>
        (primary
            ? 'var(--primary-color)'
            : (secondary
                ? 'var(--secondary-color)'
                : (danger
                    ? 'var(--button-danger-background)'
                    : (link ? 'transparent' : 'var(--button-default-background)'))))};
    }

    :focus {
        outline: none;
    }

    :disabled {
        opacity: 0.5;
    }
`;

export default function Button({ children, primary, secondary, danger, link, disabled, lowercase, fontSize, icon, onClick }) {
    return (
        <HoverClarifier initialOpacity={1} scaleFactor={0.03}>
            <StyledButton {...{ primary, secondary, danger, link, disabled, lowercase, fontSize, onClick }}>
                {icon ?? null}
                {children}
            </StyledButton>
        </HoverClarifier>
    );
}
