import PointFigure from './Figures/PointFigure';
import CircleFigure from './Figures/CircleFigure';
import SquareFigure from './Figures/SquareFigure';
import FreeFigure from './Figures/FreeFigure';
import ArrowFigure from './Figures/ArrowFigure';

export default class FigureFactory {
    static create(figureType, id, annotation) {
        switch (figureType) {
            case 'arrow':
                return new ArrowFigure(id, annotation);
            case 'point':
                return new PointFigure(id, annotation);
            case 'square':
                return new SquareFigure(id, annotation);
            case 'circle':
                return new CircleFigure(id, annotation);
            case 'free':
                return new FreeFigure(id, annotation);
            default: return null;
        }
    }
}
