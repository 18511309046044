import { observable, computed, makeObservable, override } from 'mobx';
import ModalCubit from './ModalCubit';

export default class ConfirmModalCubit extends ModalCubit {
    _message = '';
    _confirmText = '';
    _confirmHandler = () => { };

    constructor() {
        super();
        makeObservable(this, {
            _message: observable,
            _confirmText: observable,
            _confirmHandler: observable,

            message: computed,
            confirmText: computed,
            confirmHandler: computed,

            show: override,
        });
    }

    get message() { return this._message; }
    get confirmText() { return this._confirmText; }
    get confirmHandler() {
        return this._confirmHandler
            ? () => {
                this._confirmHandler();
                this.hide();
            }
            : null;
    }

    show(title, message, confirmText, confirmHandler) {
        this._title = title;
        this._message = message;
        this._confirmText = confirmText;
        this._confirmHandler = confirmHandler;
        super.show();
    }
}
