import React, { useRef } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { AnimatePresence, motion } from 'framer-motion';
import { useProofXStore } from '../Store/ProofXStore';
import ViewLayout from './ViewLayout';
import SideBySideLayout from '../CompareModes/SideBySideLayout';
import FaderLayout from '../CompareModes/FaderLayout';
import DiffLayout from '../CompareModes/DiffLayout';
import ToggleLayout from '../CompareModes/ToggleLayout';
import Spinner from '../uicontrols/Spinner';
import { FlexRow } from '../../styles';
import ViewerLeftToolbar from './ViewerLeftToolbar';
import ViewerRightToolbar from './ViewerRightToolbar';
import ViewerTopToolbar from './ViewerTopToolbar';

// #region Styles

const Wrapper = styled(FlexRow)`      
    background-color: ${({ isError }) => isError ? 'red' : ''};
    height: 100%;
    position: relative;
`;

const SpinnerOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--modal-backdrop-color);
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SpinnerColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    border-radius: 8px;
    background-color: var(--glass-background);
    box-shadow: var(--glass-shadow-popup);
`;

const WaitMessage = styled(FlexRow)`
    font-size: 14px;
    margin-top: 12px;
    color: var(--primary-color);
`;

const TopToolsRow = styled(FlexRow)`
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: center;
`;

const LeftToolsColumn = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`;

const RightToolsColumn = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

export const StageContainer = styled.div`
    width: ${({ sideBySide }) => sideBySide ? '50%' : '100%'};
    border-right: ${({ sideBySide, isLeft }) => sideBySide && isLeft ? '1px solid var(--glass-background)' : 'none'};
    border-left: ${({ sideBySide, isRight }) => sideBySide && isRight ? '1px solid var(--glass-background)' : 'none'};
    height: 100%;
    overflow: hidden;
    position: relative;
`;

const MotionDiv = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;  
`;

const CenterMarker = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 1px;
`;

// #endregion

export function StageSlider({ assetUid, containerHeight, direction, children }) {
    return (
        <AnimatePresence mode='popLayout' initial={false}>
            <MotionDiv
                key={`stage-container-${assetUid ?? 'empty'}`}
                custom={direction}
                initial={(direction) => ({
                    y: (direction ?? 1) * -containerHeight,
                    scale: 1,
                    opacity: 0,
                })}
                animate={{ y: 0, scale: 1, opacity: 1 }}
                exit={{ y: 0, scale: 0, opacity: 0 }}
                transition={{
                    y: { type: 'spring', stiffness: 300, damping: 30, duration: 0.2 },
                    duration: 0.2,
                }}
            >
                {children}
            </MotionDiv>
        </AnimatePresence>
    );
}

export default observer(function Viewer({ cubit }) {
    const wrapperRef = useRef();
    const compareModesManager = useProofXStore((state) => state.compareModesManager);
    const containerHeight = wrapperRef.current?.clientHeight ?? 0;

    if (!cubit) return;

    return (
        <Wrapper ref={wrapperRef} id='viewer-wrapper'>
            {!compareModesManager.activeMode && (
                <ViewLayout {...{ containerHeight }} />
            )}
            {compareModesManager.isSideBySideMode && (
                <SideBySideLayout {...{ containerHeight }} />
            )}
            {compareModesManager.isFaderMode && (
                <FaderLayout {...{ containerHeight }} />
            )}
            {compareModesManager.isDiffMode && (
                <DiffLayout {...{ containerHeight }} />
            )}
            {compareModesManager.isToggleMode && (
                <ToggleLayout {...{ containerHeight }} />
            )}
            <TopToolsRow>
                <ViewerTopToolbar />
            </TopToolsRow>
            <LeftToolsColumn>
                <ViewerLeftToolbar />
            </LeftToolsColumn>
            <RightToolsColumn>
                <ViewerRightToolbar />
            </RightToolsColumn>
            {cubit.longProcessRunning && (
                <SpinnerOverlay>
                    <SpinnerColumn>
                        <Spinner color='var(--primary-color)' size={48} />
                        {cubit.longProcessPrompt && (
                            <WaitMessage>{cubit.longProcessPrompt}</WaitMessage>
                        )}
                    </SpinnerColumn>
                </SpinnerOverlay>
            )}
            <CenterMarker id='viewer-center' />
        </Wrapper>
    );
});
