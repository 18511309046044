import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FlexMotionRow, smallIconSize } from '../../styles';

// #region Styles

const IconButton = styled(motion.div)`
    position: relative;
    width: ${smallIconSize}px;
    height: ${smallIconSize}px;
    border-radius: ${Math.round(smallIconSize / 2)}px;
    background-color: ${({ $on }) => $on ? 'var(--button-default-background)' : ''};
    cursor: pointer;
`;

const MotionDiv = styled(FlexMotionRow)`
    position: absolute;
    width: ${smallIconSize}px;
    height: ${smallIconSize}px;
    justify-content: center;
    top: 0;
    left: 0;
    scale: 0.9;
    color: var(--secondary-color);
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
`;

// #endregion

const buttonVariants = {
    active: { backgroundColor: 'var(--button-default-background)' },
    default: { backgroundColor: 'rgba(255, 255, 255, 0)' },
};

export default function ToggleButton({ Icon, isOn, onClick }) {
    return (
        <IconButton
            $on={isOn}
            {...{ onClick }}
            variants={buttonVariants}
            animate={isOn ? 'active' : 'default'}
        >
            <MotionDiv
                initial={{ opacity: 0, color: 'var(--secondary-color)' }}
                animate={{ opacity: 1, color: 'var(--secondary-color)' }}
                exit={{ opacity: 0 }}
                transition={{
                    duration: 0.2,
                    ease: 'easeInOut',
                }}
                whileHover={{
                    color: 'var(--primary-color)',
                    scale: 1.1,
                    transition: { duration: 0.2 },
                }}
            >
                {Icon}
            </MotionDiv>
        </IconButton>
    );
};
