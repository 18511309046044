import { useProofXStore } from '../Store/ProofXStore';
import { generateTempUid } from '../Viewer/AnnotationLayer/Utils';

class DemoApi {
    get store() { return useProofXStore.getState(); }

    createAnnotation(assetUid, pageNum, body, json, tempUid) {
        const existingComments = this.store.assetComments;
        const newComment = {
            tempUid,
            assetUid,
            body,
            commentSeqId: (existingComments?.length ?? 0) + 1,
            commentUid: tempUid,
            created: new Date().toISOString(),
            createdDateTime: new Date().toLocaleString(),
            isComplete: false,
            isInternal: false,
            isProjectDiscussion: false,
            json,
            pageNumber: pageNum,
            userName: this.store.proofX.userName,
            userUid: this.store.proofX.environment.userUid,
        };
        const assetPageDemoComments = this.store.demoComments[`${assetUid}::${pageNum}`] ?? [];
        useProofXStore.setState({
            demoComments: {
                ...this.store.demoComments,
                [`${assetUid}::${pageNum}`]: [...assetPageDemoComments, newComment],
            },
        });
        this.store.proofX.loadComments(assetUid, pageNum);
    }

    saveAnnotation(assetUid, pageNum, annotationUid, body, json) {
        const assetPageDemoComments = this.store.demoComments[`${assetUid}::${pageNum}`] ?? [];
        const updatedComments = assetPageDemoComments.map(comment =>
            comment.commentUid === annotationUid ? { ...comment, body, json } : comment,
        );
        useProofXStore.setState({
            demoComments: {
                ...this.store.demoComments,
                [`${assetUid}::${pageNum}`]: updatedComments,
            },
        });
        this.store.proofX.loadComments(assetUid, pageNum);
    }

    addReply(assetUid, pageNum, parentUid, body, json) {
        const existingComments = this.store.assetComments;
        const newComment = {
            assetUid,
            body,
            commentSeqId: (existingComments?.length ?? 0) + 1,
            commentUid: generateTempUid(),
            created: new Date().toISOString(),
            createdDateTime: new Date().toLocaleString(),
            isComplete: false,
            isInternal: false,
            isProjectDiscussion: false,
            pageNumber: pageNum,
            parentCommentUid: parentUid,
            json,
            userName: this.store.proofX.userName,
            userUid: this.store.proofX.environment.userUid,
        };
        const assetPageDemoComments = this.store.demoComments[`${assetUid}::${pageNum}`] ?? [];
        useProofXStore.setState({
            demoComments: {
                ...this.store.demoComments,
                [`${assetUid}::${pageNum}`]: [...assetPageDemoComments, newComment],
            },
        });
        this.store.proofX.loadComments(assetUid, pageNum);
    }

    deleteAnnotation(uid) {
        const assetPage = {};
        Object.keys(this.store.demoComments).forEach(key => {
            const comments = this.store.demoComments[key];
            if (comments?.find(comment => comment.commentUid === uid)) {
                const parts = key.split('::');
                assetPage.assetUid = parts[0];
                assetPage.pageNum = parseInt(parts[1]);
            }
        });
        if (!assetPage.assetUid) return;
        const assetPageDemoComments = this.store.demoComments[`${assetPage.assetUid}::${assetPage.pageNum}`] ?? [];
        const updatedComments = assetPageDemoComments.filter(comment => comment.commentUid !== uid);
        useProofXStore.setState({
            demoComments: {
                ...this.store.demoComments,
                [`${assetPage.assetUid}::${assetPage.pageNum}`]: updatedComments,
            },
        });
        this.store.proofX.loadComments(assetPage.assetUid, assetPage.pageNum);
    }

    toggleCommentComplete(assetUid, pageNum, commentUid, isComplete) {
        const assetPageDemoComments = this.store.demoComments[`${assetUid}::${pageNum}`] ?? [];
        const updatedComments = assetPageDemoComments.map(comment =>
            comment.commentUid === commentUid ? { ...comment, isComplete } : comment,
        );
        useProofXStore.setState({
            demoComments: {
                ...this.store.demoComments,
                [`${assetUid}::${pageNum}`]: updatedComments,
            },
        });
        this.store.proofX.loadComments(assetUid, pageNum);
    }

    updateReactions(assetUid, pageNum, commentUid, reactions) {
        const assetPageDemoComments = this.store.demoComments[`${assetUid}::${pageNum}`] ?? [];
        const updatedComments = assetPageDemoComments.map(comment =>
            comment.commentUid === commentUid ? { ...comment, reactionsJson: JSON.stringify(reactions) } : comment,
        );
        useProofXStore.setState({
            demoComments: {
                ...this.store.demoComments,
                [`${assetUid}::${pageNum}`]: updatedComments,
            },
        });
        this.store.proofX.loadComments(assetUid, pageNum);
    }
}

export const demoApi = new DemoApi();
