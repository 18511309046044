import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../Store/ProofXStore';
import Collapsable from '../uicontrols/Collapsable';
import {
    Circle, DotOutline, EyeSlash,
    Square, ScribbleLoop, Eye, ArrowUpRight,
} from '@phosphor-icons/react';
import log from '../ProofX/Logger';
import ColorSelector from '../uicontrols/ColorSelector';
import { ViewerModes } from './ViewerCubit';
import sendGTMEvent from '../GTM';
import AppTool from '../AppBar/AppTool';

// #region Styles

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 18px 8px 4px 8px;
    background-color: var(--toolbar-panel-background);
    box-shadow: var(--glass-shadow);
    backdrop-filter: blur(3px);
    z-index: 1;
    margin-top: -1px;
    border-radius: 0 0 15px 15px;
`;

// #endregion

export default observer(function AnnotationTools() {
    const proofX = useProofXStore((state) => state.proofX);
    const viewer = useProofXStore((state) => state.viewer);
    const strings = useProofXStore((state) => state.strings);
    const annotationManager = useProofXStore((state) => state.annotationManager);
    const splitView = useProofXStore((state) => state.splitView);
    const isSimpleMode = useProofXStore((state) => state.proofX?.environment?.task?.simpleMode ?? false);

    const toggleDrawMode = (figureType) => {
        log('🔘', figureType);
        annotationManager?.toggleDrawMode(figureType);
        if (annotationManager?.isFreeDrawMode && proofX.isMobile) {
            splitView.closeLeftDrawer();
            splitView.closeRightDrawer();
        }
    };

    const toggleAnnotations = () => {
        if (!annotationManager) return;
        if (annotationManager.visible) {
            annotationManager.hideAnnotations();
            annotationManager?.toggleDrawMode(null);
            sendGTMEvent('Annotatate-Hide-Annotations');
        } else {
            if (!viewer.annotationsEnabledMode) {
                viewer.setMode(ViewerModes.annotations);
            }
            annotationManager.showAnnotations();
            sendGTMEvent('Annotatate-Show-Annotations');
        }
    };

    return (
        <Collapsable column isOpen={!proofX.isMobile && !isSimpleMode && viewer.annotationsCanBeDrawn && annotationManager.isDrawMode}>
            <Wrapper>
                <AppTool small
                    Icon={ArrowUpRight} title={strings.arrow}
                    isOn={annotationManager?.isArrowDrawMode}
                    onPress={() => toggleDrawMode('arrow')}
                />
                <AppTool small filled
                    Icon={DotOutline} title={strings.point}
                    isOn={annotationManager?.isPointDrawMode}
                    onPress={() => toggleDrawMode('point')}
                />
                <AppTool small
                    Icon={Square} title={strings.square}
                    isOn={annotationManager?.isSquareDrawMode}
                    onPress={() => toggleDrawMode('square')}
                />
                <AppTool small
                    Icon={Circle} title={strings.circle}
                    isOn={annotationManager?.isCircleDrawMode}
                    onPress={() => toggleDrawMode('circle')}
                />
                <AppTool small
                    Icon={ScribbleLoop} title={strings.free}
                    isOn={annotationManager?.isFreeDrawMode}
                    onPress={() => toggleDrawMode('free')}
                />
                <ColorSelector />
                {viewer.annotationsEnabledMode && (
                    <AppTool small
                        Icon={annotationManager?.visible ? EyeSlash : Eye}
                        title={annotationManager?.visible ? strings.hideAnnotations : strings.showAnnotations}
                        onPress={toggleAnnotations}
                    />
                )}
            </Wrapper>
        </Collapsable>
    );
});
