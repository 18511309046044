import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Alert from './Alert';
import { useProofXStore } from '../Store/ProofXStore';
import { AnimatePresence, motion } from 'framer-motion';
// import { useProofXStore } from '../Store/ProofXStore';

const Wrapper = styled.div`
    position: absolute;
    top: 55px;
    right: 0px;
    max-height: calc(var(--app-height) - 70px);
    overflow: hidden;
    z-index: 300000;    
`;

const Stack = styled.div`
    display: flex;
    margin-left: 24px;
    flex-direction: column;
    align-items: flex-end;
`;

const AlertContainer = styled(motion.div)`
    transform-origin: top center;
    overflow: hidden;
    backdrop-filter: var(--glass-blur);
    margin: 6px 12px;
    border-radius: 8px;
    box-shadow: var(--alert-shadow);
`;

const variants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto' },
};

export default observer(function AlertsStack() {
    const cubit = useProofXStore((state) => state.alertsStack);
    if (!cubit) return;

    return (
        <Wrapper>
            <Stack>
                <AnimatePresence>
                    {cubit.alerts.map(a =>
                        <AlertContainer key={a.uid}
                            variants={variants}
                            initial='hidden'
                            animate='visible'
                            exit='hidden'
                            transition={{
                                duration: 0.25,
                                type: 'spring',
                                bounce: 0.35,
                            }}
                        >
                            <Alert message={a.message} severity={a.severity} onClick={() => cubit.dismissAlert(a.uid)} />
                        </AlertContainer>,
                    )}
                </AnimatePresence>
            </Stack>
        </Wrapper>
    );
});
