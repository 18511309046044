/* global fabric */

import FigureCubit, { styles } from '../FigureCubit';
import { getImageDimensions, getRotatedDimensions } from '../Utils';

export default class SquareFigure extends FigureCubit {
    createShape() {
        this._figure = new fabric.Rect({
            type: 'figure',
            figureType: 'square',
            fill: 'transparent',
            strokeWidth: styles.figureStroke / this._layer.getZoom(),
            stroke: this._annotationCubit.color,
            selectable: false, // this._annotationCubit.canBeEdited,
            perPixelTargetFind: true,
            opacity: styles.opacity.default,
            noScaleCache: false,
        });
        this._layer.add(this._figure);
        return this._figure;
    }

    _continueDraw(pointer) {
        const startingPoint = this._drawingProps.startingPoint;
        pointer = pointer ?? startingPoint;
        const freePosition = {
            left: Math.min(startingPoint.x, pointer.x),
            top: Math.min(startingPoint.y, pointer.y),
            width: Math.abs(startingPoint.x - pointer.x),
            height: Math.abs(startingPoint.y - pointer.y),
        };
        const dimensions = getImageDimensions();
        this._position = {
            x: Math.max(0, freePosition.left),
            y: Math.max(0, freePosition.top),
        };
        this._dimensions = {
            x: Math.min(freePosition.width, dimensions.x - freePosition.left),
            y: Math.min(freePosition.height, dimensions.y - freePosition.top),
        };
        this.placeShape(this._position, this._dimensions);
    };

    placeShape(position, dimensions) {
        this._position = position;
        this._dimensions = { x: Math.max(1, dimensions.x), y: Math.max(1, dimensions.y) };
        const mod = getRotatedDimensions(position, this._dimensions);
        this._figure.set({
            left: mod.position.x,
            top: mod.position.y,
            width: mod.dimensions.x,
            height: mod.dimensions.y,
        });
    }

    findLineEnd(startPoint) {
        const f = this._figure;
        const points = [
            new fabric.Point(f.left, f.top),
            new fabric.Point(f.left + f.width, f.top),
            new fabric.Point(f.left + f.width, f.top + f.height),
            new fabric.Point(f.left, f.top + f.height),
        ];
        const a1 = new fabric.Point(startPoint.x, startPoint.y);
        const a2 = new fabric.Point(
            f.left + f.width / 2,
            f.top + f.height / 2,
        );
        const intersection = fabric.Intersection.intersectLinePolygon(a1, a2, points);

        if (intersection.status === 'Intersection') {
            const { x, y } = intersection.points[0];
            return { x, y };
        }
        return {
            x: f.left,
            y: f.top,
        };
    };
}
