import { observable, computed, action, makeObservable } from 'mobx';
import { api } from '../../Api/ProofXApi';
import { useProofXStore } from '../../Store/ProofXStore';
import EditorCubit from '../../uicontrols/CommentEditor/EditorCubit';
import DiscussionCubit from './DiscussionCubit';
import sendGTMEvent from '../../GTM';

export const discussionSources = {
    asset: 'asset',
    project: 'project',
};

export default class DiscussionPanelCubit {
    _source = discussionSources.asset;
    _discussionCubit = null;
    _newCommentEditor = null;

    constructor() {
        makeObservable(this, {
            _source: observable,
            _discussionCubit: observable,
            _newCommentEditor: observable,

            source: computed,
            discussionCubit: computed,
            isAssetComments: computed,
            newCommentEditor: computed,

            toggleSource: action,
            updateDiscussion: action,

        });
        this._discussionCubit = new DiscussionCubit();
        this._newCommentEditor = new EditorCubit(
            'new-project-comment',
            this.createNewProjectComment.bind(this),
            this.cleanNewProjectComment.bind(this),
            this.store.proofX.isMobile,
        );
    };

    get source() { return this._source; }
    get isAssetComments() { return this._source === discussionSources.asset; }
    get discussionCubit() { return this._discussionCubit; }
    get newCommentEditor() { return this._newCommentEditor; }
    get store() { return useProofXStore.getState(); }

    toggleSource(source) {
        this._source = source ??
            (this._source === discussionSources.asset ? discussionSources.project : discussionSources.asset);

        this._discussionCubit.updateComments();
        if (this._source === discussionSources.project) {
            this.store.proofX.loadProjectDiscussion();
        }
    }

    updateDiscussion() {
        this._discussionCubit.updateComments();
    }

    highlightComment(commentUid) {
        if (this._source !== discussionSources.asset) {
            this.toggleSource(discussionSources.asset);
        }
        this._discussionCubit.highlightComment(commentUid);
    }

    createNewProjectComment() {
        const html = this._newCommentEditor.html.trim();
        if (html === '') return;
        this._newCommentEditor.initWithHtml('');
        this._discussionCubit.setScrollDownAfterRebuild(true);
        api.postNewProjectComment(this.store.proofX.environment.projectUid, html);
        sendGTMEvent('Discussion-Discussion-Added');
    }

    cleanNewProjectComment() {
        this._newCommentEditor.initWithHtml('');
    }
}
