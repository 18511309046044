import { observable, computed, action, makeObservable } from 'mobx';
import { AssetTypes, useProofXStore } from '../Store/ProofXStore';
import { api } from '../Api/ProofXApi';
import sendGTMEvent from '../GTM';

export default class ThumbnailCubit {
    // #region Fields

    _asset = {
        uid: null,
        title: '',
        version: 0,
        pages: [],
    };

    _expanded = true;
    _selectedPage = 0;
    _isComparePanel = false;
    _expandedLayersForPage = [];
    _loadedLayer = null;
    _layersLoading = false;

    // #endregion

    constructor(asset, isComparePanel) {
        makeObservable(this, {
            _asset: observable,
            _expanded: observable,
            _selectedPage: observable,
            _expandedLayersForPage: observable,
            _loadedLayer: observable,
            _layersLoading: observable,

            asset: computed,
            expanded: computed,
            selected: computed,
            layersLoading: computed,

            updateAsset: action,
            onPageClicked: action,
            toggleFolding: action,
            setSelectedPage: action,
            setLoadedLayer: action,
            _setPdfLayersLoading: action,
            toggleLayersExpandedForPage: action,
        });
        this._isComparePanel = isComparePanel ?? false;
        this._asset = asset;
    };

    // #region Interface

    // #region getters

    get asset() { return this._asset; }
    get expanded() { return this._expanded; }
    get selected() { return this._selectedPage > 0; }
    get selectedPage() { return this._selectedPage; }
    get isComparePanel() { return this._isComparePanel; }
    get isSelectiveApproval() { return !this._isComparePanel && (this.store.proofX?.isSelectiveApproval && this.store.proofX?.showApprovalTools); }
    get layersLoading() { return this._layersLoading; }
    get store() { return useProofXStore.getState(); }

    // #endregion

    // #region actions

    onPageClicked(pageNum) {
        const page = pageNum - 1;
        this.setSelectedPage(pageNum);
        this.setLoadedLayer(null);
        this._loadAssetPage(page, this._isComparePanel ? AssetTypes.compare : AssetTypes.main);
    };

    onCheckboxClicked() {
        this.store.proofX.toggleSelectiveApprovalAssetCheck(this.asset.uid);
    };

    isAssetChecked() {
        return this.store.proofX.isSelectiveApprovalAssetChecked(this.asset.uid);
    }

    setSelectedPage(pageNum) {
        if (pageNum > 0) {
            const panel = this._isComparePanel ? this.store.compareThumbnailsPanel : this.store.mainThumbnailsPanel;
            panel.dropSelection();
        }
        this._selectedPage = pageNum;
    }

    setLoadedLayer(layer) {
        if (layer) {
            this.store.mainThumbnailsPanel.clearLoadedLayer();
        }
        this._loadedLayer = layer;
    }

    areLayersExpandedForPage(pageNum) {
        return this._expandedLayersForPage.includes(pageNum);
    }

    toggleLayersExpandedForPage(pageNum) {
        if (this.areLayersExpandedForPage(pageNum)) {
            this._expandedLayersForPage = this._expandedLayersForPage.filter(p => p !== pageNum);
        } else {
            this._expandedLayersForPage.push(pageNum);
        }
    }

    async updateAsset(asset) {
        this._asset = asset;
        if (this._layersLoading && asset.layersLoaded) {
            this._setPdfLayersLoading(false);
        }
        const mainAsset = this.store.assets.main;
        if (mainAsset?.uid === asset.uid) {
            const page = asset.pages.find(p => p.pageNum === mainAsset.page + 1);
            mainAsset.numberOfComments = page.numberOfComments;
        }
    }

    toggleFolding() {
        this._expanded = !this._expanded;
    };

    loadLayer(pageNum, dir) {
        const page = pageNum - 1;
        this.setSelectedPage(pageNum);
        this.setLoadedLayer({ pageNum, dir });
        this._loadAssetPageLayer(page, dir);
    }

    isLayerActive(pageNum, dir) {
        return this._loadedLayer?.pageNum === pageNum && this._loadedLayer?.dir === dir;
    }

    async generatePdfLayers() {
        await api.generatePdfLayers(this.asset.uid);
        this._setPdfLayersLoading(true);
        sendGTMEvent('RTUtilities-PDF-Layers');
    }

    // #endregion

    // #region private methods

    _loadAssetPage(page, assetType) {
        const { viewer } = this.store;
        const asset = this.store.assets[assetType];
        if (asset?.uid === this.asset.uid && asset.page === page && !asset.layerDir) return;
        const direction = this._getSlidingDirection(asset, { uid: this.asset.uid, page });
        viewer?.loadAsset(assetType, this.asset, page, direction);
    }

    _loadAssetPageLayer(page, dir) {
        const { viewer } = this.store;
        const asset = this.store.assets.main;
        if (asset?.uid === this.asset.uid && asset?.page === page && asset?.layerDir === dir) return;
        const direction = this._getSlidingDirection(asset, { uid: this.asset.uid, page });
        viewer?.loadAsset('main', this.asset, page, direction, dir);
    }

    _getSlidingDirection(previousAsset, newAasset) {
        const { proofX } = this.store;
        const prevAssetIndex = previousAsset ? proofX.getThumbnailIndex(previousAsset, previousAsset) : 0;
        const newAssetIndex = proofX.getThumbnailIndex(newAasset);
        return Math.sign(prevAssetIndex - newAssetIndex);
    }

    _setPdfLayersLoading(isOn) {
        this._layersLoading = isOn;
    }

    // #endregion

    // #endregion
}
