import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Drawer from './Drawer';
import ThumbnailsPanel from '../ThumbnailsPanel/ThumbnailsPanel';
import Viewer from '../Viewer/Viewer';
import { useProofXStore } from '../Store/ProofXStore';
import RightPanel from '../RightPanel/RightPanel';

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
`;

const Content = styled.div`
    flex-grow: 1;
    z-index: 100;
    display: flex;
    background-color: var(--pure-bg-color);       
`;

const BackgroundWrapper = styled.div`
    flex-grow: 1;
    background: ${({ bg, img }) => img ? `url(data:image/jpeg;base64,${img})` : `var(--bg-pattern-${bg})`};
`;

export default observer(function SplitView() {
    const mainThumbnailsPanel = useProofXStore((state) => state.mainThumbnailsPanel);
    const viewer = useProofXStore((state) => state.viewer);
    const customBackground = useProofXStore((state) => state.proofX?.environment?.customBackgroundImg);

    return (
        <Wrapper>
            <Drawer side='left'>
                <ThumbnailsPanel cubit={mainThumbnailsPanel} />
            </Drawer>
            <Content>
                <BackgroundWrapper bg={viewer?.backgroundPattern ?? 'default'} img={viewer?.backgroundPattern === 'custom' ? customBackground : null}>
                    <Viewer cubit={viewer} />
                </BackgroundWrapper>
            </Content>
            <Drawer side='right'>
                <RightPanel cubit={viewer} />
            </Drawer>
        </Wrapper>
    );
});
