import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';
import FoldIcon from '../uicontrols/FoldIcon';
import FoldingPanel from '../uicontrols/FoldingPanel';
import { useProofXStore } from '../Store/ProofXStore';
import { ChatCircleDots, ArchiveBox, Stack } from '@phosphor-icons/react';
import Checkbox from '../uicontrols/Checkbox';
import Tooltip from '../uicontrols/Tooltip';
import HoverClarifier from '../uicontrols/HoverClarifier';
import Spinner from '../uicontrols/Spinner';
import { FlexRow } from '../../styles';

// #region Styles

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid var(--glass-border);
    background-color: ${({ selected }) => selected ? 'var(--discussion-asset-active)' : ''};  

    :last-of-type {
        border-bottom: none;
    } 
`;

const Title = styled.div`
    margin: 12px 0 12px 8px;
    font-size: 11px;
    font-weight: ${({ selected }) => selected ? 'bold' : 'normal'};
    overflow: hidden;
    user-select: text;
    text-overflow: ellipsis;
`;

const LoadLayersButton = styled(FlexRow)`
    margin: 4px 8px 8px 22px;
    padding: 8px;
    font-size: 12px;
    background-color: var(--primary-color);
    border-radius: 8px;
    color: var(--colored-button-text);
    box-shadow: var(--glass-shadow);
`;

const PdfLayersLoadingPrompt = styled(FlexRow)`
    font-size: 10px;
    margin: 0 8px 8px 20px;
    background-color: var(--glass-background);
    padding: 4px;
    border-radius: 8px;
`;

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 12px 12px;
`;

const Border = styled(motion.div)`
    position: relative;    
    border-radius: 4px;
    width: var(--thumbnail-width);
    
    box-shadow: ${({ selected }) => selected ? 'var(--selected-asset-border-color) 0px 0px 7px 7px' : 'var(--glass-shadow)'};
    cursor: ${({ selected }) => selected ? 'default' : 'pointer'};
`;

const Container = styled.div`
    display: flex;
    height: ${({ height }) => height ? `${height}px` : 'auto'};
`;

const ThumbnailImg = styled.img.attrs(({ thumbnail }) => ({
    src: `data:image/jpeg;base64,${thumbnail}`,
}))`
    width: var(--thumbnail-width);
    height: auto;
    max-height: 400px;
`;

const Version = styled.div`
    font-size: 10px;
`;

const Page = styled.div`
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 2px 4px;
    border-width: 1px 0 0 1px;
    background: var(--discussion-page-badge);
    border-color: var(--control-border);
    border-style: solid;
    border-radius: 4px 0 0 0;
    font-size: 12px;
    font-family: var(--medium);
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    :hover {
        background-color: ${({ selected }) => selected ? '' : 'var(--discussion-asset-active)'};
    }
`;

const CommentsNumber = styled(FlexRow)`
    position: absolute;
    top: 4px;
    left: 4px;
    padding: 2px 5px;
    border-radius: 12px;
    border: 1px solid var(--glass-border);
    background-color: var(--primary-color);
    color: white;
    font-size: 12px;
    font-family: var(--medium);
    box-shadow: 0px 1px 8px 1px var(--glass-shadow-color);
    cursor: default;
`;

const CheckBoxContainer = styled.div`
    position: absolute;
    top: 4px;
    right: 4px;    
    color: var(--colored-button-text);
    border-radius: 2px;
    z-index: 10;
`;

const CheckboxBackground = styled.div`
    position: absolute;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    top: 1px;
    left: 1px;
    z-index: -1;
    background-color: var(--primary-color);
`;

const CommentsIcon = styled(ChatCircleDots).attrs(() => ({
    size: 16,
    weight: 'fill',
}))`
    color: white;
    margin-right: 4px;
`;

const LayersBadgeContainer = styled.div`
    position: absolute;
    bottom: 4px;
    left: 4px;
`;

const LayersBadge = styled(FlexRow)`
    padding: 2px 5px;
    border-radius: 12px;
    border: 1px solid var(--glass-border);
    background-color: var(--secondary-color);
    color: white;
    font-size: 12px;
    font-family: var(--medium);
    box-shadow: 0px 1px 8px 1px var(--glass-shadow-color);
    cursor: pointer;
`;

const LayersIcon = styled(Stack).attrs(() => ({
    size: 16,
    weight: 'fill',
}))`
    color: white;
    margin-right: 4px;
`;

const LayersContainer = styled(motion.div)`
    overflow: hidden;
    max-width: 160px;
`;

const Layers = styled.div`
    padding: 8px 8px 4px 8px;
    margin: 0 8px 6px 8px;
    border: 1px solid var(--control-border);
    border-radius: 0 0 8px 8px;
    display: flex;
    background-color: var(--discussion-comment-background);
    box-shadow: 0 6px 6px -4px var(--glass-shadow-color);
    flex-direction: column;
`;

const LayerLink = styled(FlexRow)`
    font-size: 12px;
    padding: 4px;
    border-radius: 8px;
    background-color: ${({ selected }) => selected ? 'var(--highlighted-background)' : ''};
    

    :hover {
        color: var(--primary-color);
    }
`;

const LayerName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

// #endregion

const layersVariants = {
    collapsed: { height: 0 },
    expanded: { height: 'auto' },
};

export default observer(function Thumbnail({ cubit }) {
    const { asset, expanded, selected, selectedPage } = cubit;
    const strings = useProofXStore.getState().strings;
    const compareModesManager = useProofXStore.getState().compareModesManager;
    const onPageClicked = cubit.onPageClicked.bind(cubit);
    const onCheckboxClicked = cubit.onCheckboxClicked.bind(cubit);

    return (
        <Wrapper selected={selected}>
            <FlexRow>
                <FoldIcon isOpen={expanded} size={18} onClick={() => cubit.toggleFolding()} />
                <Title selected={selected}>{asset.title} <Version>{`(${strings.version} ${asset.version})`}</Version> </Title>
            </FlexRow>
            <FoldingPanel isOpen={expanded} initiallyOpen>
                {!compareModesManager.activeMode && (<>
                    {asset.layersExist && !asset.layersLoaded && !cubit.layersLoading && (
                        <HoverClarifier initialOpacity={1}>
                            <LoadLayersButton onClick={() => cubit.generatePdfLayers()}>
                                <ArchiveBox size={14} style={{ marginRight: 4 }} />{strings.loadPdfLayers}
                            </LoadLayersButton>
                        </HoverClarifier>
                    )}
                    {cubit.layersLoading && (
                        <PdfLayersLoadingPrompt>
                            <Spinner size={12} />
                            <span style={{ marginLeft: 4 }}>{strings.loadingPdfLayers}</span>
                        </PdfLayersLoadingPrompt>
                    )}
                </>)}
                {asset.pages.map(p => {
                    const pageSelected = selectedPage === p.pageNum;
                    return (
                        <PageContainer key={asset.uid + p.pageNum}>
                            <Border
                                animate={
                                    pageSelected
                                        ? { boxShadow: '0px 0px 0px 3px var(--selected-asset-border-color)' }
                                        : { boxShadow: 'var(--glass-shadow)' }
                                }
                                selected={pageSelected}
                                whileHover={{
                                    scale: 1.05,
                                    transition: { duration: 0.2, ease: 'easeInOut' },
                                }}
                                whileTap={{
                                    scale: 1,
                                    transition: { duration: 0.2, ease: 'easeInOut' },
                                    boxShadow: '0 0 3px 2px transparent',
                                }}
                            >
                                <Container selected={pageSelected}>
                                    <ThumbnailImg thumbnail={p.thumbnail} />
                                    <Page>{p.pageNum}</Page>
                                </Container>
                                <Overlay selected={pageSelected} onClick={() => onPageClicked(p.pageNum)} />

                                {/* Annotations Counter */}
                                {!cubit.isComparePanel && p.numberOfComments > 0 && (
                                    <CommentsNumber>
                                        <Tooltip flex text={strings.annotationsOnPage}>
                                            <CommentsIcon />{`${p.numberOfComments}`}
                                        </Tooltip>
                                    </CommentsNumber>
                                )}

                                {/* Selective Approval Checkbox */}
                                {cubit.isSelectiveApproval && (
                                    <CheckBoxContainer>
                                        <CheckboxBackground />
                                        <Checkbox filled size={18}
                                            checked={cubit.isAssetChecked()}
                                            onClick={() => onCheckboxClicked()}
                                        />
                                    </CheckBoxContainer>
                                )}

                                {/* Layers Badge */}
                                {!compareModesManager.activeMode && p.layers?.length > 0 && (
                                    <LayersBadgeContainer>
                                        <Tooltip text={cubit.areLayersExpandedForPage(p.pageNum) ? strings.hidePdfLayers : strings.showPdfLayers}>
                                            <HoverClarifier initialOpacity={1}>
                                                <LayersBadge onClick={() => cubit.toggleLayersExpandedForPage(p.pageNum)}>
                                                    <LayersIcon />
                                                    {p.layers.length}
                                                </LayersBadge>
                                            </HoverClarifier>
                                        </Tooltip>
                                    </LayersBadgeContainer>
                                )}
                            </Border>

                            {/* Layers Panel */}
                            {!compareModesManager.activeMode && p.layers?.length > 0 && (
                                <LayersContainer
                                    variants={layersVariants}
                                    initial='collapsed'
                                    animate={cubit.areLayersExpandedForPage(p.pageNum) ? 'expanded' : 'collapsed'}
                                    transition={{ duration: 0.2, ease: 'easeInOut' }}
                                >
                                    <Layers>
                                        {p.layers.map(l => (
                                            <HoverClarifier key={l.layerId} initialOpacity={1}>
                                                <LayerLink selected={cubit.isLayerActive(p.pageNum, l.dir)} onClick={() => cubit.loadLayer(p.pageNum, l.dir)}>
                                                    <Stack style={{ marginRight: 4, minWidth: 12 }} />
                                                    <LayerName>{l.layerName}</LayerName>
                                                </LayerLink>
                                            </HoverClarifier>
                                        ))}
                                    </Layers>
                                </LayersContainer>
                            )}
                        </PageContainer>
                    );
                })}
            </FoldingPanel>
        </Wrapper>
    );
});
