import React from 'react';
import { XCircle } from '@phosphor-icons/react';
import styled from 'styled-components';
import HoverClarifier from '../uicontrols/HoverClarifier';

// #region Styles

const Wrapper = styled.div`
    position: absolute;
    top: -12px;
    right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Background = styled.div`
    position: absolute;
    background-color: var(--popover-background);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    z-index: -1;
    top: 4px;
    left: 4px;
`;

const CloseButton = styled(XCircle).attrs(() => ({
    size: 24,
    weight: 'fill',
}))`        
    color: var(--secondary-color);
`;

// #endregion Styles

export function TourCloseButton({ onClick }) {
    return (
        <Wrapper>
            <Background />
            <HoverClarifier initialOpacity={1}>
                <CloseButton onClick={onClick} />
            </HoverClarifier>
        </Wrapper>
    );
}
