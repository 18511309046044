import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { motion, AnimatePresence } from 'framer-motion';
import { useProofXStore } from '../../../Store/ProofXStore';

const Underlay = styled(motion.div)`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background: var(--background-gradient-outer-color);
`;

export default observer(function TextBoxUnderlay() {
    const annotationManager = useProofXStore((state) => state.annotationManager);
    return (
        <AnimatePresence>
            {annotationManager.isEditMode && (
                <Underlay
                    onClick={annotationManager.onTextboxUnderlayClick.bind(annotationManager)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.4 }}
                    exit={{ opacity: 0 }}
                    transition={{
                        duration: 0.25,
                        ease: 'easeInOut',
                    }}
                />

            )}
        </AnimatePresence>
    );
});
