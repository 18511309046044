/* global fabric */

import { useProofXStore } from '../../Store/ProofXStore';
import {
    rotationMatrix,
    getRotatedPoint,
    getRotationAngle,
    getRotatedDimensions,
    getRotatedImageBounds,
    getUnrotatedPoint,
} from './Utils';

export const styles = {
    pointFigureRadius: 3,
    figureStroke: 4,
    textBoxSize: { width: 140, height: 30 },
    opacity: { default: 1 },
};

// @abstract
export default class FigureCubit {
    _id = null;
    _figure = null;
    _position = null;
    _dimensions = null;
    _annotationCubit = null;
    _color = { default: '#000000', hover: '#000000', selected: '#000000' };
    _connectingLine = null;
    _boundingRect = null;
    _active = false;
    _highlighted = false;
    _scalingProps = null;
    _movingProps = null;
    _drawingProps = null;
    _isNew = false;

    constructor(id, annotation) {
        if (this.constructor === FigureCubit) {
            throw new Error('You should not instanciate a FigureCubit constructor');
        }
        this._id = id;
        this._annotationCubit = annotation;
        this._color = annotation.colorVariants;
    }

    get id() { return this._id; }
    get annotationId() { return this._annotationCubit?.id; }
    get isFigure() { return true; }
    get position() { return this._position; }
    get dimensions() { return this._dimensions; }
    get zoom() { return this._layer.getZoom(); }
    get active() { return this._active; }
    get isNew() { return this._isNew; }
    get store() { return useProofXStore.getState(); }
    get stage() { return this.store.annotationManager.stage; }
    get _layer() { return this.store.fabricLayer; }

    get figureType() {
        if (!this._figure || !this._figure.figureType) {
            throw new Error('Figure type is undefined.');
        }
        return this._figure.figureType;
    }

    get _rotatedCorners() {
        const coords = this._boundingRect?.getBoundingRect(true, true);
        if (!coords) return null;
        return {
            lt: { x: coords.left, y: coords.top },
            rb: { x: coords.left + coords.width, y: coords.top + coords.height },
        };
    }

    setNew(isNew) {
        this._isNew = isNew;
    }

    update(position, dimensions) {
        if (!this._layer) return;

        this._position = position ?? this._position;
        this._dimensions = dimensions ?? this._dimensions;
        this._color = this._annotationCubit.colorVariants;
        if (!this._figure) {
            this._removeOrphans();
            this._figure = this.createShape();
            this._mountResizeControls();
        }

        this.placeShape(this._position, this._dimensions);
        this._figure.annotationCubit = this._annotationCubit;
        this._figure.figureCubit = this;
        this._figure.setCoords();
        this._figure.set({ stroke: this._color.default });
        this._renderConnectingLine();
        this._updateShadow();
        this._updateBoundingRect();
        this._layer.renderAll();
    }

    updateZoom() {
        this._updateZoom();
        this._renderConnectingLine();
        this._updateShadow();
        this._updateBoundingRect();
        this._layer.renderAll();
    }

    updateColor() {
        this._color = this._annotationCubit.colorVariants;
        this._figure.set({ stroke: this._color.default });
        this._renderConnectingLine();
        this._layer.renderAll();
    }

    // overridden for Point figure type
    _updateZoom() {
        this._figure.set({
            strokeWidth: styles.figureStroke / this.zoom,
        });
    }

    updateConnectingLine() {
        this._renderConnectingLine();
    }

    hover(isOn, shouldPropagate) {
        this._highlight(isOn);

        if (shouldPropagate) {
            this._annotationCubit.hover(isOn, this);
        }
    }

    select() {
        if (!this._annotationCubit.editable) return;
        this._active = true;
        this._highlight(true);
        this._toggleResizeControls(true);
        this.store.annotationManager.toggleDrawMode(null);
    }

    unselect() {
        this._active = false;
        this._highlight(false);
        this._toggleResizeControls(false);
    }

    handleMoving() {
        if (!this._moving) {
            this._moving = true;
            this._movingProps = { originalPosition: { x: this._boundingRect.left, y: this._boundingRect.top } };
            this._figure.bringToFront();
            this._boundingRect.bringToFront();
        }
        if (this.store.proofX.isMobile) {
            this.stage.toggleMouseTracker(false);
        }
        this._move();
    }

    handleScaling() {
        if (this.store.proofX.isMobile) {
            this.stage.toggleMouseTracker(false);
        }
        if (!this._scalingProps) {
            this._startScaling();
        }
        this._scale();
    }

    endMovingOrScaling() {
        console.log('endMovingOrScaling');
        this._calculateNewPosition();
        if (this._scalingProps) {
            this._calculateNewDimensions();
            this._scalingProps = null;
        }
        if (this.store.proofX.isMobile) {
            this.stage.toggleMouseTracker(true);
        }
        this._annotationCubit.save();

        this._moving = false;
    }

    startDraw(pointer) {
        this._drawingProps = {
            startingPoint: getUnrotatedPoint(pointer),
        };
        this._figure = this.createShape();
        this._continueDraw();
        this._layer.renderAll();

        const stage = this.store.annotationManager.stage;
        stage.toggleMouseTracker(false);
    };

    dispose() {
        this.store.annotationManager.clearFigureSelection();
        this._figure?.remove();
        this._connectingLine?.remove();
        this._boundingRect?.remove();
        this.figure = null;
        this._connectingLine = null;
        this._boundingRect = null;
    }

    show() {
        if (this._figure) this._figure.visible = true;
        if (this._connectingLine) this._connectingLine.visible = true;
    }

    hide() {
        this.unselect();
        if (this._figure) this._figure.visible = false;
        if (this._connectingLine) this._connectingLine.visible = false;
    }

    _renderConnectingLine() {
        if (!this._annotationCubit._textBoxCubit) return;

        if (this._connectingLine === null) {
            this._connectingLine = new fabric.Line([], {
                type: 'connectionLine',
                stroke: this._color.default,
                selectable: false,
                perPixelTargetFind: true,
                hoverCursor: 'default',
                noScaleCache: false,
                visible: true,
            });
            this._layer.add(this._connectingLine);
        }
        const textBox = this._annotationCubit._textBoxCubit;
        const startPoint = getRotatedPoint({
            x: textBox.position.x,
            y: textBox.position.y,
        });

        const endPoint = this.findLineEnd(startPoint);
        this._connectingLine.set({
            x1: startPoint.x,
            y1: startPoint.y,
            x2: endPoint.x,
            y2: endPoint.y,
            stroke: this._color.default,
            strokeWidth: 1 / this.zoom,
            opacity: 0.5,
        });
        this._connectingLine.sendToBack();
    };

    _updateShadow() {
        const zoom = this.zoom;
        let [left, top, blur, color] = [
            `${Math.ceil(2 / zoom)}px`,
            `${Math.ceil(2 / zoom)}px`,
            `${Math.ceil(2 / zoom)}px`,
            this._color.shadow,
        ];
        this._figure.setShadow(`${left} ${top} ${blur} ${color}`);

        [left, top, blur, color] = [
            `${Math.ceil(1 / zoom)}px`,
            `${Math.ceil(1 / zoom)}px`,
            `${Math.ceil(1 / zoom)}px`,
            this._color.shadow,
        ];
        this._connectingLine?.setShadow(`${left} ${top} ${blur} ${color}`);
    }

    // overridden for Point figure type
    _calculateBoundingRectPosition() {
        const mod = getRotatedDimensions(this._position, this._dimensions);
        const zoom = this.zoom;
        this._boundingRect.set({
            left: mod.position.x,
            top: mod.position.y,
            width: mod.dimensions.x + (styles.figureStroke / 2 / zoom),
            height: mod.dimensions.y + (styles.figureStroke / 2 / zoom),
            scaleX: 1,
            scaleY: 1,
        });
    }

    _updateBoundingRect() {
        if (!(this._boundingRect?.visible)) return;
        this._calculateBoundingRectPosition();
        this._boundingRect.setCoords();
    }

    _removeOrphans() {
        this._layer._objects.filter(o =>
            o.type === 'figure' && o.figureCubit.annotationId === this.annotationId && o.figureCubit.id === this.id)
            .forEach(f => {
                f.remove();
                f.figureCubit._connectingLine?.remove();
            });
    }

    _highlight(isOn) {
        if (
            (isOn && this._highlighted) ||
            (!isOn && !this._highlighted) ||
            (!isOn && this._active)
        ) return;
        fabric.runningAnimations?.cancelByTarget(this._figure);

        const color = this._color;
        const colors = {
            from: isOn ? color.default : color.hover,
            to: isOn ? color.hover : color.default,
        };
        fabric.util.animateColor(colors.from, colors.to, 300, {
            easing: isOn ? fabric.util.ease.easeIn : fabric.util.ease.easeOut,
            onChange: (value) => this._onColorAnimated(value),
        });
        this._highlighted = isOn;
    }

    // Overridden for Point figure type
    _onColorAnimated(value) {
        this._figure?.set({ stroke: value });
        if (this.figureType === 'arrow') {
            this._figure?.set({ fill: value });
        }
        this._connectingLine?.set({ stroke: value });
        this._layer.renderAll();
    }

    handleDrawing(pointer) {
        const bounds = getRotatedImageBounds();
        const bounded = {
            x: Math.min(Math.max(pointer.x, bounds.lt.x), bounds.rb.x),
            y: Math.min(Math.max(pointer.y, bounds.lt.y), bounds.rb.y),
        };
        const unrotatedMouse = getUnrotatedPoint(bounded);
        this._continueDraw(unrotatedMouse);
        this._layer.renderAll();
    }

    handleEndDrawing() {
        this._finalizeShape && this._finalizeShape();
        if (this.figureType !== 'point' && this._dimensions.x + this._dimensions.y < 4) {
            this.dispose();
            this._layer.renderAll();
            return;
        }
        this._mountResizeControls();
        this._annotationCubit.finalizeFigure(this);
    }

    // #region moving and scaling

    _mountResizeControls() {
        if (this._boundingRect) return;
        const computed = getComputedStyle(document.body);
        this._boundingRect = new fabric.Rect({
            type: 'boundingRect',
            fill: '#cccccc33',
            selectable: true,
            perPixelTargetFind: false,
            hasBorders: false,
            hasControls: this.figureType !== 'point' && this.figureType !== 'arrow',
            hasRotatingPoint: false,
            transparentCorners: false,
            cornerSize: 10,
            cornerStyle: 'circle',
            hoverCursor: 'move',
            cornerStrokeColor: computed.getPropertyValue('--primary-color'),
            cornerColor: computed.getPropertyValue('--figure-resizer-background'),
            opacity: styles.opacity.default,
        });
        this._boundingRect.figureCubit = this;
        this._boundingRect.visible = false;
        this._layer.add(this._boundingRect);
    }

    _toggleResizeControls(isOn) {
        if (!this._boundingRect) {
            this._mountResizeControls();
        }
        this._boundingRect.visible = isOn;
        this._boundingRect.set('active', isOn);
        if (isOn) {
            this._updateBoundingRect();
            this._boundingRect.bringToFront();
        }
        if (this.figureType === 'arrow') {
            this._startResizePoint.visible = isOn;
            this._endResizePoint.visible = isOn;
            this._startResizePoint.bringToFront();
            this._endResizePoint.bringToFront();
        };
        this._layer.renderAll();
    }

    _getBoundedDimensions() {
        const bounds = getRotatedImageBounds();
        const corners = this._rotatedCorners;
        if (!corners) return null;
        const bounded = this._moving
            ? this._getBoundedDimensionsForMoving(bounds, corners)
            : this._getBoundedDimensionsForScaling(bounds, corners);

        this._boundingRect.setPositionByOrigin({ x: bounded.x, y: bounded.y }, 'left', 'top');
        return bounded;
    }

    _getBoundedDimensionsForMoving(bounds, corners) {
        const frameSize = {
            x: corners.rb.x - corners.lt.x,
            y: corners.rb.y - corners.lt.y,
        };
        const boundedPosition = {
            x: Math.max(bounds.lt.x, Math.min(corners.lt.x, bounds.rb.x - frameSize.x)),
            y: Math.max(bounds.lt.y, Math.min(corners.lt.y, bounds.rb.y - frameSize.y)),
        };
        const bounded = {
            x: boundedPosition.x,
            y: boundedPosition.y,
            width: frameSize.x,
            height: frameSize.y,
        };
        return bounded;
    }

    _getBoundedDimensionsForScaling(bounds, corners) {
        const frameSize = {
            x: corners.rb.x - corners.lt.x,
            y: corners.rb.y - corners.lt.y,
        };
        const boundedCorners = {
            lt: {
                x: Math.max(corners.lt.x, bounds.lt.x),
                y: Math.max(corners.lt.y, bounds.lt.y),
            },
            rb: {
                x: Math.min(corners.rb.x, bounds.rb.x),
                y: Math.min(corners.rb.y, bounds.rb.y),
            },
        };
        const boundedSize = {
            x: boundedCorners.rb.x - boundedCorners.lt.x,
            y: boundedCorners.rb.y - boundedCorners.lt.y,
        };
        const currentScale = {
            scaleX: this._boundingRect.scaleX,
            scaleY: this._boundingRect.scaleY,
        };
        const sizeLocked = {
            x: frameSize.x > boundedSize.x,
            y: frameSize.y > boundedSize.y,
        };
        const prevScale = this._scalingProps.prevScale ?? currentScale;
        const newScale = {
            scaleX: sizeLocked.x ? prevScale.scaleX : currentScale.scaleX,
            scaleY: sizeLocked.y ? prevScale.scaleY : currentScale.scaleY,
        };
        this._boundingRect.set(newScale);
        this._scalingProps.prevScale = newScale;
        const bounded = {
            x: boundedCorners.lt.x,
            y: boundedCorners.lt.y,
            width: boundedSize.x,
            height: boundedSize.y,
        };
        return bounded;
    }

    _startScaling() {
        this._scalingProps = { originalDimensions: this._dimensions };
        this._boundingRect.bringToFront();
        this._figure.bringToFront();
    }

    _scale() {
        this._calculateNewPosition();
        this._calculateNewDimensions();
        this.placeShape(this._position, this._dimensions);
        this._annotationCubit.updateConnectingLines();
        this._layer.renderAll();
    }

    _move() {
        this._calculateNewPosition();
        this.placeShape(this._position, this._dimensions);
        this._annotationCubit.updateConnectingLines();
        this._layer.renderAll();
    }

    // overridden for Arrow, Point and Free figure types
    _calculateNewPosition() {
        const zoom = this.zoom;
        const angle = getRotationAngle();
        const bounded = this._getBoundedDimensions();
        const strokeWidth = styles.figureStroke / zoom;
        const rotationFactor = rotationMatrix[angle];
        const rotatedPosition = {
            x: bounded.x + rotationFactor.x * (bounded.width - strokeWidth),
            y: bounded.y + rotationFactor.y * (bounded.height - strokeWidth),
        };
        this._position = getUnrotatedPoint(rotatedPosition);
    }

    _calculateNewDimensions() {
        const bounded = this._getBoundedDimensions();
        const angle = getRotationAngle();
        const strokeWidth = styles.figureStroke / this.zoom;
        const rotationFactor = rotationMatrix[angle];
        const rotatedSize = {
            x: (rotationFactor.flip ? bounded.height : bounded.width) - strokeWidth / 2,
            y: (rotationFactor.flip ? bounded.width : bounded.height) - strokeWidth / 2,
        };
        this._dimensions = {
            ...this._dimensions,
            x: rotatedSize.x,
            y: rotatedSize.y,
        };
    }

    // #endregion

    // #region abstract methods

    createShape() {
        throw new Error("Method 'createShape()' must be implemented.");
    }

    placeShape(position, dimensions) {
        throw new Error("Method 'placeShape()' must be implemented.");
    }

    _continueDraw(pointer) {
        throw new Error("Method '_continueDraw()' must be implemented.");
    }

    findLineEnd(startPoint) {
        throw new Error("Method 'findLineEnd()' must be implemented.");
    }

    // #endregion
};
