import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';

const Indicator = styled.div`
    margin: 0 3px;
    border-radius: 20px;
    text-align: center;
    padding: 4px 8px;
    width: 30px;
    background-color: var(--stage-button-background);
    box-shadow: var(--glass-shadow-lite);
    font-size: 14px;
    color: var(--stage-button-color);
`;

export default observer(function PercentageIndicator({ cubit }) {
    return (
        <Indicator>
            {cubit?.percentage ?? '100%'}
        </Indicator>
    );
});
