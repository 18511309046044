import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../Store/ProofXStore';
import { FlexRow } from '../../styles';

const Wrapper = styled(FlexRow)`
    padding: 12px 4px;
    justify-content: center;    
`;

const Slider = styled.input.attrs(() => ({
    type: 'range',
}))`
    width: 150px;
    margin: 0 8px;
    outline: none;
    -webkit-appearance: none;
    height: 5px;
    border-radius: 5px;  
    background: var(--control-border);
    outline: none; 

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border-radius: 50%; 
        background: var(--stage-button-color);
        cursor: pointer;
    }

    &::-moz-range-thumb {
        width: 15px;
        height: 15px;
        border-radius: 50%; 
        border: none;
        background: var(--stage-button-color);
        cursor: pointer;
      }
`;

export default observer(function FaderSlider() {
    const compareModesManager = useProofXStore((state) => state.compareModesManager);

    if (!compareModesManager?.isFaderMode) return null;
    return (
        <Wrapper>
            <Slider onInput={(e) => compareModesManager.setFaderValue(e)} value={compareModesManager.faderValue} />
        </Wrapper>
    );
});
