import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import ModalBase, { Footer as FooterBase, FooterButton } from './ModalBase';
import { useProofXStore } from '../Store/ProofXStore';
import LanguageSelector from '../uicontrols/LanguageSelector';
import { CaretDown, CaretUp, Link, PaperPlaneRight } from '@phosphor-icons/react';
import HoverClarifier from '../uicontrols/HoverClarifier';
import Button from '../uicontrols/Button';
import FoldingPanel from '../uicontrols/FoldingPanel';
import { FlexRow } from '../../styles';
import { Input, TextArea } from '../uicontrols/Input';
import Checkbox from '../uicontrols/Checkbox';
import DatePicker from '../uicontrols/DatePicker';
import SelectBox from '../uicontrols/SelectBox';
import ChecklistConfigurationForm from './ChecklistConfigurationForm';
import EmailPicker from '../uicontrols/EmailPicker/EmailPicker';

// #region Styles

const Wrapper = styled.div``;

const Header = styled(FlexRow)`
    font-size: 18px;
    color: var(--contrast-text);
    font-weight: 500;
    margin: 0;
    justify-content: space-between;
`;

const GrowColumn = styled.div`
    flex-grow: 1;
    padding-right: 12px;
`;

const Body = styled.div`
    padding: 24px 0px;
    min-width: ${({ isMobile }) => isMobile ? '100%' : '400px'};
    max-width: 600px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ControlRow = styled.div`
    display: flex;
    flex-direction: ${({ $wrap }) => $wrap ? 'column' : 'row'};
    justify-content: space-between;
    margin-bottom: ${({ withMargin }) => withMargin ? '12px' : '0'};
`;

const Form = styled.form.attrs(() => ({
    autoComplete: 'off',
}))`
    display: flex;
    flex-grow: ${({ grow }) => grow ? 1 : 'auto'};
`;

const Control = styled.div`
    margin-bottom: 12px;
    flex-grow: 1;
    color: var(--contrast-text);
    padding: ${({ padding }) => padding ? `${padding}px` : '0'};
`;

const AdvancedOptionsButton = styled(FlexRow)`
    color: var(--secondary-color);
    text-transform: uppercase;

    :hover {
        color: var(--primary-color);
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: [left] 1fr [right] 1fr;
    column-gap: 12px;
    aligh-items: center;
`;

const AdvancedOptions = styled(Grid)`
    margin-bottom: 12px;
    border: 1px solid var(--input-border-color);
    border-radius: 8px;
    background-color: var(--transparent-dimmed);
    box-shadow: inset 2px 2px 10px var(--glass-shadow-color);
    padding: 24px;
`;

const FullWidthRow = styled(FlexRow)`
    grid-column-start: left;
    grid-column-end: end;
`;

const LeftZone = styled.div`
    grid-column: left;
    grid-column-end: ${({ isMobile }) => isMobile ? 'end' : ''};
`;

const RightZone = styled(FlexRow)`
    grid-column-start: ${({ isMobile }) => isMobile ? 'left' : 'right'};
    grid-column-end: end; 
`;

const ChecklistsCaption = styled(FlexRow)`
    color: var(--secondary-color);
    font-size: 11px;
    padding-left: 4px;
    margin-bottom: 2px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const Footer = styled(FooterBase)`
    justify-content: space-between;
`;

export default observer(function ShareModal({ cubit }) {
    const strings = useProofXStore.getState().strings;
    const isLite = useProofXStore((state) => state.proofX?.environment?.project?.isLiteMode ?? true);
    const isMobile = useProofXStore((state) => state.proofX?.isMobile ?? false);
    const canDownloadAssets = useProofXStore((state) => state.proofX?.environment?.flags?.canDownloadAssets ?? false);
    const checklists = cubit?.checklists?.map(c => c.name) ?? [];
    const selectedChecklist = cubit?.checklists.find(c => c.name === cubit.checklist);
    const checklistSelection = cubit?.checklist ? `${cubit.checklist} ${cubit.checklistCustomized ? strings?.customized : ''}` : strings?.selectChecklist;
    const templates = cubit?.templates?.map(c => c.name) ?? [];
    const templateSelection = (cubit?.templateSelection === undefined ? cubit?.templates[0] : cubit?.templateSelection);

    if (!strings) return;
    const simpleModeCheckbox = (
        <Control>
            <Checkbox
                label={strings.simpleMode}
                color='var(--checkbox-color)'
                labelStyle={{ whiteSpace: 'nowrap' }}
                checked={cubit.simpleMode}
                onClick={() => cubit.toggleSimpleMode()}
            />
        </Control>
    );

    return cubit && (
        <ModalBase cubit={cubit}>
            <Wrapper>
                <Header>
                    {/* Modal title */}
                    <GrowColumn>
                        {strings.createExternalReview}
                    </GrowColumn>

                    {/* Language selector */}
                    <HoverClarifier initialOpacity={1}>
                        <LanguageSelector
                            cubit={cubit.languageSelector}
                            language={cubit.language}
                            onChange={(lang) => cubit.setLanguage(lang)}
                        />
                    </HoverClarifier>
                </Header>
                <Body {...{ isMobile }}>
                    <ControlRow withMargin>
                        <Column>
                            <ControlRow>
                                {/* Email */}
                                <Form grow>
                                    <EmailPicker cubit={cubit.emailPicker} />
                                </Form>
                            </ControlRow>
                        </Column>
                    </ControlRow>

                    <FoldingPanel isOpen={cubit.advancedSectionExpanded} hiddenOverflow>
                        <AdvancedOptions>

                            {/* Allow Asset Download */}
                            {canDownloadAssets && (
                                <LeftZone {...{ isMobile }}>
                                    <Control>
                                        <Checkbox
                                            label={strings.allowAssetDownload}
                                            color='var(--checkbox-color)'
                                            labelStyle={{ whiteSpace: 'nowrap' }}
                                            checked={cubit.allowAssetDownload}
                                            onClick={() => cubit.toggleAllowAssetDownload()}
                                        />
                                    </Control>
                                </LeftZone>
                            )}

                            {/* Simple Mode */}
                            {canDownloadAssets
                                ? (
                                    <RightZone {...{ isMobile }}>
                                        {simpleModeCheckbox}
                                    </RightZone>
                                )
                                : (
                                    <LeftZone {...{ isMobile }}>
                                        {simpleModeCheckbox}
                                    </LeftZone>
                                )
                            }

                            {/* Selective approval */}
                            <Control>
                                <Checkbox
                                    label={strings.selectiveApprovalMode}
                                    color='var(--checkbox-color)'
                                    labelStyle={{ whiteSpace: 'nowrap' }}
                                    checked={cubit.selectiveApproval}
                                    onClick={() => cubit.toggleSelectiveApproval()}
                                />
                            </Control>

                            {!isLite && (
                                <FullWidthRow>
                                    <Control>
                                        <Checkbox
                                            label={strings.allowSubscriptions}
                                            color='var(--checkbox-color)'
                                            labelStyle={{ whiteSpace: 'nowrap' }}
                                            checked={cubit.allowSubscriptions}
                                            onClick={() => cubit.toggleAllowSubscriptions()}
                                        />
                                    </Control>
                                </FullWidthRow>
                            )}

                            {/* Subject */}
                            <FullWidthRow>
                                <Control>
                                    <Form>
                                        <Input grow
                                            autoComplete="subject"
                                            placeholder={strings.subject}
                                            value={cubit.subject}
                                            onChange={(e) => cubit.setSubject(e.target.value)}
                                        />
                                    </Form>
                                </Control>
                            </FullWidthRow>

                            {/* Password */}
                            <LeftZone {...{ isMobile }}>
                                <Control>
                                    <Form>
                                        <Input password grow
                                            autoComplete="new-password"
                                            placeholder={strings.password}
                                            value={cubit.password}
                                            onChange={(e) => cubit.setPassword(e.target.value)}
                                        />
                                    </Form>
                                </Control>
                            </LeftZone>

                            {/* Due Date */}
                            <RightZone {...{ isMobile }}>
                                <Control padding={isMobile ? 3 : 0}>
                                    <DatePicker
                                        value={cubit.dueDate}
                                        placeholder={strings.dueDate}
                                        onChange={(date) => cubit.setDueDate(date)}
                                    />
                                </Control>
                            </RightZone>

                            {/* Comment */}
                            <FullWidthRow>
                                <Control>
                                    <Form>
                                        <TextArea grow
                                            autoComplete="comment"
                                            strictWidth={isMobile ? null : 427}
                                            placeholder={strings.comment}
                                            value={cubit.comment}
                                            onChange={(e) => cubit.setComment(e.target.value)}
                                        />
                                    </Form>
                                </Control>
                            </FullWidthRow>

                            {/* Checklist selection */}
                            {cubit.checklists?.length > 0 && (
                                <FullWidthRow>
                                    <Column>
                                        <ChecklistsCaption>{strings.checklist}</ChecklistsCaption>
                                        <ControlRow $wrap={isMobile}>
                                            <FlexRow grow style={{ marginRight: isMobile ? '0' : '12px', marginBottom: isMobile ? '12px' : '0' }}>
                                                <SelectBox grow
                                                    options={[strings.selectChecklist, ...checklists]}
                                                    value={checklistSelection}
                                                    onChange={(value) => cubit.setChecklist(value === strings.selectChecklist ? null : value)}
                                                />
                                            </FlexRow>

                                            {/* Configure Checklist */}
                                            <ChecklistConfigurationForm
                                                cubit={cubit}
                                                checklist={selectedChecklist}
                                                selection={cubit.checklistSelection}
                                                onSave={(items) => cubit.setChecklistSelection(items)}
                                            />
                                        </ControlRow>
                                    </Column>
                                </FullWidthRow>
                            )}

                            {/* Templates selection */}
                            {cubit.templates?.length > 1 && (
                                <FullWidthRow>
                                    <Column>
                                        <ChecklistsCaption>{strings.template}</ChecklistsCaption>
                                        <ControlRow $wrap={isMobile}>
                                            <FlexRow grow>
                                                <SelectBox grow
                                                    options={templates}
                                                    value={templateSelection.name}
                                                    onChange={(value) => cubit.setTemplate(value)}
                                                />
                                            </FlexRow>
                                        </ControlRow>
                                    </Column>
                                </FullWidthRow>
                            )}
                        </AdvancedOptions>
                    </FoldingPanel>

                    <ControlRow>
                        {/* Advanced options toggle */}
                        <HoverClarifier initialOpacity={1}>
                            <AdvancedOptionsButton link onClick={() => cubit.toggleAdvancedSection()}>
                                {cubit.advancedSectionExpanded
                                    ? <><CaretUp size={16} style={{ marginRight: 4 }} />{strings.hideAdvancedOptions}</>
                                    : <><CaretDown size={16} style={{ marginRight: 4 }} />{strings.advancedOptions}</>}
                            </AdvancedOptionsButton>
                        </HoverClarifier>

                        {/* Send button */}
                        <Button primary
                            disabled={!cubit.readyToSend}
                            onClick={() => cubit.send()}
                        >
                            {strings.send}<PaperPlaneRight size={16} style={{ marginLeft: 4 }} />
                        </Button>
                    </ControlRow>

                </Body>
                <Footer>
                    <FooterButton onClick={() => cubit.copyShortlink()}>
                        <Link size={16} style={{ marginRight: 4 }} />{strings.getShortlink}
                    </FooterButton>
                    <FooterButton link noPadding onClick={() => cubit.hide()}>
                        {strings.close}
                    </FooterButton>
                </Footer>
            </Wrapper>
        </ModalBase>
    );
});
