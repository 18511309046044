import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useProofXStore } from '../Store/ProofXStore';
import { useLayer } from 'react-laag-v18';
import ToggleButton from '../uicontrols/ToggleButton';
import { Bell } from '@phosphor-icons/react';
import { smallIconSize } from '../../styles';
import { motion } from 'framer-motion';
import Checkbox from '../uicontrols/Checkbox';

const Wrapper = styled.div`
    position: relative;
`;

const Popup = styled(motion.div)`
    background-color: var(--popover-background);
    border-radius: 8px;
    box-shadow: var(--glass-shadow);
    overflow: hidden;
    padding: 24px;
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    margin-bottom: 12px;
`;

const CheckboxRow = styled.div`
    margin-bottom: 8px;
`;

const variants = {
    opened: { opacity: 1 },
    closed: { opacity: 0 },
};

export default observer(function AssetSubscriptionPopup() {
    const strings = useProofXStore.getState().strings;
    const proofX = useProofXStore.getState().proofX;
    const [submitting, setSubmitting] = useState(false);

    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen: proofX.assetSubscriptionPopupOpen,
        onOutsideClick: () => proofX.toggleAssetSubscriptionPopup(false), // close the menu when the user clicks outside
        onDisappear: () => proofX.toggleAssetSubscriptionPopup(false), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => proofX.toggleAssetSubscriptionPopup(false),
        overflowContainer: false, // keep the menu positioned outside the container
        auto: true, // automatically find the best placement
        placement: proofX.placement ?? 'bottom-end',
        possiblePlacements: ['bottom-end'],
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 8, // give the menu some room to breath relative to the container
    });

    const toggleCommentUpdates = () => {
        const subscr = proofX.assetSubscription ?? { commentUpdates: false, fileVersionsUpdates: false };
        setSubmitting(true);
        proofX.updateAssetSubscription(!subscr.commentUpdates, subscr.fileVersionsUpdates);
        setSubmitting(false);
    };

    const toggleFileVersionsUpdates = () => {
        const subscr = proofX.assetSubscription ?? { commentUpdates: false, fileVersionsUpdates: false };
        setSubmitting(true);
        proofX.updateAssetSubscription(subscr.commentUpdates, !subscr.fileVersionsUpdates);
        setSubmitting(false);
    };

    return (<Wrapper>
        <div {...triggerProps} className='trigger'>
            <ToggleButton
                isOn={false}
                Icon={<Bell size={smallIconSize} weight='fill' />}
                onClick={() => proofX.toggleAssetSubscriptionPopup()}
            />
        </div>
        {proofX?.assetSubscriptionPopupOpen && renderLayer(
            <Popup {...layerProps}
                variants={variants}
                initial='closed'
                animate={proofX?.assetSubscriptionPopupOpen ? 'opened' : 'closed'}
                exit='closed'
                transition={{
                    duration: 0.3,
                }}
            >
                <Header>{strings.getNotifications}</Header>
                <CheckboxRow>
                    <Checkbox label={strings.commentUpdates}
                        disabled={submitting}
                        color='var(--checkbox-color)'
                        checked={proofX.assetSubscription?.commentUpdates ?? false}
                        onClick={() => toggleCommentUpdates()}
                    />
                </CheckboxRow>
                <CheckboxRow>
                    <Checkbox label={strings.fileVersionsUpdates}
                        disabled={submitting}
                        color='var(--checkbox-color)'
                        checked={proofX.assetSubscription?.fileVersionsUpdates ?? false}
                        onClick={() => toggleFileVersionsUpdates()}
                    />
                </CheckboxRow>
            </Popup>,
        )}
    </Wrapper>
    );
});
