import { observable, computed, action, makeObservable } from 'mobx';
// import { useProofXStore } from '../Store/ProofXStore';

export default class ModalCubit {
    _isOpen = false;
    _title = '';
    _containerElement = null;
    _closeOnBackdropClick = true;

    constructor(containerElement) {
        makeObservable(this, {
            _isOpen: observable,
            _title: observable,
            _closeOnBackdropClick: observable,

            isOpen: computed,
            closeOnBackdropClick: computed,

            show: action,
            hide: action,
        });
        this._containerElement = containerElement ?? document.getElementById('proofx-container');
    };

    get isOpen() { return this._isOpen; }
    get title() { return this._title; }
    get closeOnBackdropClick() { return this._closeOnBackdropClick; }

    show() {
        // useProofXStore.getState().proofX.blurContent();
        this._isOpen = true;
    }

    hide() {
        // useProofXStore.getState().proofX.unblurContent();
        this._isOpen = false;
    }
}
