import {
    Barcode, BookOpenText, CaretRight, ChatCircleText, ChatsCircle, Eyedropper,
    Info, Robot, Ruler, Toolbox,
} from '@phosphor-icons/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../Store/ProofXStore';
import StageToolButton from '../uicontrols/StageToolButton';
import { FlexColumn } from '../../styles';
import Collapsable from '../uicontrols/Collapsable';
import { ViewerModes } from './ViewerCubit';
import BackgroundSelector from '../uicontrols/BackgroundSelector';
import sendGTMEvent from '../GTM';

// #region Styles

const Wrapper = styled(FlexColumn)`
    justify-content: center;
    padding: var(--sm);
`;

// #endregion

export default observer(function ViewerRightToolbar() {
    const strings = useProofXStore((state) => state.strings);
    const proofX = useProofXStore((state) => state.proofX);
    const viewer = useProofXStore((state) => state.viewer);
    const splitView = useProofXStore((state) => state.splitView);
    const asset = useProofXStore((state) => state.assets?.main);
    const isSimpleMode = useProofXStore((state) => state.proofX?.environment?.task?.simpleMode ?? false);
    const isLite = useProofXStore((state) => state.proofX?.environment?.project?.isLiteMode ?? true);

    const toggleRightDrawer = () => {
        if (splitView.rightDrawerOpened) {
            splitView.closeRightDrawer();
        } else {
            splitView.openRightDrawer();
        }
    };

    const activateMode = (mode) => {
        if (proofX.isMobile) {
            splitView.openRightDrawer();
        }
        viewer.setMode(mode);
    };

    if (!viewer) return null;
    const isExternalReview = proofX.environment.flags.isExternalReview;
    const panelClosedInMobile = proofX.isMobile && !splitView.rightDrawerOpened;
    return (
        <Wrapper>
            {(!proofX?.isMobile || splitView.rightDrawerOpened) && (
                <StageToolButton right big
                    Icon={splitView.rightDrawerOpened ? CaretRight : isSimpleMode ? ChatsCircle : Toolbox}
                    title={splitView.rightDrawerOpened ? strings.hidePanel : isSimpleMode ? strings.showAssetDiscussion : strings.showToolsPanel}
                    onPress={toggleRightDrawer}
                />
            )}
            {/* ASSET DISCUSSION (mobile) */}
            {proofX?.isMobile && !splitView.rightDrawerOpened && (
                <StageToolButton right big filled
                    Icon={ChatsCircle} title={strings.assetDiscussion}
                    isOn={!panelClosedInMobile && viewer.currentMode === ViewerModes.annotations}
                    onPress={() => {
                        sendGTMEvent('Annotatate-Annotation-Clicked-on-Annotate');
                        activateMode(ViewerModes.annotations);
                    }}
                />
            )}

            {!isSimpleMode && (splitView.rightDrawerOpened || proofX?.isMobile) && (
                <FlexColumn>
                    <Collapsable column isOpen={viewer.currentMode !== ViewerModes.compare}>
                        {/* FILE INFO */}
                        <StageToolButton right big
                            Icon={Info} title={strings.fileInfo}
                            isOn={!panelClosedInMobile && viewer.currentMode === ViewerModes.fileInfo}
                            onPress={() => activateMode(ViewerModes.fileInfo)}
                        />

                        {/* PROJECT DISCUSSION */}
                        {!isLite && !isExternalReview && (
                            <StageToolButton right big id="project-discussion-button"
                                Icon={ChatCircleText} title={strings.projectDiscussion}
                                isOn={!panelClosedInMobile && viewer.currentMode === ViewerModes.projectDiscussion}
                                onPress={() => activateMode(ViewerModes.projectDiscussion)}
                            />
                        )}

                        {/* TOOLBOX */}
                        {!proofX?.isTaskCompleted && !proofX.isMobile && (<FlexColumn id="extra-tools">
                            {/* RULER */}
                            {proofX?.environment?.flags.rulerEnabled && (
                                <StageToolButton right big
                                    Icon={Ruler} title={strings.ruler}
                                    isOn={viewer.currentMode === ViewerModes.ruler}
                                    onPress={() => viewer.setMode(ViewerModes.ruler)}
                                />
                            )}
                            {/* EYEDROPPER */}
                            {proofX?.environment?.flags.eyeDropperEnabled && (
                                <StageToolButton right big
                                    Icon={Eyedropper} title={strings.eyedropper}
                                    isOn={viewer.currentMode === ViewerModes.eyedropper}
                                    onPress={() => viewer.setMode(ViewerModes.eyedropper)}
                                />
                            )}
                            {/* BARCODE_SCANNER */}
                            {proofX?.environment?.flags.barcodeReaderEnabled && (
                                <StageToolButton right big
                                    Icon={Barcode} title={strings.barcodeScanner}
                                    isOn={viewer.currentMode === ViewerModes.barcodeScanner}
                                    onPress={() => viewer.setMode(ViewerModes.barcodeScanner)}
                                />
                            )}
                            {/* SPELLCHECKER  */}
                            {proofX?.environment?.flags.spellChecker && asset?.spellcheckerType && (
                                <StageToolButton right big
                                    Icon={BookOpenText} title={strings.spellchecker}
                                    isOn={false}
                                    onPress={() => proofX.showSpellcheckerModal()}
                                />
                            )}
                            {/* AI ASSISTANT  */}
                            {proofX?.environment?.flags.aiAssistantEnabled && (
                                <StageToolButton right big
                                    Icon={Robot} title={strings.talkToAi}
                                    isOn={false}
                                    onPress={() => viewer.setMode(ViewerModes.aiAssistant)}
                                />
                            )}
                        </FlexColumn>)}

                        <BackgroundSelector />
                    </Collapsable>
                </FlexColumn>
            )}
        </Wrapper>
    );
});
