import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../../Store/ProofXStore';
import { FlexRow } from '../../../styles';

const Wrapper = styled(FlexRow)`
    padding: var(--sm);
    border-radius: 8px;
    justify-content: center;
    background-color: var(--glass-background);
    box-shadow: var(--glass-shadow-lite);
    backdrop-filter: var(--glass-blur);
    font-size: var(--font-size-md);
`;

const Prompt = styled.div`
    margin-right: 8px;
    font-family: var(--medium);
`;

const Value = styled.div`
    user-select: text;
`;

export default observer(function RulerTool() {
    const strings = useProofXStore((state) => state.strings);
    const viewer = useProofXStore((state) => state.viewer);

    return (
        <Wrapper>
            {viewer?.ruler?.value
                ? (<>
                    <Prompt>{strings.distance}:</Prompt>
                    <Value>{viewer.ruler.value}</Value>
                </>)
                : (<Prompt>{strings.setRulerStartingPoint}</Prompt>
                )}
        </Wrapper>
    );
});
