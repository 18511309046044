import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';

import { FlexRow, GlobalStyle, Themes } from '../../styles';
import { useProofXStore } from '../Store/ProofXStore';
import WrongLinkPage from '../WrongLinkPage/WrongLink';
import AppBar from '../AppBar/AppBar';
import SplitView from '../SplitView/SplitView';
import ConfirmModal from '../Modals/ConfirmModal';
import AttachmentViewerModal from '../Modals/AttachmentViewerModal';
import Spinner from '../uicontrols/Spinner';
import AlertsStack from '../AlertsStack/AlertsStack';
import ApprovalModal from '../Modals/ApprovalModal';
import ShareModal from '../Modals/ShareModal';
import ExternalUserIntroModal from '../Modals/ExternalUserIntroModal';
import SpellcheckerModal from '../Modals/SpellcheckerModal';
import SuccessMessageModal from '../Modals/SuccessMessageModal';
import AddressBookRecordModal from '../Modals/AddressBookRecordModal';
import { TourProvider } from '@reactour/tour';
import AddressBookModal from '../Modals/AddressBookModal';

// #region Styles

const Wrapper = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
`;

const WaitDimmer = styled(FlexRow)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    border-radius: 10px;
    background-color: var(--waiting-block-background);
    justify-content: center;
    filter: drop-shadow(2px 2px 10px var(--secondary-color));
`;

const WaitingMessageContainer = styled(FlexRow)`
    border-radius: 8px;        
    padding: 24px;
    font-size: 14px;
    background-color: var(--glass-background);
    box-shadow: var(--glass-shadow-popup);
`;

const WaitingMessage = styled.div`
    margin-left: 12px;
`;

// #endregion

export default observer(function ProofX() {
    const proofX = useProofXStore((state) => state.proofX);
    const tourCubit = useProofXStore((state) => state.tourCubit);

    if (!proofX) return;

    const theme = proofX.theme;
    const ThemeProvider = GlobalStyle;

    return (<>
        <ThemeProvider theme={Themes[theme]} />
        {!proofX.isWrongLink
            ? (!proofX.isAuthenticating
                ? (
                    <TourProvider {...tourCubit?.props}>
                        <Wrapper id='proofx-container'>
                            <AppBar key={proofX.theme} />
                            <SplitView />
                            {proofX.isLoading && (
                                <WaitDimmer>
                                    {proofX.waitingMessage && (
                                        <WaitingMessageContainer><Spinner /><WaitingMessage>{proofX.waitingMessage}</WaitingMessage></WaitingMessageContainer>
                                    )}
                                </WaitDimmer>
                            )}
                        </Wrapper>
                        <AlertsStack />
                        <ExternalUserIntroModal cubit={proofX.externalUserIntroModal} />
                        <ConfirmModal cubit={proofX.confirmModal} />
                        <ApprovalModal cubit={proofX.approvalModal} />
                        <SuccessMessageModal cubit={proofX.successMessageModal} />
                        <ShareModal cubit={proofX.shareModal} />
                        <AttachmentViewerModal cubit={proofX.attachmentViewerModal} />
                        <SpellcheckerModal cubit={proofX.spellcheckerModal} />
                        <AddressBookModal cubit={proofX.addressBookModal} />
                        <AddressBookRecordModal cubit={proofX.addressBookRecordModal} />
                    </TourProvider>
                )
                : <Wrapper />)
            : <WrongLinkPage />}
    </>);
});
