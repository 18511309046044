import { observer } from 'mobx-react-lite';
import React from 'react';
import SimpleBar from 'simplebar-react';
import styled from 'styled-components';
import { FlexRow } from '../../../styles';
import { useProofXStore } from '../../Store/ProofXStore';
import Spinner from '../../uicontrols/Spinner';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;    
    height: calc(100vh - var(--appbar-height) - var(--right-panel-header-height)); 
    max-width: 266px;
    min-width: 266px;
    overflow: auto;
    position: relative;
    padding: 8px 0;
`;

const ScrollWrapper = styled.div`
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    padding: 0 8px;
`;

const ResultLine = styled(FlexRow)`
    font-size: 12px;
    margin-top: 8px;
    align-items: start;
`;

const ResultKey = styled.div`  
    display: flex;
    font-family: var(--medium);
    margin-right: 6px;
`;

const Error = styled(FlexRow)`
    color: var(--error-color);
    font-size: 12px;
    margin-top: 8px;
`;

const SnapshotDiv = styled.div`
    visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0px;
    border: 1px solid var(--input-border-color);
    `;

const Snapshot = styled.canvas.attrs(() => ({
    id: 'barcode-scanner-snapshot',
}))`
    max-height: 400px;
    max-width: 250px;
`;

export default observer(function BarcodeScannerPanel() {
    const strings = useProofXStore((state) => state.strings);
    const cubit = useProofXStore((state) => state.viewer?.barcodeScanner);

    return (
        <Wrapper>
            <ScrollWrapper>
                <SimpleBar style={{ width: 250, maxHeight: '100%', display: 'flex' }}>
                    <SnapshotDiv $show={cubit?.hasSnapshot}>
                        <Snapshot />
                    </SnapshotDiv>
                    {cubit.scanning && (
                        <ResultLine><ResultKey><Spinner size={18} /></ResultKey>{strings.readingBarcode}</ResultLine>
                    )}
                    {cubit.scanResult && (<>
                        <ResultLine>
                            <ResultKey>{strings.barcodeType}</ResultKey>
                            {cubit.scanResult.barcodeFormat}
                        </ResultLine>
                        <ResultLine>
                            <ResultKey>{strings.text}</ResultKey>
                            {cubit.scanResult.text}
                        </ResultLine>
                    </>)}
                    {cubit.error && (
                        <Error>
                            {strings[cubit.error]}
                        </Error>
                    )}
                </SimpleBar>
            </ScrollWrapper>
        </Wrapper>
    );
});
