import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useLayer } from 'react-laag-v18';
import styled from 'styled-components';

const Flyout = styled(motion.div)`
    position: relative;
    border-radius: 8px;        
    padding: 6px;
    display: flex;
    font-size: 11px;
    background-color: var(--tooltip-background);
    box-shadow: var(--glass-shadow-popup);
    transform-origin: center center;
    z-index: 200000;
`;

export default function Tooltip({ text, placement, disabled, children, style, flex }) {
    const [isOpen, setOpen] = useState(false);
    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen,
        onOutsideClick: () => { }, // close the menu when the user clicks outside
        onDisappear: () => setOpen(false), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => setOpen(false), // close the menu when the parent closes (e.g. a modal)
        overflowContainer: true, // make the tooltip flow above the container
        auto: true, // automatically find the best placement
        placement: placement || 'bottom-center', // we prefer to place the menu "top-end"
        possiblePlacements: ['bottom-center', 'top-center', 'center', 'right-center'],
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 4, // give the menu some room to breath relative to the container
    });
    return text && (<>
        <div {...triggerProps}
            style={{ ...style, display: flex ? 'flex' : 'block' }}
            onPointerEnter={() => !disabled && setOpen(true)}
            onPointerLeave={() => !disabled && setOpen(false)}
            onClick={() => !disabled && setOpen(false)}>
            {children}
        </div>
        {renderLayer(
            <div {...layerProps}>
                <AnimatePresence>
                    {isOpen && (
                        <Flyout
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{
                                delay: 0.2,
                                duration: 0.25,
                                ease: 'easeInOut',
                            }}
                        >{text}</Flyout>
                    )}
                </AnimatePresence>
            </div>,
        )}
    </>);
};
