import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../Store/ProofXStore';
import { useLayer } from 'react-laag-v18';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';
import { FlexRow } from '../../styles';

// #region Styles

const Wrapper = styled.div`
    position: relative;
`;

const Label = styled.div`
    background-image: url(/img/flag_${({ language }) => language}.svg);
    border-radius: 50%;
    box-shadow: var(--glass-shadow);
    margin: 0 1px 1px 0;
    width: ${({ size }) => size ?? 24}px;
    height: ${({ size }) => size ?? 24}px;
    background-size: contain;
    opacity: ${({ opacity }) => opacity ?? 1};
    cursor: pointer;
    :hover {
        opacity: 1;        
    } 
    :active {
        box-shadow: none;
        margin: 1px 0 0 1px;
    }
`;

const Menu = styled(motion.div)`
    background-color: var(--popover-background);
    border-radius: 8px;
    box-shadow: var(--glass-shadow);
    overflow: hidden;
    transform-origin: center top;
`;

const Item = styled(FlexRow)`
    padding: 6px 12px;
    font-size: 12px;
    cursor: pointer;
    
    :hover {
        background-color: var(--highlighted-background);
    }
`;

const Flag = styled.div`
    background-image: url(/img/flag_${props => props.language}.svg);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-right: 6px; 
    background-size: contain;
`;

const ItemLabel = styled.span`
`;

// #endregion

const languages = {
    en: 'english',
    de: 'german',
    es: 'spanish',
    fr: 'french',
    pl: 'polish',
    ua: 'ukrainian',
};

const variants = {
    opened: { opacity: 1, scale: 1 },
    closed: { opacity: 0, scale: 0 },
};

export default observer(function LanguageSelector({ cubit, language, size, opacity, onChange }) {
    const strings = useProofXStore.getState().strings;
    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen: cubit.isOpen,
        onOutsideClick: () => cubit.close(), // close the menu when the user clicks outside
        onDisappear: () => cubit.close(), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => cubit.close(),
        overflowContainer: false, // keep the menu positioned outside the container
        auto: true, // automatically find the best placement
        placement: cubit.placement ?? 'bottom-center',
        possiblePlacements: ['bottom-center'],
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 8, // give the menu some room to breath relative to the container
    });

    return (<Wrapper>
        <div {...triggerProps} className='trigger' onClick={() => cubit.open()}>
            <Label language={language} size={size} opacity={opacity}></Label>
        </div>
        {cubit?.isOpen && renderLayer(
            <Menu {...layerProps}
                variants={variants}
                initial='closed'
                animate={cubit?.isOpen ? 'opened' : 'closed'}
                exit='closed'
                transition={{
                    duration: 0.3,
                    type: 'spring',
                    bounce: 0.35,
                }}
                onClick={() => cubit.close()}
            >
                {Object.keys(languages).map(ln => (
                    <Item key={ln} onClick={() => onChange(ln)}>
                        <Flag language={ln} /><ItemLabel>{strings[languages[ln]]}</ItemLabel>
                    </Item>
                ))}
            </Menu>,
        )}
    </Wrapper>
    );
});
