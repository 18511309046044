import { observable, computed, action, makeObservable } from 'mobx';
import { useProofXStore } from '../../Store/ProofXStore';
import CommentCubit from './CommentCubit';
import { getRandomColor } from '../../Viewer/AnnotationLayer/Utils';

export default class DiscussionCubit {
    _latestAsset = null;
    _commentCubits = [];
    _commentTree = [];
    _scrollDownAfterRebuild = false;
    _shouldScrollDown = false;

    constructor() {
        makeObservable(this, {
            _latestAsset: observable.ref,
            _commentTree: observable,
            _scrollDownAfterRebuild: observable,
            _shouldScrollDown: observable,

            _rawComments: computed,
            commentsTree: computed,
            hasExpandableReplies: computed,

            updateComments: action,
            toggleCollapseAll: action,
            setScrollDownAfterRebuild: action,
            setShouldScrollDown: action,
        });
    };

    get _rawComments() { return this.store.discussionPanel.isAssetComments ? this.store.assetComments : this.store.projectComments; }
    get commentsTree() { return this._commentTree; }
    get isAssetComments() { return this.store.discussionPanel.isAssetComments; }
    get shouldScrollDown() { return this._shouldScrollDown; }
    get store() { return useProofXStore.getState(); }

    get hasExpandedReplies() {
        return !!this.commentsTree?.find(cubit => cubit.replies.length > 0 && cubit.showReplies);
    }

    get hasExpandableReplies() {
        return !!this.commentsTree?.find(cubit => cubit.replies.length > 0);
    }

    updateComments() {
        this._rebuildCommentsTree();
    }

    toggleCollapseAll() {
        const shouldExpand = !this.hasExpandedReplies;
        this._commentTree.forEach(c => c.toggleCollapseReplies(shouldExpand));
    }

    hideNewReply() {
        this._commentTree.forEach(c => c.closeNewReply());
    }

    highlightComment(uid) {
        this._commentTree.forEach(c => c.dropHighlight());
        if (uid !== null) {
            this._commentTree.find(c => c.id === uid)?.highlight();
        }
    }

    setScrollDownAfterRebuild(isOn) {
        this._scrollDownAfterRebuild = isOn;
    }

    setShouldScrollDown(isOn) {
        this._shouldScrollDown = isOn;
    }

    cancelEditAll() {
        this._commentCubits.forEach(c => c.cancelEdit());
    }

    deleteComment(uid) {
        const strings = this.store.strings;
        this.store.proofX.showConfirm(
            strings.confirmDeletingComment,
            strings.deletingCommentPrompt,
            strings.delete,
            () => this._deleteComment(uid),
        );
    }

    _cleanAbsentComments() {
        const existingUids = this._rawComments.map(c => c.commentUid);
        this._commentCubits = this._commentCubits.filter(cubit => existingUids.includes(cubit.comment.commentUid));
    }

    _createOrUpdateCubits() {
        this._rawComments.forEach(comment => {
            const cubit = this._commentCubits.find(cubit => cubit.comment.commentUid === comment.commentUid);
            if (!cubit) {
                this._commentCubits.push(new CommentCubit(comment));
            } else {
                cubit.setComment(comment);
            }
        });
    }

    _rebuildCommentsTree() {
        this._cleanAbsentComments();
        this._createOrUpdateCubits();
        this._commentTree = this._buildCommentsTree(null, this._rawComments);
        if (this._scrollDownAfterRebuild) {
            window.setTimeout(() => {
                this.setScrollDownAfterRebuild(false);
                this.setShouldScrollDown(true);
            }, 500);
        }
    }

    // recursive!
    _buildCommentsTree(parent, allComments) {
        const me = this;
        const rootComments = allComments?.filter(c => (c.parentCommentUid ?? null) === (parent?.comment?.commentUid ?? null)) ?? [];
        return rootComments.map(comment => {
            const cubit = this._commentCubits.find(cubit => cubit.comment.commentUid === comment.commentUid);
            cubit.setParent(parent ?? null);
            cubit.setLevel(parent?.level + 1 ?? 0);
            if (parent?.color && parent.color !== 'transparent') {
                cubit.setColor(parent.color);
            } else if (comment?.json) {
                const metadata = JSON.parse(comment.json);
                cubit.setColor(metadata.color.mainColor);
            } else {
                cubit.setColor(getRandomColor(comment.userUid));
            }
            cubit.setReplies(me._buildCommentsTree(cubit, allComments));
            return cubit;
        });
    }

    _deleteComment(uid) {
        this.store.proofX.deleteComment(uid, this.isAssetComments);
        this._rebuildCommentsTree();
    }
}
