import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import ModalBase, { Footer as ModalFooter, FooterButton } from './ModalBase';
import { useProofXStore } from '../Store/ProofXStore';
import Button from '../uicontrols/Button';
import SimpleBar from 'simplebar-react';
import Tooltip from '../uicontrols/Tooltip';
import { Pencil, Plus } from '@phosphor-icons/react';
import Checkbox from '../uicontrols/Checkbox';

// #region Styles

const Wrapper = styled.div``;
const Title = styled.div`
    font-size: 18px;
    color: var(--contrast-text);
    font-weight: 500;
    margin: 0;
`;
const Body = styled.div`
    padding: 24px 0px;
    min-width: 450px;
    max-width: 600px;
    font-size: 13px;
`;
const Prompt = styled.div`
    margin-bottom: 12px;  
`;
const TabBar = styled.div`
    margin-top: 12px;
    display: flex;
    border-bottom: 1px solid var(--input-border-color);
`;
const TabButton = styled.div`
    font-weight: ${({ active }) => active ? 'bold' : ''};
    color: ${({ active }) => active ? 'var(--primary-color)' : ''};
    padding: 6px 12px;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;

    :hover {
        color: var(--contrast-text);
    }

    &:after {
        border-bottom: ${({ active }) => active ? '2px solid var(--primary-color)' : ''};
        content: "";
        position: absolute;
        width: 100%;
        bottom: -2px;
        left: 0;
    }
`;
const TabBody = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 400px;
    margin-top: 12px;
`;
const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    margin-bottom: 12px;
`;
const ScrollWrapper = styled.div`
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    padding: 0 8px;
`;
const ContactsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const Contact = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
    white-space: nowrap;
`;
const ContactText = styled.div`
    max-width: 380px;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const EditRecordButton = styled(Pencil).attrs({ size: 18, weight: 'fill', color: 'var(--secondary-color)' })`
    cursor: pointer;
    min-width: 18px;
    margin-left: 4px;
`;
const Footer = styled(ModalFooter)`
    display: flex;
    flex-direction: row;   
    align-items: center;
    justify-content: flex-end;    
`;

// #endregion

export default observer(function AddressBookModal({ cubit }) {
    const strings = useProofXStore.getState().strings;

    return cubit && (
        <ModalBase cubit={cubit}>
            <Wrapper>
                <Title>{strings.addressBook}</Title>
                <Body>
                    <Prompt>{cubit.groups.length > 0 ? strings.selectContactsAndGroups : strings.selectContacts}</Prompt>
                    {cubit.groups.length > 0 && (
                        <TabBar>
                            <TabButton active={cubit.tab === 'contacts'} onClick={() => cubit.switchTab('contacts')}>{strings.contacts}</TabButton>
                            <TabButton active={cubit.tab === 'groups'} onClick={() => cubit.switchTab('groups')}>{strings.groups}</TabButton>
                        </TabBar>
                    )}
                    <TabBody>
                        {cubit.tab === 'contacts' && (<>
                            <ButtonRow>
                                <Button secondary onClick={() => cubit.createContact()}><Plus />{strings.newConact}</Button>
                            </ButtonRow>
                            <ScrollWrapper>
                                <SimpleBar style={{ maxHeight: '300px', display: 'flex' }}>
                                    <ContactsWrapper>
                                        {cubit.contacts.map(e => (
                                            <Contact key={e.email}>
                                                <Checkbox style={{ flexGrow: 1 }}
                                                    label={<ContactText>{e.name ? (<><strong>{e.name}</strong> ({e.email})</>) : e.email}</ContactText>}
                                                    checked={cubit.selectedEmails.find(es => es === e.email)}
                                                    onClick={() => cubit.toggleEmailSelected(e.email)}
                                                />
                                                <Tooltip flex text={strings.editAddressBookRecord}>
                                                    <EditRecordButton onClick={() => cubit.editContact(e)} />
                                                </Tooltip>
                                            </Contact>
                                        ))}
                                    </ContactsWrapper>
                                </SimpleBar>
                            </ScrollWrapper>
                        </>)}
                        {cubit.tab === 'groups' && (<>
                            <ScrollWrapper>
                                <SimpleBar style={{ width: 250, maxHeight: '100%', display: 'flex' }}>
                                    <ContactsWrapper>
                                        {cubit.groups.map(gr => (
                                            <Contact key={gr.groupId}>
                                                <Checkbox label={<ContactText>{gr.name}</ContactText>} style={{ flexGrow: 1 }}
                                                    checked={cubit.selectedGroupIds.find(id => id === gr.groupId)}
                                                    onClick={() => cubit.toggleGroupSelected(gr.groupId)}
                                                />
                                            </Contact>
                                        ))}
                                    </ContactsWrapper>
                                </SimpleBar>
                            </ScrollWrapper>
                        </>)}
                    </TabBody>
                </Body>
                <Footer>
                    <FooterButton link onClick={() => cubit.hide()} style={{ marginRight: 8 }}>
                        {strings.cancel}
                    </FooterButton>
                    <FooterButton primary onClick={() => cubit.apply()}>
                        {strings.apply}
                    </FooterButton>
                </Footer>
            </Wrapper>
        </ModalBase>
    );
});
