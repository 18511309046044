import { useProofXStore } from '../Store/ProofXStore';

// abstract class to manage specific compare modes by inherited classes
export default class CompareModeCubit {
    get store() { return useProofXStore.getState(); }

    // #region abstract methods

    assignDependentStages() {
        throw new Error("Method 'assignDependentStages()' must be implemented.");
    }

    async loadCompareResult() {
        throw new Error("Method 'loadCompareResult()' must be implemented.");
    }

    // #endregion
}
