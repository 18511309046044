import { observable, makeObservable, override, action, computed } from 'mobx';
import ModalCubit from './ModalCubit';

export default class AttachmentViewerModalCubit extends ModalCubit {
    _commentCubit = null;
    _imageLoaded = false;

    constructor() {
        super();
        makeObservable(this, {
            _commentCubit: observable.ref,
            _imageLoaded: observable,

            imageLoaded: computed,

            setImageLoaded: action,

            show: override,
        });
    }

    get title() { return this._commentCubit?.comment?.name; }
    get attachmentUid() { return this._commentCubit?.attachment?.uid; }
    get imageLoaded() { return this._imageLoaded; }

    download() {
        this._commentCubit?.downloadAttachment();
    }

    show(commentCubit) {
        this._commentCubit = commentCubit;
        this._imageLoaded = false;
        super.show();
    }

    setImageLoaded(isTrue) {
        this._imageLoaded = isTrue;
    }
}
