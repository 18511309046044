/**
    *
    *  Javascript color conversion:
    *  https://gist.github.com/felipesabino/5066336
    *  https://gist.github.com/mjackson/5311256
    *  https://github.com/picturae/rgb-lab-conversion
    *  https://github.com/Qix-/color-convert
    *  https://www.w3schools.com/colors/colors_converter.asp
    *  https://codebeautify.org/dist/5.2/js/pantonJS.js
    *
    **/

import { PMS2RGBmap } from './PantoneRGBMap';

const DEGREE_CHAR = String.fromCharCode(176);

function toHex(n) {
    let h = '';
    if (n !== undefined) {
        h = n.toString(16);
        while (h.length < 2) { h = '0' + h; }
    }
    return h.toUpperCase();
}

export const colorSchemes = {
    HSV: (h, s, v) => {
        const hValue = Math.min(Math.max(h, 0), 360) % 360;
        const sValue = Math.min(Math.max(s, 0), 100);
        const vValue = Math.min(Math.max(v, 0), 100);

        return {
            val: {
                h: hValue,
                s: sValue,
                v: vValue,
            },
            str: `${hValue}${DEGREE_CHAR}, ${sValue}%, ${vValue}%`,
        };
    },

    HSL: (h, s, l) => {
        const hValue = Math.min(Math.max(h, 0), 360);
        const sValue = Math.min(Math.max(s, 0), 100);
        const lValue = Math.min(Math.max(l, 0), 100);

        return {
            val: {
                h: hValue,
                s: sValue,
                l: lValue,
            },
            str: `${hValue}${DEGREE_CHAR}, ${sValue}%, ${lValue}%`,
        };
    },

    RGB: (r, g, b) => {
        const rValue = Math.min(Math.max(r, 0), 255);
        const gValue = Math.min(Math.max(g, 0), 255);
        const bValue = Math.min(Math.max(b, 0), 255);

        return {
            val: {
                r: rValue,
                g: gValue,
                b: bValue,
            },
            str: `${rValue}, ${gValue}, ${bValue}`,
        };
    },

    HEX: (r, g, b) => {
        const rgb = colorSchemes.RGB(r, g, b);
        const hex = `${toHex(rgb.val.r)}${toHex(rgb.val.g)}${toHex(rgb.val.b)}`;
        return {
            val: { hex },
            str: `#${hex}`,
        };
    },

    CMYK: (c, m, y, k) => {
        const cValue = Math.min(Math.max(c, 0), 100);
        const mValue = Math.min(Math.max(m, 0), 100);
        const yValue = Math.min(Math.max(y, 0), 100);
        const kValue = Math.min(Math.max(k, 0), 100);

        return {
            val: {
                c: cValue,
                m: mValue,
                y: yValue,
                k: kValue,
            },
            str: `${cValue}%, ${mValue}%, ${yValue}%, ${kValue}%`,
        };
    },

    PANTONE: (p) => {
        return {
            val: { pantone: p },
            str: p,
        };
    },

    LAB: (l, a, b) => {
        return {
            val: { l, a, b },
            str: `${l}, ${a}, ${b}`,
        };
    },
};

export function rgbToHEX(rgbValue) {
    const ret = colorSchemes.HEX(rgbValue.r, rgbValue.g, rgbValue.b);
    return ret;
}

export function rgbToHSL(rgbValue) {
    const rgb = colorSchemes.RGB(rgbValue.r, rgbValue.g, rgbValue.b);
    let h = 0;
    let s = 0;
    let l = 0;
    const r = rgb.val.r / 255;
    const g = rgb.val.g / 255;
    const b = rgb.val.b / 255;
    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const delta = max - min;

    if (min === max) {
        h = 0;
    } else {
        if (r === max) {
            h = (g - b) / delta;
        } else if (g === max) {
            h = 2 + (b - r) / delta;
        } else if (b === max) {
            h = 4 + (r - g) / delta;
        }
    }
    h = Math.min(h * 60, 360);
    if (h < 0) { h = h + 360; }
    l = (min + max) / 2;
    if (min === max) {
        s = 0;
    } else {
        if (l < 0.5) {
            s = delta / (max + min);
        } else {
            s = delta / (2 - max - min);
        }
    }

    h = Math.round(h);
    s = Math.round(s * 100);
    l = Math.round(l * 100);

    const ret = colorSchemes.HSL(h, s, l);
    return ret;
}

/* https://github.com/picturae/rgb-lab-conversion */
const whitePoint = {
    D50: [0.96422, 1, 0.82521],
    D65: [0.95047, 1, 1.08883],
};

const rgbSpaces = {
    AdobeRGB1998: {
        name: 'Adobe RGB (1998)',
        gamma: 2.19921875,
        matrix: {
            D50: {
                X: { red: 0.6097559, green: 0.2052401, blue: 0.149224 },
                Y: { red: 0.3111242, green: 0.625656, blue: 0.0632197 },
                Z: { red: 0.0194811, green: 0.0608902, blue: 0.7448387 },
            },
            D65: {
                X: { red: 0.5767309, green: 0.185554, blue: 0.1881852 },
                Y: { red: 0.2973769, green: 0.6273491, blue: 0.0752741 },
                Z: { red: 0.0270343, green: 0.0706872, blue: 0.9911085 },
            },
        },
        whitepoint: 'D65',
    },
    eciRGB_v2: {
        name: 'eciRGB v2',
        gamma: 1.8,
        matrix: {
            D50: {
                X: { red: 0.6502043, green: 0.1780774, blue: 0.1359384 },
                Y: { red: 0.3202499, green: 0.6020711, blue: 0.0776791 },
                Z: { red: 0.0, green: 0.067839, blue: 0.757371 },
            },
            D65: {
                X: { red: 0.67, green: 0.21, blue: 0.14 },
                Y: { red: 0.33, green: 0.71, blue: 0.08 },
                Z: { red: 0, green: 0.08, blue: 0.78 },
            },
        },
        whitepoint: 'D50',
    },
    grayGamma22: {
        // based on AdobeRGB1998
        name: 'Gray Gamma 2.2',
        gamma: 2.19921875,
        matrix: {
            D50: {
                X: {
                    red: 0.3111242 * whitePoint.D50[0],
                    green: 0.625656 * whitePoint.D50[0],
                    blue: 0.0632197 * whitePoint.D50[0],
                },
                Y: { red: 0.3111242, green: 0.625656, blue: 0.0632197 },
                Z: {
                    red: 0.3111242 * whitePoint.D50[2],
                    green: 0.625656 * whitePoint.D50[2],
                    blue: 0.0632197 * whitePoint.D50[2],
                },
            },
            D65: {
                X: {
                    red: 0.2973769 * whitePoint.D65[0],
                    green: 0.6273491 * whitePoint.D65[0],
                    blue: 0.0752741 * whitePoint.D65[0],
                },
                Y: { red: 0.2973769, green: 0.6273491, blue: 0.0752741 },
                Z: {
                    red: 0.2973769 * whitePoint.D65[2],
                    green: 0.6273491 * whitePoint.D65[2],
                    blue: 0.0752741 * whitePoint.D65[2],
                },
            },
        },
        whitepoint: 'D65',
    },
    sRGB: {
        name: 'sRGB IEC61966-2.1',
        gamma: -2.2,
        matrix: {
            D50: {
                X: { red: 0.4360747, green: 0.3850649, blue: 0.1430804 },
                Y: { red: 0.2225045, green: 0.7168786, blue: 0.0606169 },
                Z: { red: 0.0139322, green: 0.0971045, blue: 0.7141733 },
            },
            D65: {
                X: { red: 0.4124564, green: 0.3575761, blue: 0.1804375 },
                Y: { red: 0.2126729, green: 0.7151522, blue: 0.072175 },
                Z: { red: 0.0193339, green: 0.119192, blue: 0.9503041 },
            },
        },
        whitepoint: 'D65',
    },
};

const kappa = 24389 / 27; // 903.3
const epsilon = 216 / 24389; // 0.008856
const rgbLabConversion = (function () {
    const inverseSRGB = function (fractionedRGB, iccProfile) {
        // http://www.brucelindbloom.com/index.html?Eqn_RGB_to_XYZ.html
        const inversedSRGB = fractionedRGB.map(channel => {
            if (channel > 0.04045) {
                channel = Math.pow((channel + 0.055) / 1.055, 2.4);
            } else {
                channel = channel / 12.92;
            }
            return channel;
        });
        return inversedSRGB;
    };
    const inverseLx = function (fractionedRGB, iccProfile) {
        // http://www.color.org/chardata/rgb/ecirgb.xalter
        // Inverse eciRGB Companding
        const inversedLx = fractionedRGB.map(channel => {
            if (channel > epsilon) {
                channel = Math.pow((channel + 0.16) / 1.16, 3);
            } else {
                channel = channel / kappa / 100;
            }
            return channel;
        });
        return inversedLx;
    };
    const inverseGamma = function (fractionedRGB, iccProfile) {
        // http://www.brucelindbloom.com/index.html?Eqn_RGB_to_XYZ.html
        // https://www.easyrgb.com/en/math.php
        const inversedGamma = fractionedRGB.map(channel =>
            Math.pow(channel, iccProfile.gamma),
        );
        return inversedGamma;
    };

    /**
         * XYZ to Lab conversion with color temperature correction
         * @param {number[]} xyzArray
         * @param {number[]} whitePoint
         * @returns {number[]} labArray
         */
    const XYZCIELab = function (xyzArray, whitePoint) {
        const sizedXYZ = xyzArray.map(
            (channel, index) => channel / whitePoint[index] / 100,
        );

        const [X, Y, Z] = sizedXYZ.map((channel, index) => {
            if (channel > epsilon) {
                channel = Math.pow(channel, 1 / 3);
            } else {
                channel = 7.787 * channel + 16 / 116;
            }
            return channel;
        });

        const Lx = 116 * Y - 16;
        const ax = 500 * (X - Y);
        const bx = 200 * (Y - Z);
        return [Lx, ax, bx];
    };

    /**
         * RGB to XYZ companding for some icc profiles
         * @param {number[]} rgbArray
         * @param {string} iccProfileName - one of the predefined rgbSpaces
         * @returns {number[]} xyzArray
         */
    const RGBXYZcompand = function (rgbArray, iccProfileName) {
        const iccProfile = rgbSpaces[iccProfileName];
        if (!iccProfile || !iccProfile.name) {
            console.error(`unknown iccProfile "${iccProfileName}"`);
            return;
        }

        // convert to fractions
        const fractionedRGB = rgbArray.map(channel => channel / 255);

        let inverse;
        switch (iccProfile.name) {
            case 'Adobe RGB (1998)':
                inverse = inverseGamma;
                break;
            case 'eciRGB v2':
                inverse = inverseLx;
                break;
            case 'Gray Gamma 2.2':
                inverse = inverseGamma;
                break;
            case 'sRGB IEC61966-2.1':
                inverse = inverseSRGB;
                break;
            default:
                console.error(`unsupported iccProfile "${iccProfile.name}"`);
                return;
        }
        const inversedRGB = inverse(fractionedRGB, iccProfile);
        const X =
                inversedRGB[0] * 100 * iccProfile.matrix.D50.X.red +
                inversedRGB[1] * 100 * iccProfile.matrix.D50.X.green +
                inversedRGB[2] * 100 * iccProfile.matrix.D50.X.blue;
        const Y =
                inversedRGB[0] * 100 * iccProfile.matrix.D50.Y.red +
                inversedRGB[1] * 100 * iccProfile.matrix.D50.Y.green +
                inversedRGB[2] * 100 * iccProfile.matrix.D50.Y.blue;
        const Z =
                inversedRGB[0] * 100 * iccProfile.matrix.D50.Z.red +
                inversedRGB[1] * 100 * iccProfile.matrix.D50.Z.green +
                inversedRGB[2] * 100 * iccProfile.matrix.D50.Z.blue;
        return [X, Y, Z];
    };

    /**
         * XYZ to L*ab conversion like photoshop does
         * @param {number[]} xyzArray
         * @returns {number[]} labArray
         */
    const XYZ2Lab = function (xyzArray) {
        // photoshop shows lab values with D50
        return XYZCIELab(xyzArray, whitePoint.D50);
    };

    return {
        rgb2XYZ: RGBXYZcompand,
        XYZ2Lab: XYZ2Lab,
    };
})();

export function rgbToLAB(rgbValue) {
    const rgb = colorSchemes.RGB(rgbValue.r, rgbValue.g, rgbValue.b);
    const xyz = rgbLabConversion.rgb2XYZ([rgb.val.r, rgb.val.g, rgb.val.b], 'sRGB');
    const lab = rgbLabConversion.XYZ2Lab([xyz[0], xyz[1], xyz[2]]);

    const ll = Math.round(lab[0]);
    const aa = Math.round(lab[1]);
    const bb = Math.round(lab[2]);

    const ret = colorSchemes.LAB(ll, aa, bb);
    return ret;
}

export function rgbToHSV(rgbValue) {
    const rgb = colorSchemes.RGB(rgbValue.r, rgbValue.g, rgbValue.b);

    const r = rgb.val.r / 255;
    const g = rgb.val.g / 255;
    const b = rgb.val.b / 255;

    let h = 0;
    let s = 0;
    let v = 0;

    const minVal = Math.min(r, g, b);
    const maxVal = Math.max(r, g, b);
    const delta = maxVal - minVal;

    v = maxVal;

    if (delta === 0) {
        h = 0;
        s = 0;
    } else {
        s = delta / maxVal;
        const deltaRGB = {
            r: (((maxVal - r) / 6) + (delta / 2)) / delta,
            g: (((maxVal - g) / 6) + (delta / 2)) / delta,
            b: (((maxVal - b) / 6) + (delta / 2)) / delta,
        };

        if (r === maxVal) {
            h = deltaRGB.b - deltaRGB.g;
        } else if (g === maxVal) {
            h = (1 / 3) + deltaRGB.r - deltaRGB.b;
        } else if (b === maxVal) {
            h = (2 / 3) + deltaRGB.g - deltaRGB.r;
        }
        h = h < 0
            ? h + 1
            : h > 1
                ? h - 1
                : h;
    }

    return colorSchemes.HSV(
        Math.round(h * 360),
        Math.round(s * 100),
        Math.round(v * 100),
    );
}

function hex2rgb(hex) {
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    return { r, g, b };
}

function PMSColorMatching(hex, distance) {
    if (!distance) distance = 32;

    const a = new Set();
    // exact pms
    const m = Object.keys(PMS2RGBmap).find(k => PMS2RGBmap[k] === hex) ?? '';
    if (m) { a.add(m); }

    // search near color
    const { r, g, b } = hex2rgb(hex);

    Object.keys(PMS2RGBmap).forEach(k => {
        const rgb1 = hex2rgb(PMS2RGBmap[k]);
        const dist = Math.sqrt(
            Math.pow((r - rgb1.r), 2) +
            Math.pow((g - rgb1.g), 2) +
            Math.pow((b - rgb1.b), 2),
        );
        if (dist <= distance) {
            a.add(k);
        }
    });
    return [...a];
}

export function hexToPANTONE(hexValue) {
    const hex = hexValue.hex;
    let p = '';

    const dArr = [16, 32, 48, 64, 80, 96];
    for (let i = 0; i < dArr.length; i++) {
        const dist = dArr[i];
        const pArr = PMSColorMatching(hex, dist);
        if (pArr.length > 0) {
            p = pArr[0];
            break;
        }
    }

    const ret = colorSchemes.PANTONE(p);

    return ret;
}
