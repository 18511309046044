import { observable, computed, action, makeObservable } from 'mobx';
import log from '../../ProofX/Logger';
import AnnotationCubit from './AnnotationCubit';
import { useProofXStore } from '../../Store/ProofXStore';
import { isMouseOnImage } from './Utils';
import { ViewerModes } from '../ViewerCubit';
import sendGTMEvent from '../../GTM';

export default class AnnotationManager {
    _stage = null;
    _layer = null;
    _asset = null;
    _annotationCubits = [];
    _visible = true;
    _drawMode = null;
    _drawColor = 'blue';
    _isDrawing = false;
    _selectedFigure = null;
    _activeAnnotation = null;

    constructor() {
        makeObservable(this, {
            _stage: observable.ref,
            _layer: observable.ref,
            _annotationCubits: observable,
            _drawMode: observable,
            _activeAnnotation: observable.ref,
            _selectedFigure: observable.ref,
            _drawColor: observable,
            _visible: observable,

            allCommentsExpanded: computed,
            selectedFigureCubit: computed,
            isPointDrawMode: computed,
            isSquareDrawMode: computed,
            isCircleDrawMode: computed,
            isFreeDrawMode: computed,
            isDrawMode: computed,
            isEditMode: computed,
            drawColor: computed,
            visible: computed,

            reset: action,
            initializeForAsset: action,
            showTextBoxes: action,
            hideTextBoxes: action,
            showAnnotations: action,
            hideAnnotations: action,
            toggleDrawMode: action,
            setActiveAnnotation: action,
            onTextboxUnderlayClick: action,
            setDrawColor: action,
            removeAnnotationCubit: action,
            _updateAnnotationCubits: action,
            _startDraw: action,
            _handleMouseUp: action,
        });
    }

    get stage() { return this._stage; }
    get comments() { return this.store.assetComments; }
    get visible() { return this._visible; }
    get allCommentsExpanded() { return this._annotationCubits.filter(a => !a.expanded).length === 0; }
    get isArrowDrawMode() { return this._drawMode === 'arrow'; }
    get isPointDrawMode() { return this._drawMode === 'point'; }
    get isSquareDrawMode() { return this._drawMode === 'square'; }
    get isCircleDrawMode() { return this._drawMode === 'circle'; }
    get isFreeDrawMode() { return this._drawMode === 'free'; }
    get isDrawMode() { return !!this._drawMode; }
    get drawColor() { return this._drawColor; }
    get isEditMode() { return this._activeAnnotation?.editMode; }
    get allTextBoxes() { return Array.from(document.getElementsByClassName('textbox')); }
    get selectedFigureCubit() { return this._selectedFigure?.figureCubit; }
    get _discussionPanel() { return this.store.discussionPanel; }
    get store() { return useProofXStore.getState(); }

    reset() {
        this._annotationCubits.forEach(c => c.dispose());
        useProofXStore.setState({ assetComments: [] });
        this._stage = null;
        this._layer = null;
        this._annotationCubits = [];
        this._drawMode = null;
        this._activeAnnotation = null;
        this._selectedFigure = null;
        log('🧹 Annotation Manager reset');
    }

    initializeForAsset(asset, stage) {
        this._layer = this.store.fabricLayer;
        this._stage = stage;
        this._layer.hoverCursor = 'pointer';
        this._asset = asset;
        log('✨ Created Annotation Layer', asset, this._layer, this._stage.stageName);

        this._initializeEvents();

        if (!this.visible) {
            this.hideAnnotations();
        }
        this.updateAnnotations();
    }

    updateAnnotations() {
        if (!this.comments || !this._layer) return;
        this._updateAnnotationCubits();
        this.drawAnnotations();
    }

    drawAnnotations() {
        this._layer.renderAll();
    }

    setZoom(zoom) {
        this._annotationCubits.forEach(ac => ac.updateZoom(zoom));
    }

    setDrawColor(color) { this._drawColor = color; }

    showTextBoxes() {
        this._annotationCubits.forEach(ac => ac.expand());
    }

    hideTextBoxes() {
        this._annotationCubits.forEach(ac => ac.collapse());
    }

    showAnnotations() {
        this._visible = true;
        this._annotationCubits.forEach(ac => ac.show());
        this._layer?.renderAll();
    }

    hideAnnotations() {
        this._visible = false;
        this._annotationCubits.forEach(ac => ac.hide());
        this._layer?.renderAll();
    }

    highlightAnnotation(uid) {
        this.dropHighlight();
        const annotation = uid ? this._annotationCubits.find(c => c.uid === uid) : null;
        annotation?.highlight();
    }

    dropHighlight() {
        this._annotationCubits.forEach(a => a.dropHighlight());
    }

    toggleDrawMode(figureType) {
        this._drawMode = figureType || null;
        if (!figureType) return;

        const modeString = figureType.charAt(0).toUpperCase() + figureType.slice(1);
        sendGTMEvent(`Annotatate-${modeString}`);
    }

    setActiveAnnotation(annotationCubit) {
        this._activeAnnotation = annotationCubit ?? null;
    }

    onTextboxUnderlayClick() {
        this._activeAnnotation?.exitEditMode();
    }

    rerender() {
        this._updateAnnotationCubits();
    }

    removeAnnotationCubit(cubit) {
        this._annotationCubits = this._annotationCubits.filter(c => c !== cubit);
    }

    _setAnnotation(comment) {
        const foundAnnotation = this.comments.find(a => a.commentUid === comment.commentUid);
        if (foundAnnotation) {
            // TODO: fix
            this.comments = this.comments.map(a =>
                a.commentUid === comment.commentUid ? comment : a);
        } else {
            this.comments.push(comment);
        }
    }

    _updateAnnotationCubits() {
        log('💫 Updating Annotation Cubits', this._annotationCubits);

        const newAnnotations = this.comments.filter(a => !a.parentCommentUid);
        const newUids = newAnnotations.map(a => a.commentUid);

        // find cubits with a tempUid and finalize them
        const justCreatedAnnotations = newAnnotations.filter(a => !!a.tempUid);
        const theRestOfAnnotations = newAnnotations.filter(a => !a.tempUid);
        justCreatedAnnotations.forEach(a => {
            const cubit = this._annotationCubits.find(ac => ac.tempUid === a.tempUid);
            if (cubit) {
                cubit?.update(a);
            } else {
                this._annotationCubits.push(new AnnotationCubit(a));
            }
            a.tempUid = null;
        });

        // dispose cubits that are absent in updated list
        this._annotationCubits.filter(a =>
            !newUids.includes(a.annotation.commentUid) &&
            !a._figures.find(f => f.isNew),
        ).forEach(a => a.dispose());
        this._annotationCubits = this._annotationCubits.filter(a =>
            newUids.includes(a.annotation.commentUid) || !a.uid,
        );

        // modify existing cubits or create new ones
        theRestOfAnnotations.forEach(a => {
            const existingCubit = this._annotationCubits.find(ac => ac.annotation.commentUid === a.commentUid);
            if (existingCubit) {
                existingCubit.update(a);
            } else {
                this._annotationCubits.push(new AnnotationCubit(a));
            }
        });

        if (!this.visible) {
            this.hideAnnotations();
        }
    };

    _updateAnnotationWithNewFigures(annotationWithNewFigures, newAnnotations) {
        const figureJustDrawn = annotationWithNewFigures._figures.find(f => f.isNew);
        if (!figureJustDrawn) return;
        const correspondingAnnotation = this._findCorrespondingNewAnnotation(figureJustDrawn, newAnnotations);
        annotationWithNewFigures.update(correspondingAnnotation);
    }

    _findCorrespondingNewAnnotation(figureJustDrawn, newAnnotations) {
        const searchTerm = JSON.stringify({
            figureType: figureJustDrawn.figureType,
            basePoint: figureJustDrawn.position,
            dimensions: figureJustDrawn.dimensions,
        });
        const matchMap = newAnnotations.map(a => {
            const figures = JSON.parse(a.json).figures;
            return figures.map(f => ({
                annotation: a,
                searchData: JSON.stringify({
                    figureType: f.type,
                    basePoint: f.basePoint,
                    dimensions: f.dimensions,
                }),
            }));
        }).flat();
        const foundEntry = matchMap.find(m => m.searchData === searchTerm);
        return foundEntry.annotation;
    }

    _selectFigure(figureObj) {
        // if the figure is already selected, skip action
        if (this._selectedFigure?.figureCubit === figureObj.figureCubit) return;
        this._selectedFigure?.figureCubit.unselect();
        figureObj.figureCubit.select();
        this._selectedFigure = figureObj;
    }

    clearFigureSelection() {
        this._selectedFigure?.figureCubit.unselect();
        this._selectedFigure = null;
    }

    _adjustCursor(evt) {
        if (!this._layer) return;
        this._layer.defaultCursor = this._drawMode && isMouseOnImage(this._layer.getPointer(evt)) ? 'crosshair' : 'default';
    }

    _startDraw(evt) {
        if (!this._drawMode) return;

        const pointer = this._layer.getPointer(evt);
        if (!isMouseOnImage(pointer)) return;

        if (!this._activeAnnotation) {
            const newAnnotation = { isNew: true, color: this._drawColor ?? 'blue' };
            this._activeAnnotation = new AnnotationCubit(newAnnotation);
            this._annotationCubits.push(this._activeAnnotation);
            log('🦶 added new cubit', this._activeAnnotation, this._annotationCubits);
        }
        this._isDrawing = true;
        this._activeAnnotation.startDraw(this._drawMode, pointer);
    }

    // #region Event handlers

    _initializeEvents() {
        this.store.viewer.addEvents([ViewerModes.annotations, ViewerModes.fileInfo], {
            'mouse:over': (evt) => this._handleMouseOver(evt.target, evt.e),
            'mouse:out': (evt) => this._handleMouseOut(evt.target),
            'mouse:move': (evt) => this._handleMouseMove(evt.target, evt.e),
            'mouse:down': (evt) => this._handleMouseDown(evt.target),
            'mouse:up': (evt) => this._handleMouseUp(evt.target, evt.e),
            'object:scaling': (evt) => this._handleObjectScaling(evt.target),
            'object:moving': (evt) => this._handleObjectMoving(evt.target),
            'object:modified': (evt) => this._handleObjectModified(evt.target),
        });
    }

    _handleMouseOver(target, evt) {
        if (target?.isType('figure') && !target?.figureCubit.active) {
            target.figureCubit.hover(true, true);
        }
        if (!target) {
            this._adjustCursor(evt);
        }
    }

    _handleMouseOut(target) {
        if (target?.isType('figure') && !target?.figureCubit.active) {
            target.figureCubit.hover(false, true);
        }
    }

    _handleMouseMove(target, evt) {
        if (!target) {
            this._adjustCursor(evt);
        }
        if (this._isDrawing) {
            const pointer = this._layer.getPointer(evt);
            this._activeAnnotation.continueDrawing(pointer);
        }
    }

    _handleMouseDown(target, evt) {
        if (!target) {
            this.clearFigureSelection();
            if (this._drawMode) {
                this._startDraw(evt);
            }
        }
    }

    _handleMouseUp(target, evt) {
        if (this._isDrawing) {
            const pointer = this._layer.getPointer(evt);
            this._activeAnnotation.endDrawing(pointer);
            this._isDrawing = false;
        } else if (target?.isType('figure')) {
            this._selectFigure(target);
        }
    }

    _handleObjectScaling(target) {
        if (target?.isType('boundingRect')) {
            target.figureCubit.handleScaling();
        }
    }

    _handleObjectMoving(target) {
        if (target?.isType('boundingRect')) {
            target.figureCubit.handleMoving();
        }
        // arrow reposition controls
        if (target?.isType('resizeControl')) {
            target.figureCubit.handleReposition();
        }
    }

    _handleObjectModified(target) {
        if (target.isType('boundingRect')) {
            target.figureCubit.endMovingOrScaling();
        }
        if (target?.isType('resizeControl')) {
            target.figureCubit.endReposition();
        }
    }

    // #endregion
}
