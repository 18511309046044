import React from 'react';
import { observer } from 'mobx-react-lite';
import { FlexRow } from '../../styles';
import styled from 'styled-components';
import { UserCircle } from '@phosphor-icons/react';
import { useProofXStore } from '../Store/ProofXStore';
import { getRandomColor } from '../Viewer/AnnotationLayer/Utils';
import Tooltip from '../uicontrols/Tooltip';

const Wrapper = styled(FlexRow)`
    margin: 0 8px;
`;

const AvaratWrapper = styled(FlexRow)`
    margin-left: -6px;
                
    &:not(:last-of-type) {
        mask: radial-gradient(13px at 32px 50%,transparent 100%, #fff 100%)        
    }
`;

const AvatarImg = styled.img`
    width: 22px;
    height: 22px;
    border-radius: 15px;
    border: 2px solid var(--secondary-color);
`;

const ExternalUser = styled(UserCircle)`
    margin-top: -4px;
    margin-left: -4px;
`;

const ExternalUserBackground = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 15px;
    margin: 1px;
    background-color: var(--colored-button-text);
`;

const ExternalUserAvatar = ({ color }) => (
    <ExternalUserBackground>
        <ExternalUser size={32} color={color} weight='duotone' />
    </ExternalUserBackground>
);

const Avatar = React.memo(function Avatar({ uid, isExternal, name }) {
    return (
        <AvaratWrapper>
            <Tooltip text={name} flex>
                {isExternal
                    ? <ExternalUserAvatar color={getRandomColor(uid)} />
                    : <AvatarImg src={`/Api/GetUserAvatar/${uid}`} />
                }
            </Tooltip>
        </AvaratWrapper>

    );
});

export default observer(function UserAttendance() {
    const users = useProofXStore((state) => state.proofX?.loggedInUsers ?? []);
    return (
        <Wrapper>
            {users.map(u => <Avatar uid={u.UID} isExternal={u.IsExt === 'True'} key={u.UID} name={u.UserName} />)}
        </Wrapper>
    );
});
