import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../../Store/ProofXStore';
import { FlexRow, smallIconSize } from '../../../styles';
import Tooltip from '../../uicontrols/Tooltip';
import ToggleButton from '../../uicontrols/ToggleButton';
import { HandGrabbing } from '@phosphor-icons/react';

const Wrapper = styled(FlexRow)`
    padding: var(--sm);
    border-radius: 8px;
    justify-content: center;
    background-color: var(--glass-background);
    box-shadow: var(--glass-shadow-lite);
    backdrop-filter: var(--glass-blur);
    font-size: var(--font-size-md);
`;

const Prompt = styled.div`
    margin-right: 8px;
    font-family: var(--medium);
`;

export default observer(function RulerTool() {
    const strings = useProofXStore((state) => state.strings);
    const cubit = useProofXStore((state) => state.viewer?.barcodeScanner);

    return (
        <Wrapper>
            <Prompt>
                {cubit?.handMode ? strings.barcodePanningPrompt : strings.barcodeScannerPrompt}
            </Prompt>
            <Tooltip text={cubit?.handMode ? strings.toScanMode : strings.toPanMode} flex>
                <ToggleButton
                    isOn={cubit?.handMode}
                    Icon={<HandGrabbing size={smallIconSize} weight='bold' />}
                    onClick={() => cubit.toggleHandMode()}
                />
            </Tooltip>
        </Wrapper>
    );
});
