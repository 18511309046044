import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// #region Styles

const MotionDiv = styled(motion.div).attrs(() => ({ layout: true }))`
    overflow: hidden;
`;

// #endregion

const variants = {
    open: { height: 'auto', overflow: 'visible' },
    openHidden: { height: 'auto', overflow: 'hidden' },
    closed: { height: 0, overflow: 'hidden' },
};

export default function FoldingPanel({ isOpen, children, initiallyOpen, hiddenOverflow }) {
    return (
        <MotionDiv
            layout
            initial={initiallyOpen ? 'open' : 'closed'}
            animate={isOpen ? (hiddenOverflow ? 'openHidden' : 'open') : 'closed'}
            variants={variants}
            transition={{
                duration: 0.2,
                ease: 'easeInOut',
            }}
        >
            { children }
        </MotionDiv>

    );
};
