import { CaretLeft, ImageSquare } from '@phosphor-icons/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../Store/ProofXStore';
import StageToolButton from '../uicontrols/StageToolButton';
import { FlexColumn } from '../../styles';

const Wrapper = styled(FlexColumn)`
    justify-content: center;
    padding: 8px;
`;

export default observer(function ViewerLeftToolbar() {
    const strings = useProofXStore((state) => state.strings);
    const proofX = useProofXStore((state) => state.proofX);
    const splitView = useProofXStore((state) => state.splitView);

    const toggleLeftDrawer = () => {
        if (splitView.leftDrawerOpened) {
            splitView.closeLeftDrawer();
        } else {
            splitView.openLeftDrawer();
        }
    };

    if (!proofX) return null;

    return (
        <Wrapper>
            <StageToolButton left big
                filled={!splitView.leftDrawerOpened}
                Icon={splitView.leftDrawerOpened ? CaretLeft : ImageSquare}
                title={splitView.leftDrawerOpened ? strings.hideAssetsPanel : strings.showAssets}
                onPress={toggleLeftDrawer}
            />
        </Wrapper >
    );
});
