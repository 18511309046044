import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../Store/ProofXStore';
import Stage from '../Viewer/Stage';
import { StageTypes } from '../Viewer/StageCubit';
import { StageContainer } from '../Viewer/Viewer';

const StageLayer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
`;

export default observer(function FaderLayout() {
    const mainStageContainerRef = useRef();
    const middleStageContainerRef = useRef();
    const compareStageContainerRef = useRef();
    const viewer = useProofXStore((state) => state.viewer);
    const mainAsset = useProofXStore((state) => state.assets.main);
    const compareAsset = useProofXStore((state) => state.assets.compare);

    if (!viewer || !mainAsset) return;

    return (<>
        <StageContainer id='main-stage-container' ref={mainStageContainerRef}>
            <Stage key={`stage-${mainAsset.uid}-${mainAsset.page ?? 0}`}
                asset={mainAsset}
                wrapperRef={mainStageContainerRef}
                stageType={StageTypes.main}
                showControls={false}
            />
        </StageContainer>
        <StageLayer id='middle-stage-container' ref={middleStageContainerRef}>
            <Stage key={`middle-stage-${mainAsset.uid}-${mainAsset.page ?? 0}-${compareAsset?.uid}-${compareAsset?.page ?? 0}`}
                asset={compareAsset}
                wrapperRef={middleStageContainerRef}
                stageType={StageTypes.middle}
                showControls={false}
            />
        </StageLayer>
        <StageLayer id='compare-stage-container' ref={compareStageContainerRef}>
            <Stage key={`compare-stage-${compareAsset?.uid}-${compareAsset?.page ?? 0}`}
                asset={compareAsset}
                wrapperRef={compareStageContainerRef}
                stageType={StageTypes.compare}
                showControls={true}
            />
        </StageLayer>
    </>);
});
