import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FlexRow } from '../../styles';

// #region Styles

const Wrapper = styled(FlexRow)`
    cursor: pointer;
`;

const IconButton = styled.div`
    position: relative;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
`;

const Label = styled(FlexRow)`
    color: var(--secondary-color);
    font-size: 14px;
    margin-left: 8px;
`;

const MotionDiv = styled(motion.div)`
    position: absolute;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    top: 0;
    left: 0;
    scale: 0.9;
    color: var(--secondary-color);
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
`;

// #endregion

export default function FlippingToggle({ isOn, big, OnIcon, OffIcon, onClick, label }) {
    const size = big ? 30 : 24;
    return (
        <Wrapper {...{ onClick }}>
            <IconButton {...{ size }}>
                <AnimatePresence>
                    {!isOn && (
                        <MotionDiv key={'flipping-toggle-off'}
                            {...{ size }}
                            initial={{ opacity: 0, rotate: -180 }}
                            animate={{ opacity: 1, rotate: 0 }}
                            exit={{ opacity: 0, rotate: 180 }}
                            transition={{
                                duration: 0.2,
                                ease: 'easeInOut',
                            }}
                            whileHover={{
                                color: 'var(--primary-color)',
                                scale: 1.1,
                                transition: { duration: 0.2 },
                            }}
                        >
                            {OnIcon}
                        </MotionDiv>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {isOn && (
                        <MotionDiv key={'flipping-toggle-on'}
                            {...{ size }}
                            initial={{
                                opacity: 0,
                                rotate: -90,
                            }}
                            animate={{
                                opacity: 1,
                                rotate: 0,
                            }}
                            exit={{ opacity: 0, rotate: 90 }}
                            transition={{
                                duration: 0.2,
                                ease: 'easeInOut',
                            }}
                            whileHover={{
                                color: 'var(--primary-color)',
                                scale: 1.1,
                                transition: { duration: 0.2 },
                            }}
                        >
                            {OffIcon}
                        </MotionDiv>
                    )}
                </AnimatePresence>
            </IconButton>
            {label && (
                <Label >{label}</Label>
            )}
        </Wrapper>
    );
};
