import { observable, computed, makeObservable, override, action } from 'mobx';
import ModalCubit from './ModalCubit';
import { ReviewStatus } from '../ProofX/ProofXCubit';
import { useProofXStore } from '../Store/ProofXStore';

export default class ApprovalModalCubit extends ModalCubit {
    _message = '';
    _confirmText = '';
    _checklist = '';
    _action = null;
    _confirmHandler = () => { };
    _customCheckboxChecked = false;

    constructor() {
        super();
        makeObservable(this, {
            _message: observable,
            _confirmText: observable,
            _confirmHandler: observable,
            _checklist: observable.ref,
            _action: observable,
            _customCheckboxChecked: observable,

            message: computed,
            confirmText: computed,
            confirmHandler: computed,
            hasCustomCheckbox: computed,
            customCheckboxChecked: computed,
            checklist: computed,
            isValid: computed,

            toggleChecklistItem: action,
            toggleCustomCheckbox: action,

            show: override,
        });
    }

    get message() { return this._message; }
    get confirmText() { return this._confirmText; }
    get environment() { return useProofXStore.getState()?.proofX?.environment; }
    get settings() { return this.environment?.approvalSettings; }
    get isApproval() { return this._action === ReviewStatus.Approved; }
    get customCheckboxChecked() { return this._customCheckboxChecked; }
    get checklistItems() {
        const env = this.environment;
        if (!env) return [];
        const taskChecklist = env.task?.checklistItems;
        if (taskChecklist) {
            return taskChecklist.split('||');
        } else if (env.flags.isCommonExternalReview) {
            const defaultChecklist = env.approvalSettings?.checklists?.find(ch => ch.isDefault);
            return defaultChecklist?.options?.split(';') ?? [];
        }
        return [];
    }

    get hasCustomCheckbox() {
        return this._action === ReviewStatus.Approved &&
            this.settings.customApprovalEnabled &&
            this.settings.showCheckBox;
    }

    get checklist() {
        return this._checklist;
    }

    get isValid() {
        if (!this._action) return false;
        if (!this.isApproval) return true;

        if (this.hasCustomCheckbox && !this.customCheckboxChecked) return false;
        if (this.checklist) {
            return this.checklist.filter(i => i.checked).length === this.checklist.length;
        }
        return true;
    }

    get confirmHandler() {
        return () => {
            this._confirmHandler(this._checklist?.filter(i => i.checked).map(i => i.name));
            this.hide();
        };
    }

    show(action, confirmHandler) {
        const { strings } = useProofXStore.getState();
        const settings = this.settings;
        this._action = action;
        switch (action) {
            case ReviewStatus.Approved:
                this._title = strings.confirmApprove;
                this._message = settings.customApprovalEnabled ? settings.approvalMessage : strings.confirmApprovePrompt;
                this._confirmText = strings.approve;
                break;
            case ReviewStatus.ApprovedWithChanges:
                this._title = strings.confirmApproveWithChanges;
                this._message = strings.confirmApproveWithChangesPrompt;
                this._confirmText = strings.approveWithChanges;
                break;
            case ReviewStatus.Rejected:
                this._title = strings.confirmRequestChanges;
                this._message = strings.confirmRequestChangesPrompt;
                this._confirmText = strings.requestChanges;
                break;
            default:
                console.error('Unknown approval action: ', action);
                return;
        }
        this._confirmHandler = confirmHandler;
        this._customCheckboxChecked = false;
        this._checklist = this.checklistItems
            .map(i => ({ name: i, checked: false })) ?? null;
        super.show();
    }

    toggleChecklistItem(name) {
        this._checklist = this._checklist.map(i => i.name === name ? { ...i, checked: !i.checked } : i);
    }

    toggleCustomCheckbox() {
        this._customCheckboxChecked = !this._customCheckboxChecked;
    }
}
