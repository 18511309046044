import { Plus, Smiley } from '@phosphor-icons/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useLayer } from 'react-laag-v18';
import styled from 'styled-components';

import { useProofXStore } from '../Store/ProofXStore';
import HoverClarifier from './HoverClarifier';
import Tooltip from './Tooltip';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const Trigger = styled.div`
    font-family: var(--emoji-font);
    display: flex;
    flex-direction: row;
    cursor: pointer;
`;

const Flyout = styled(motion.div)`
    position: relative;
    border-radius: 8px;        
    padding: 6px;
    display: flex;
    flex-direction: row;
    font-family: var(--emoji-font);
    font-size: 16px;
    background-color: var(--popover-background);
    box-shadow: 0px 0px 15px 2px var(--glass-shadow-color);
    transform-origin: top center;
`;

const Emoji = styled.div`
    font-family: var(--emoji-font);
    margin-right: 0px;
    font-size: 12px;
`;

const TooltipColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const TooltipItem = styled.div`
    padding: 4px;
`;

const TooltipHeader = styled.div`
    font-family: var(--emoji-font);
    font-size: 18px;
    border-bottom: 1px solid var(--glass-border);
    margin-bottom: 2px;
    padding: 2px;
`;

const Hover = styled(HoverClarifier).attrs({ scaleFactor: 0.4, tapScaleFactor: 0.1, style: { display: 'flex' } })``;

const emojis = ['👍', '👎', '❤', '🙏', '😂', '😮', '😢', '😡'];

export default function ReactionPicker({ reactions, onChange }) {
    const [isOpen, setOpen] = useState(false);
    const strings = useProofXStore((state) => state.strings);

    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen,
        onOutsideClick: () => setOpen(false), // close the menu when the user clicks outside
        onDisappear: () => setOpen(false), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => setOpen(false), // close the menu when the parent closes (e.g. a modal)
        overflowContainer: false, // keep the menu positioned inside the container
        auto: true, // automatically find the best placement
        placement: 'bottom-center', // we prefer to place the menu "top-end"
        possiblePlacements: ['bottom-center'],
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 4, // give the menu some room to breath relative to the container
    });
    const reactionsGroupped = reactions.reduce((acc, curr) => {
        const key = curr.Emoji;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(curr.ExecutorName);
        return acc;
    }, {});
    const reactionsEmojis = Object.keys(reactionsGroupped);

    return (<>
        <Row>
            {reactionsEmojis.map((emoji) => (
                <Tooltip key={emoji} disabled={isOpen} text={
                    <TooltipColumn>
                        <TooltipHeader>{emoji}</TooltipHeader>
                        {reactionsGroupped[emoji].map((name) => <TooltipItem key={name}>{name}</TooltipItem>)}
                    </TooltipColumn>
                } placement='top-center'>
                    <Hover initialOpacity={1} onClick={() => onChange(emoji)}>
                        <Emoji key={emoji}>{emoji}</Emoji>
                    </Hover>
                </Tooltip>
            ))}
            <Tooltip disabled={isOpen} text={strings.addReaction} >
                <Trigger {...triggerProps} onClick={() => setOpen(true)}>
                    <Hover style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                        <Smiley size={16} color='var(--secondary-color)' />
                        <Plus size={8} color='var(--secondary-color)' weight='bold' style={{ marginLeft: -2 }} />
                    </Hover>
                </Trigger>
            </Tooltip>
        </Row>

        {renderLayer(
            <div {...layerProps}>
                <AnimatePresence>
                    {isOpen && (
                        <Flyout
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}
                            transition={{
                                duration: 0.25,
                                type: 'spring',
                                bounce: 0.35,
                            }}
                        >
                            {emojis.map((emoji, i) => (
                                <Hover
                                    key={i}
                                    onClick={() => {
                                        onChange(emoji);
                                        setOpen(false);
                                    }}
                                    initialOpacity={1}
                                    style={{ margin: '0 3px' }}
                                >
                                    {emoji}
                                </Hover>
                            ))}
                        </Flyout>
                    )}
                </AnimatePresence>
            </div>,
        )}
    </>);
};
