/* global fabric */

import FigureCubit, { styles } from '../FigureCubit';
import { getRotatedDimensions } from '../Utils';

export default class PointFigure extends FigureCubit {
    createShape() {
        const zoom = this._layer.getZoom();
        this._dimensions = { x: 0, y: 0 };
        this._figure = new fabric.Circle({
            type: 'figure',
            figureType: 'point',
            radius: styles.pointFigureRadius / zoom,
            strokeWidth: styles.figureStroke / zoom,
            stroke: this._annotationCubit.color,
            selectable: false,
            fill: this._annotationCubit.color,
            originX: 'center',
            originY: 'center',
            hasControls: false,
            opacity: styles.opacity.default,
        });
        this._layer.add(this._figure);
        return this._figure;
    }

    _continueDraw(pointer) {
        const startingPoint = this._drawingProps.startingPoint;
        pointer = pointer ?? startingPoint;
        this.placeShape({
            x: Math.max(0, pointer.x),
            y: Math.max(0, pointer.y),
        });
    };

    placeShape(position, dimensions) {
        this._position = position;
        const mod = getRotatedDimensions(this._position, this._dimensions);
        this._figure.set({
            left: mod.position.x,
            top: mod.position.y,
            stroke: this._annotationCubit.color,
            fill: this._annotationCubit.color,
        });
    }

    findLineEnd(startPoint) {
        return {
            x: this._figure.left,
            y: this._figure.top,
        };
    };

    get _radius() {
        return styles.pointFigureRadius / this.zoom;
    }

    _updateZoom() {
        this._figure.set({
            strokeWidth: styles.figureStroke / this.zoom,
            radius: this._radius,
        });
        this._figure.setCoords();
    }

    _onColorAnimated(value) {
        this._figure?.set({ fill: value });
        super._onColorAnimated(value);
    }

    _calculateBoundingRectPosition() {
        const mod = getRotatedDimensions(this._position, this._dimensions);
        const strokeWidth = styles.figureStroke / this.zoom;
        const radius = this._radius;
        this._boundingRect.set({
            left: mod.position.x - radius - strokeWidth,
            top: mod.position.y - radius - strokeWidth,
            width: 2 * (radius + strokeWidth),
            height: 2 * (radius + strokeWidth),
        });
        this._boundingRect.setCoords();
    }

    _calculateNewPosition() {
        super._calculateNewPosition();
        const strokeWidth = styles.figureStroke / this.zoom;
        this._position = {
            x: this._position.x + this._radius + strokeWidth,
            y: this._position.y + this._radius + strokeWidth,
        };
        // const zoom = this._layer.getZoom();
        // const strokeWidth = styles.figureStroke / zoom;
        // this._position = {
        //     x: this._position.x - strokeWidth * rotationMatrix[angle].y,
        //     y: this._position.y - strokeWidth * rotationMatrix[angle].x,
        // };

        // const rotatedPosition = {
        //     x: this._figure.left,
        //     y: this._figure.top,
        // };
        // this._position = getRotatedPoint(rotatedPosition);
    }
}
