import React, { useRef } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import Thumbnail from './Thumbnail';
import { useProofXStore } from '../Store/ProofXStore';
import Checkbox from '../uicontrols/Checkbox';
import { FlexRow } from '../../styles';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;    
    height: ${({ height }) => height ?? 'calc(var(--app-height) - var(--appbar-height))'};
    overflow: auto;
`;

const CheckboxWrapper = styled(FlexRow)`
    padding: var(--sm) var(--md);
    flex-direction: flex-end;
`;

const CheckboxLabel = styled.div`
    font-size: var(--font-size-lg);
    font-family: var(--medium);
    margin-right: var(--sm);
`;

const CheckboxContainer = styled.div`
    position: absolute;
    top: var(--sm);
    right: var(--md);    
    color: var(--colored-button-text);
    border-radius: 2px;
    z-index: 10;
`;

const CheckboxBackground = styled.div`
    position: absolute;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    top: 1px;
    left: 1px;
    z-index: -1;
    background-color: var(--primary-color);
`;

export default observer(function ThumbnailsPanel({ cubit, height }) {
    const proofX = useProofXStore((state) => state.proofX);
    const isSelectiveApproval = useProofXStore((state) => state.proofX?.isSelectiveApproval ?? false);
    const showApprovalTools = useProofXStore((state) => state.proofX?.showApprovalTools ?? false);
    const strings = useProofXStore((state) => state.strings);
    const wrapperRef = useRef();
    const checkboxSize = isSelectiveApproval ? 34 : 0;
    const showSelectAllCheckBox = isSelectiveApproval && !cubit.isComparePanel && showApprovalTools && cubit?.thumbnailCubits.length > 1;

    return (
        <Wrapper ref={wrapperRef} height={height}>
            {showSelectAllCheckBox && (
                <CheckboxWrapper>
                    <CheckboxLabel>{strings.selectAll}</CheckboxLabel>
                    <CheckboxContainer>
                        <CheckboxBackground />
                        <Checkbox size={18} filled checked={proofX.isSelectAllAssetsChecked} onClick={() => proofX.toggleSelectAllAssetsChecked()} />
                    </CheckboxContainer>
                </CheckboxWrapper>
            )}
            <SimpleBar style={{ width: 200, height: `calc(100% - ${checkboxSize}px)` }}>
                {cubit?.thumbnailCubits.map((tc, index) => (
                    <Thumbnail key={index} cubit={tc} />
                ))}
            </SimpleBar>
        </Wrapper>
    );
});
