import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import UsersDropdownMenu from './UsersDropdownMenu';
import { useProofXStore } from '../../Store/ProofXStore';

// #region Styles

const EditorElement = styled.div.attrs(() => ({
    contentEditable: 'true',
}))`
    border: 1px solid var(--input-border);
    border-radius: 4px;
    background-color: var(--input-background);
    outline: none;
    font-size: 12px;
    font-family: var(--medium);
    padding: ${({ padding }) => padding ?? '0'};
    user-select: text;
    min-height: 14px;
`;

// #endregion

export default observer(function Editor({ cubit, padding }) {
    const editorRef = React.useRef();
    const strings = useProofXStore((state) => state.strings);

    React.useEffect(() => {
        if (cubit && editorRef.current) {
            cubit.onEditorUpdated(editorRef.current);
        }
    }, [editorRef, cubit]);

    return (
        <>
            <EditorElement
                id={`editor-${cubit?.id}`}
                ref={editorRef}
                padding={padding}
                placeholder={strings.enterYourComment}
                onInput={(evt) => cubit?.onInput(evt)}
                onKeyDown={(evt) => cubit?.onKeyDown(evt)}
                suppressContentEditableWarning={true}
                dangerouslySetInnerHTML={{ __html: cubit.initialHtml }}
            />
            <UsersDropdownMenu cubit={cubit.usersDropdown}><div /></UsersDropdownMenu>
        </>
    );
});
