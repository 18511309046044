import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    svg {
        filter: drop-shadow(0 8px 18px var(--glass-shadow-color));
    }
`;

export default function ApprovalToolsPanel({ width, height, upperRadius, lowerRadius }) {
    const ur = upperRadius;
    const lr = lowerRadius;
    const lh = Math.max(height - lr - ur, 0);
    const lw = Math.max(width - 2 * ur - 2 * lr, 0);
    return (
        <Wrapper>
            <svg height={height} width={width} viewBox={`0 0 ${width} ${height}`} >
                <path fill="var(--glass-background-light)" stroke='var(--glass-border-color)' strokeWidth='1'
                    d={`M 0 0 
                        Q ${ur} 0 ${ur} ${ur} 
                        L ${ur} ${ur + lh} 
                        Q ${ur} ${ur + lh + lr} ${ur + lr} ${ur + lh + lr}
                        L ${ur + lr + lw} ${ur + lh + lr}
                        Q ${ur + 2 * lr + lw} ${ur + lh + lr} ${ur + 2 * lr + lw} ${ur + lh} 
                        L ${ur + 2 * lr + lw} ${ur}
                        Q ${ur + 2 * lr + lw} 0 ${width} 0 Z`}>
                </path>
            </svg>
        </Wrapper>
    );
}
