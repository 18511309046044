import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useProofXStore } from '../Store/ProofXStore';
import Stage from './Stage';
import { StageTypes } from './StageCubit';
import { StageContainer, StageSlider } from './Viewer';

export default observer(function ViewLayout({ containerHeight }) {
    const mainStageContainerRef = useRef();
    const mainAsset = useProofXStore((state) => state.assets.main);
    const mainAssetThumbnail = useProofXStore((state) => state.assets.main?.thumbnail);
    const isMobile = useProofXStore((state) => state.proofX?.isMobile ?? false);
    const splitView = useProofXStore((state) => state.splitView);
    const annotationManager = useProofXStore((state) => state.annotationManager);
    if (!mainAsset) return;

    const handlePointerDown = (e) => {
        annotationManager?.dropHighlight();
        if (isMobile) {
            splitView.closeLeftDrawer();
            splitView.closeRightDrawer();
        }
    };

    return (
        <StageContainer ref={mainStageContainerRef} onPointerDown={handlePointerDown}>
            <StageSlider {...{ containerHeight }} assetUid={mainAsset.uid} direction={mainAsset.appearenceDirection}>
                <Stage key={`stage-${mainAsset.uid}-${mainAsset.page ?? 0}-${mainAsset.layerDir ?? ''}-${(mainAssetThumbnail ?? '').length}}}`}
                    asset={mainAsset}
                    thumbnail={mainAssetThumbnail}
                    wrapperRef={mainStageContainerRef}
                    stageType={StageTypes.main}
                    showControls={true}
                />
            </StageSlider>
        </StageContainer>
    );
});
