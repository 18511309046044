import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';
import { useLayer } from 'react-laag-v18';
import { FlexRow } from '../../../styles';

const Trigger = styled.div`
    ${({ styles }) => styles}
`;

const Menu = styled(motion.div).attrs(() => ({ layout: true }))`
    border-radius: 8px;
    background-color: var(--popover-background);  
    box-shadow: var(--glass-shadow-popup);
    font-size: 12px;
    display: flex;
    flex-direction: column; 
    transform-origin: top left;
    max-height: 300px;
    overflow: auto;
`;

const MenuItem = styled(FlexRow)`
    padding: 6px 12px;
    background-color: ${({ highlighted }) => highlighted ? 'var(--highlighted-background)' : ''};
    
    :first-child {
        border-radius: 8px 8px 0 0;
        padding-top: 8px;
    }
    :last-child {
        border-radius: 0 0 8px 8px;
        padding-bottom: 8px;
    }
`;

const Icon = styled.div`
    display: flex;
    margin-right: 6px;
`;

const variants = {
    opened: { opacity: 1, scale: 1 },
    closed: { opacity: 0, scale: 0 },
};

export default observer(function DropdownMenu({ cubit, children }) {
    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen: cubit.isOpen,
        onOutsideClick: () => cubit.close(), // close the menu when the user clicks outside
        onDisappear: () => cubit.close(), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => cubit.close(), // close the menu when the parent closes (e.g. a modal)
        overflowContainer: true, // keep the menu positioned outside the container
        auto: true, // automatically find the best placement
        placement: cubit.placement ?? 'bottom-start', // we prefer to place the menu "top-end"
        possiblePlacements: cubit.possiblePlacements,
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 8, // give the menu some room to breath relative to the container
        // arrowOffset: 16 // let the arrow have some room to breath also
    });

    return (<>
        <Trigger {...triggerProps} styles={cubit.triggerStyles ?? ''} className='trigger'>
            {children}
        </Trigger>
        {cubit?.isOpen && renderLayer(
            <div {...layerProps} onClick={() => { !cubit.stayOnClick && cubit.close(); }}>
                <Menu
                    variants={variants}
                    initial='closed'
                    animate={cubit?.isOpen ? 'opened' : 'closed'}
                    exit='closed'
                    transition={{
                        duration: 0.5,
                        type: 'spring',
                        bounce: 0.35,
                    }}
                >
                    {cubit?.menuOptions?.map((o, i) => (
                        (!o.condition || o.condition(cubit.stateCubit)) && (
                            <MenuItem key={i}
                                highlighted={cubit?.highlightedOptionIndex === i}
                                onPointerEnter={() => {
                                    cubit?.highlightOption(i);
                                }}
                                onClick={o.action ? o.action : () => cubit?.selectOption(i)}>
                                {o.icon && <Icon>{o.icon}</Icon>}
                                {o.value}
                            </MenuItem>
                        )))}
                </Menu>
            </div>,
        )}
    </>);
});
