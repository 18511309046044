import React from 'react';
import { CircleNotch } from '@phosphor-icons/react';

export default function Spinner({ size, style }) {
    return (
        <CircleNotch color="var(--secondary-color)" size={size ?? 36} weight="bold" style={style}>
            <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1.5s"
                from="0 0 0"
                to="360 0 0"
                repeatCount="indefinite"
            ></animateTransform>
        </CircleNotch>
    );
};
