import { Square } from '@phosphor-icons/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useLayer } from 'react-laag-v18';
import styled from 'styled-components';

import { useProofXStore } from '../Store/ProofXStore';
import { annotationColors } from '../Viewer/AnnotationLayer/AnnotationCubit';
import { ColorButton } from '../Viewer/AnnotationLayer/TextBox/TextBoxMenu';
import AppTool from '../AppBar/AppTool';

const Flyout = styled(motion.div)`
    position: relative;
    border-radius: 8px;        
    padding: 6px;
    display: flex;
    flex-direction: row;
    font-size: 11px;
    background-color: var(--popover-background);
    box-shadow: 0px 0px 15px 2px var(--glass-shadow-color);
    transform-origin: top center;
`;

export default function ColorSelector() {
    const [isOpen, setOpen] = useState(false);
    const annotationManager = useProofXStore((state) => state.annotationManager);
    const strings = useProofXStore((state) => state.strings);

    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen,
        onOutsideClick: () => setOpen(false), // close the menu when the user clicks outside
        onDisappear: () => setOpen(false), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => setOpen(false), // close the menu when the parent closes (e.g. a modal)
        overflowContainer: false, // keep the menu positioned inside the container
        auto: true, // automatically find the best placement
        placement: 'bottom-center', // we prefer to place the menu "top-end"
        possiblePlacements: ['bottom-center'],
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 4, // give the menu some room to breath relative to the container
    });

    const pickColor = (color) => {
        annotationManager.setDrawColor(color);
        setOpen(false);
    };

    const color = annotationColors[annotationManager?.drawColor ?? 'blue'];

    return (<>
        <div {...triggerProps}>
            <AppTool filled small
                Icon={Square}
                title={strings.pickColor}
                color={color}
                onPress={() => setOpen(true)}
            />
        </div>

        {renderLayer(
            <div {...layerProps}>
                <AnimatePresence>
                    {isOpen && (
                        <Flyout
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}
                            transition={{
                                duration: 0.25,
                                type: 'spring',
                                bounce: 0.35,
                            }}
                        >
                            {Object.keys(annotationColors).map(color => (
                                <ColorButton key={color} onClick={() => pickColor(color)}>
                                    <Square size={18} weight='fill' color={annotationColors[color]} />
                                </ColorButton>
                            ))}
                        </Flyout>
                    )}
                </AnimatePresence>
            </div>,
        )}
    </>);
};
