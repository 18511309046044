import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../Store/ProofXStore';
import Collapsable from '../uicontrols/Collapsable';
import { SquareSplitHorizontal, SquareHalf, ArrowsOutLineHorizontal, Exclude, ArrowsClockwise, ChatsCircle, EyeSlash } from '@phosphor-icons/react';
import { ViewerModes } from './ViewerCubit';
import { CompareModes } from '../CompareModes/CompareModesManager';
import AppTool from '../AppBar/AppTool';
import FaderSlider from '../CompareModes/FaderSlider';
import sendGTMEvent from '../GTM';

// #region Styles

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 18px 8px 4px 8px;
    background-color: var(--toolbar-panel-background);
    box-shadow: var(--glass-shadow);
    backdrop-filter: blur(3px);
    z-index: 1;
    margin-top: -1px;
    border-radius: 0 0 15px 15px;
`;

// #endregion

export default observer(function CompareTools() {
    const viewer = useProofXStore((state) => state.viewer);
    const strings = useProofXStore((state) => state.strings);
    const compareModesManager = useProofXStore((state) => state.compareModesManager);
    const annotationManager = useProofXStore((state) => state.annotationManager);

    const toggleAnnotations = () => {
        if (!annotationManager) return;
        if (annotationManager.visible) {
            annotationManager.hideAnnotations();
            sendGTMEvent('Annotatate-Hide-Annotations');
        } else {
            annotationManager.showAnnotations();
            sendGTMEvent('Annotatate-Show-Annotations');
        }
    };

    return (
        <Collapsable column isOpen={viewer.currentMode === ViewerModes.compare && !viewer.longProcessRunning}>
            <Wrapper>
                {/* SIDE BY SIDE */}
                <AppTool small
                    Icon={SquareSplitHorizontal} title={strings.sideBySide}
                    isOn={compareModesManager?.isSideBySideMode && !compareModesManager?.showDifference}
                    onPress={() => {
                        compareModesManager.setShowDifference(false);
                        compareModesManager.activateMode(CompareModes.sideBySide);
                    }}
                />
                {/* SIDE BY SIDE WITH DIFFERENCE */}
                <AppTool small
                    Icon={SquareHalf} title={strings.withDifference}
                    isOn={compareModesManager?.isSideBySideMode && compareModesManager?.showDifference}
                    onPress={() => {
                        compareModesManager.setShowDifference(true);
                        compareModesManager.activateMode(CompareModes.sideBySide);
                    }}
                />
                {/* FADER */}
                <AppTool small
                    Icon={ArrowsOutLineHorizontal} title={strings.fader}
                    isOn={compareModesManager?.isFaderMode}
                    onPress={() => compareModesManager.activateMode(CompareModes.fader)}
                />
                {compareModesManager?.isFaderMode && (
                    <FaderSlider />
                )}
                {/* DIFF */}
                <AppTool small
                    Icon={Exclude} title={strings.diff}
                    isOn={compareModesManager?.isDiffMode}
                    onPress={() => compareModesManager.activateMode(CompareModes.diff)}
                />
                {/* TOGGLE */}
                <AppTool small
                    Icon={ArrowsClockwise} title={strings.toggle}
                    isOn={compareModesManager?.isToggleMode}
                    onPress={() => compareModesManager.activateMode(CompareModes.toggle)}
                />
                {compareModesManager?.isSideBySideMode && !compareModesManager?.showDifference && (
                    <AppTool small
                        Icon={annotationManager?.visible ? EyeSlash : ChatsCircle}
                        title={annotationManager?.visible ? strings.hideAnnotations : strings.showAnnotations}
                        onPress={toggleAnnotations}
                    />
                )}
            </Wrapper>
        </Collapsable>
    );
});
