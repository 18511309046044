import CompareModeCubit from './CompareModeCubit';

export default class SideBySideCubit extends CompareModeCubit {
    assignDependentStages() {
        const { stages: { main: mainStage, compare: compareStage } } = this.store;
        if (!mainStage || !compareStage) return;
        mainStage.setDependentStages([compareStage]);
        compareStage.setDependentStages([mainStage]);
    }

    async loadCompareResult() {
        const { stages: { main: mainStage, compare: compareStage }, compareModesManager, viewer: { mainAsset, compareAsset } } = this.store;
        if (!mainAsset) return;

        const mainUrl = `/api/GetDZIFile/${mainAsset.uid}/${mainAsset.page}/dz.dzi/`;
        await mainStage.loadImage(mainAsset, mainUrl);

        if (compareAsset) {
            const compareUrl = compareModesManager._showDifference
                ? `/api/GetDZICompareResult/${mainAsset.uid}/${mainAsset.page}/${compareAsset.uid}/${compareAsset.page}/side_by_side/`
                : `/api/GetDZIFile/${compareAsset.uid}/${compareAsset.page}/dz.dzi/`;
            await compareStage.loadImage(compareAsset, compareUrl);
            mainStage.zoomToFitScreen();
        }
    }
}
