import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ArrowBendUpLeft, CaretUp, CaretDown, X, XCircle, PaperPlaneRight,
    DotsThreeCircleVertical, Paperclip, Check, UserCircle, CheckCircle,
    Circle,
} from '@phosphor-icons/react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useProofXStore } from '../../Store/ProofXStore';
import { Avatar } from '../../uicontrols/Avatar';
import { StyledButton } from '../../uicontrols/Button';
import Tooltip from '../../uicontrols/Tooltip';
import Editor from '../../uicontrols/CommentEditor/Editor';
import CommentMenu from './CommentMenu';
import HoverClarifier from '../../uicontrols/HoverClarifier';
import ReactionsPicker from '../../uicontrols/ReactionsPicker';
import { FlexMotionRow, FlexRow } from '../../../styles';

const commentStep = 12;

// #region Styles

const Wrapper = styled.div` 
    margin: 0px -8px -8px -8px;
    padding: 8px;
    border-radius: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;            
`;

const CommentFrame = styled.div`
    border: ${({ highlighted }) => highlighted ? '2px solid var(--highlighted-color)' : '1px solid var(--glass-border)'};
    border-radius: 10px;
    font-size: 12px;    
    border-radius: 10px;
    background-color: ${({ complete }) => complete ? 'var(--discussion-comment-complete-background)' : 'var(--discussion-comment-background)'};    
    opacity: ${({ internal }) => internal ? 0.7 : 1};
    box-shadow: ${({ highlighted }) => highlighted ? 'var(--highlighted-shadow)' : '0 6px 6px -4px var(--glass-shadow-color)'};
    display: flex;
    flex-direction: column;
`;

const ExternalUserAvatar = styled(FlexRow)`   
    margin-left: -2px; 
    margin-right: 6px;
`;

const CommentHeader = styled(FlexRow)`
    border-bottom: 1px solid var(--glass-border);
    padding: var(--xs) var(--sm);
    background-color: var(--glass-background);
    border-radius: 10px 10px 0 0;
`;

export const CommentBody = styled(FlexRow)` 
    padding: var(--sm);
`;

const TextContainer = styled.div`
    flex-grow: 1;
    overflow: hidden;
`;

const CommentOwner = styled(TextContainer)`
    font-size: 10px;
`;

const CommentText = styled.div`
    user-select: text;
    overflow-wrap: break-word;
`;

const EditorWrapper = styled.div`
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    padding: 4px;
`;

const AttachmentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 12px;
`;

const AttachmentNameRow = styled(FlexRow)`
`;

const AttachmentIcon = styled.div`
    min-width: 12px
`;

const AttachmentName = styled.div`
    font-size: 10px;
    padding: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;

    :hover {
        color: var(--primary-color);
    }
`;

const AttachmentImage = styled.img.attrs(({ uid }) => ({
    src: `/api/GetCommentAttachmentThumbnail/${uid}`,
}))`
    max-height: 250px;
    max-width: 100%;
    cursor: pointer;
`;

export const CommentFooter = styled(FlexRow)`
    padding: var(--sm);
    justify-content: space-between;
    font-size: 10px
`;

const RepliesContainer = styled(motion.div)`
    padding-left: ${commentStep}px;
    overflow: hidden;   
`;

const Reply = styled.div`
    border: 2px solid var(--primary-color);
    background-color: var(--discussion-comment-background);    
    box-shadow: 0 6px 6px -4px var(--glass-shadow-color);
    border-radius: 10px;
    margin-top: 8px;
    margin-left: ${commentStep}px;
    flex-grow: 1;
    padding: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis;
`;

const MenuButton = styled(FlexMotionRow)`
    justify-content: center;  
    width: 18px;
    height: 18px;
    border-radius: 9px;
    margin-left: 4px;
    cursor: pointer;
`;

const FileInput = styled.input.attrs(props => ({
    type: 'file',
    multiple: false,
    accept: attachmentAcceptTypes,
}))`
    display: none;
`;

const FooterButtonStyle = styled(StyledButton)`
    text-transform: none;
    font-size: 9px;
    padding: 0;
`;

const ProgressBarWrapper = styled.div`
    margin-top: 8px;
    height: 3px;
    display: flex;
    position: relative;
    width: 215px;
    flex-direction: row;
    align-items: start;
    justify-content: start;
`;

const ProgressBar = styled(motion.div).attrs(() => ({ layout: true }))`
    background-color: var(--secondary-color);
    border-radius: 2px;
    height: 3px;
    width: ${({ value }) => value}%;
`;

const UploadError = styled(FlexMotionRow)`
    margin-top: 8px;
    font-family: var(--medium);
    color: var(--error-color);
    background-color: var(--error-background);
    border-radius: 8px;
    padding: 8px;
    transform-origin: top center;
`;

const Icon = styled.div`
    cursor: pointer;
    margin-left: 4px;
`;

// #endregion

// #region subcomponents

export const FooterButton = (props) => {
    return (
        <HoverClarifier>
            <FooterButtonStyle {...props} dontMoveOnTap />
        </HoverClarifier>
    );
};

const UploadProgressBar = ({ value }) => {
    return (
        <ProgressBarWrapper>
            <ProgressBar value={value}
                initial={{ width: 0 }}
                animate={val => ({
                    width: `${val}%`,
                })}
                custom={value}
                transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                }}
            />
        </ProgressBarWrapper>
    );
};

const CompletionCheckbox = ({ commentCubit }) => {
    const strings = useProofXStore((state) => state.strings);
    const isSimpleMode = useProofXStore((state) => state.proofX?.environment?.task?.simpleMode ?? false);
    const isExternalUser = useProofXStore((state) => !!state.proofX?.environment?.externalUser);

    return (!isSimpleMode && (!isExternalUser || commentCubit.ownedByMe) && <>
        {!commentCubit.isComplete
            ? (
                <Tooltip text={strings.markComplete}>
                    <Icon onClick={() => commentCubit.toggleComplete(true)}>
                        <Circle size={14} color='var(--contrast-text)' />
                    </Icon>
                </Tooltip>
            )
            : (
                <Tooltip text={strings.markIncomplete}>
                    <Icon onClick={() => commentCubit.toggleComplete(false)}>
                        <CheckCircle size={14} color='var(--contrast-text)' />
                    </Icon>
                </Tooltip>
            )}
    </>);
};
// #endregion

const attachmentAcceptTypes = '.tiff, .tif, .jpeg, .jpg, .gif, .bmp, .png, .ai, .ps, .eps, .pdf, .7z, .indd, .rar, .zip, .doc, .docx, .xls, .xlsx, .pptx, .ppt, .ppsx, .pps, .pot, .potx, .pub, .dotx, .dot, .rtf, .txt, .htm, .html, .mht, .odt, .fodt, .ods, .fods, .vsd, .odg, .xps';

const repliesVariants = {
    opened: { height: 'auto' },
    closed: { height: 0 },
};

export default observer(function Comment({ cubit, children }) {
    const strings = useProofXStore((state) => state.strings);
    const proofX = useProofXStore((state) => state.proofX);
    const fileInputRef = useCallback((node) => {
        if (node) {
            cubit?.initFileInput(node);
        }
    }, [cubit]);

    const newReplyRef = useCallback((node) => {
        if (node) {
            node.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, []);

    const comment = cubit?.comment;

    return comment && (
        <Wrapper onClick={() => cubit.revealInViewer()}>
            <CommentFrame id={`comment-frame-${cubit.id}`}
                highlighted={cubit.highlighted}
                complete={cubit.comment.isComplete}
                internal={cubit.comment.isInternal}
            >
                <CommentHeader>
                    {cubit.isExternalUser
                        ? <ExternalUserAvatar><UserCircle color={cubit.color} weight='fill' size={26} /></ExternalUserAvatar>
                        : <Avatar size={18} uid={comment.userUid} color={cubit.color} borderSize={2} marginRight={8} />
                    }
                    <CommentOwner>{cubit.userName}</CommentOwner>
                    {cubit.replies.length > 0 && (<>
                        {!cubit.showReplies && (
                            <Tooltip text={strings.showReplies}>
                                <Icon onClick={() => cubit.toggleCollapseReplies()}>
                                    <CaretDown size={14} color='var(--contrast-text)' />
                                </Icon>
                            </Tooltip>
                        )}
                        {cubit.showReplies && (
                            <Tooltip text={strings.hideReplies}>
                                <Icon onClick={() => cubit.toggleCollapseReplies()}>
                                    <CaretUp size={14} color='var(--contrast-text)' />
                                </Icon>
                            </Tooltip>
                        )}
                    </>)}
                    <CompletionCheckbox commentCubit={cubit} />
                </CommentHeader>
                <CommentBody>
                    <TextContainer editMode>
                        {cubit.editMode
                            ? (
                                <EditorWrapper>
                                    <Editor cubit={cubit.editor} />
                                </EditorWrapper>
                            )
                            : (
                                <CommentText dangerouslySetInnerHTML={{ __html: comment.body }} />
                            )
                        }
                    </TextContainer>
                    {(cubit.editable || !cubit.comment?.isProjectDiscussion) && cubit.editMenu && (
                        <CommentMenu cubit={cubit.editMenu} trigger={(
                            <Tooltip text={strings.commentActions}>
                                <HoverClarifier initialOpacity={1} scaleFactor={0.1}>
                                    <MenuButton onClick={() => cubit.toggleEditMenu()}>
                                        <DotsThreeCircleVertical size={18} color='var(--secondary-color)' weight='fill' />
                                    </MenuButton>
                                </HoverClarifier>
                            </Tooltip>
                        )} />
                    )}
                </CommentBody>
                {!cubit.attachment && (
                    <FileInput ref={fileInputRef} onChange={(e) => cubit.uploadAttachment(e.target.files[0])} />
                )}
                {cubit.uploadProgress !== null && <UploadProgressBar value={cubit.uploadProgress} />}

                {/* Upload Error */}
                <AnimatePresence>
                    {cubit.uploadError !== null && (
                        <UploadError
                            initial={{ scaleY: 0 }}
                            animate={cubit.uploadError !== null ? { scaleY: 1 } : { scaleY: 1 }}
                            transition={{
                                duration: 0.25,
                                type: 'spring',
                                bounce: 0.35,
                            }}
                            exit={{ scaleY: 0 }}
                        >
                            <span style={{ flexGrow: 1 }}>{cubit.uploadError}</span>
                            <HoverClarifier initialOpacity={0.5} scaleFactor={0.1}>
                                <XCircle color='var(--error-color)' size={16} weight='fill' onClick={() => cubit.setUploadError(null)} />
                            </HoverClarifier>
                        </UploadError>
                    )}
                </AnimatePresence>

                {/* Attachment */}
                {cubit.attachment && (
                    <AttachmentContainer>
                        <AttachmentNameRow>
                            <AttachmentIcon><Paperclip size={12} weight='bold' color='var(--button-link-color)' /></AttachmentIcon>
                            <Tooltip text={strings.download} style={{ flexGrow: 1, overflow: 'hidden' }}>
                                <AttachmentName onClick={() => cubit.downloadAttachment()}>
                                    {cubit.attachment.name}
                                </AttachmentName>
                            </Tooltip>
                        </AttachmentNameRow>
                        {cubit.attachment.hasImage && (
                            <Tooltip text={strings.view} placement='center' flex style={{ justifyContent: 'center' }}>
                                <AttachmentImage uid={cubit.attachment.uid} onClick={() => cubit.viewAttachment()} />
                            </Tooltip>
                        )}
                    </AttachmentContainer>
                )}
                <CommentFooter>
                    {!cubit.editMode && (<>
                        <ReactionsPicker reactions={cubit.reactions ?? []} onChange={(reaction) => cubit.toggleReaction(reaction)} />
                        {!proofX.isTaskCompleted && (
                            <FooterButton link fontSize={10} onClick={() => cubit.openNewReply()}>
                                <ArrowBendUpLeft size={12} weight='bold' color='var(--button-link-color)' style={{ marginRight: 2 }} />
                                {strings.reply}
                            </FooterButton>
                        )}
                    </>)}
                    {cubit.editMode && (<>
                        <FooterButton link fontSize={10} onClick={() => cubit.cancelEdit()}>
                            <X size={12} weight='bold' color='var(--button-link-color)' style={{ marginRight: 2 }} />
                            {strings.cancel}
                        </FooterButton>
                        <FooterButton link fontSize={10} onClick={() => cubit.doneEditing()}>
                            {strings.save}
                            <Check size={12} weight='bold' color='var(--button-link-color)' style={{ marginLeft: 2 }} />
                        </FooterButton>
                    </>)}
                </CommentFooter>
            </CommentFrame>

            <RepliesContainer
                layout
                variants={repliesVariants}
                initial='closed'
                animate={cubit?.showReplies ? 'opened' : 'closed'}
                exit='closed'
                transition={{
                    duration: 0.25,
                    type: 'spring',
                    bounce: 0.35,
                }}
            >
                {children}
            </RepliesContainer>
            {cubit.showNewReply && (
                <Reply ref={newReplyRef}>
                    <CommentBody>
                        <Editor padding='4px 0' cubit={cubit.replyEditor} />
                    </CommentBody>
                    <CommentFooter>
                        <FooterButton link fontSize={10} onClick={() => cubit.closeNewReply()}>
                            <X size={12} weight='bold' color='var(--button-link-color)' style={{ marginRight: 2 }} />
                            {strings.cancel}
                        </FooterButton>
                        <FooterButton link fontSize={10} onClick={() => cubit.submitReply()}>
                            {strings.send}
                            <PaperPlaneRight size={12} weight='bold' color='var(--button-link-color)' style={{ marginLeft: 2 }} />
                        </FooterButton>
                    </CommentFooter>
                </Reply>
            )}

        </Wrapper>
    );
});
