import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import ModalBase, { Footer, FooterButton } from './ModalBase';
import { useProofXStore } from '../Store/ProofXStore';
import Checkbox from '../uicontrols/Checkbox';

// #region Styles

const Wrapper = styled.div`
    min-width: 300px;
`;
const Title = styled.div`
    font-size: 18px;
    color: var(--contrast-text);
    font-weight: 500;
    margin: 0;
`;
const Body = styled.div`
    padding: 24px 0px;
    max-width: 600px;
    font-size: 14px;
`;

const ChecklistContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
`;

const Prompt = styled.div`
    font-size: 12px;
`;

const Checklist = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: column;

    border: 1px solid var(--input-border);
    border-radius: 8px;
    padding: 12px 8px;
    background-color: var(--glass-background);
    box-shadow: inset 2px 2px 10px var(--glass-shadow-color);
`;

// #endregion

export default observer(function ApprovalModal({ cubit }) {
    const strings = useProofXStore.getState().strings;
    return cubit && (
        <ModalBase cubit={cubit}>
            <Wrapper>
                <Title>{cubit.title}</Title>
                <Body>
                    {cubit.hasCustomCheckbox
                        ? (
                            <Checkbox
                                label={cubit.message}
                                checked={cubit.customCheckboxChecked}
                                style={{ marginBottom: 4 }}
                                onClick={() => cubit.toggleCustomCheckbox()}
                            />
                        )
                        : cubit.message
                    }
                    {cubit.checklist?.length > 0 && (
                        <ChecklistContainer>
                            {cubit.isApproval && (
                                <Prompt>
                                    {strings.checklistRequiredPrompt}
                                </Prompt>
                            )}
                            <Checklist>
                                {cubit.checklist
                                    .map(i => (
                                        <Checkbox key={i.name}
                                            label={i.name}
                                            checked={i.checked}
                                            style={{ marginBottom: 4 }}
                                            labelStyle={{ fontSize: 14 }}
                                            onClick={() => cubit.toggleChecklistItem(i.name)}
                                        />
                                    ))}
                            </Checklist>
                        </ChecklistContainer>
                    )}
                </Body>
                <Footer>
                    <FooterButton link onClick={() => cubit.hide()}>
                        {strings.cancel}
                    </FooterButton>
                    <FooterButton primary disabled={!cubit.isValid} onClick={() => cubit.confirmHandler()}>
                        {cubit.confirmText}
                    </FooterButton>
                </Footer>
            </Wrapper>
        </ModalBase>
    );
});
