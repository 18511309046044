import { CheckSquare, Square } from '@phosphor-icons/react';
import React from 'react';
import styled from 'styled-components';
import HoverClarifier from './HoverClarifier';
import { FlexRow } from '../../styles';

const ActiveZone = styled(FlexRow)`
    flex-shrink: 1;
    cursor: ${({ disabled }) => disabled ? '' : 'pointer'};
`;

const Label = styled.div`
    margin-left: 4px;
`;

export default function Checkbox({ label, checked, color, style, labelStyle, filled, size, disabled, onClick }) {
    return (
        <FlexRow {...{ style }} >
            <ActiveZone {...{ disabled }} onClick={disabled ? () => { } : onClick}>
                <HoverClarifier initialOpacity={1} style={{ display: 'flex' }}>
                    {checked
                        ? <CheckSquare size={size ?? 16} weight='fill' color={color} />
                        : <Square size={size ?? 16} weight={filled ? 'fill' : 'bold'} color={color} />
                    }
                </HoverClarifier>
                {label && <Label style={labelStyle}>{label}</Label>}
            </ActiveZone>
        </FlexRow >
    );
}
