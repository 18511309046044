import { observable, computed, action, makeObservable } from 'mobx';

export const AlertSeverity = {
    info: 'info',
    error: 'error',
};

export default class AlertsStackCubit {
    _alerts = [];

    constructor() {
        makeObservable(this, {
            _alerts: observable,

            alerts: computed,

            addAlert: action,
            dismissAlert: action,
        });
    };

    get alerts() { return this._alerts; }

    addAlert(message, severity, dismissInterval) {
        const uid = Math.random().toString(36).slice(2);
        this._alerts.push({
            uid,
            message,
            severity,
        });
        if (dismissInterval) {
            window.setTimeout(() => this.dismissAlert(uid), dismissInterval);
        }
    }

    dismissAlert(uid) {
        this._alerts = this._alerts.filter(a => a.uid !== uid);
    }
}
