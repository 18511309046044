/* global fabric */

import { action, computed, makeObservable, observable, override } from 'mobx';
import log from '../../ProofX/Logger';
import { useProofXStore } from '../../Store/ProofXStore';
import { ViewerModes } from '../../Viewer/ViewerCubit';
import {
    getDistance,
    getImageDimensions,
    getRotatedDimensions,
    getUnrotatedPoint,
    isMouseOnImage,
    rotatePoint,
    getRotationAngle,
    getRotatedPoint,
} from '../../Viewer/AnnotationLayer/Utils';
import ExtraToolCubit from '../ExtraToolCubit';

export const ImageUnits = {
    inches: 0,
    centimeters: 1,
    pixels: 2,
    millimeters: 3,
};

const options = {
    crossSize: 5,
    rulerColor: '#0dbf13',
    shadowColor: '#08770c',
};
const clickThreshold = 300;

export default class RulerCubit extends ExtraToolCubit {
    _value = null;
    _units = ImageUnits.pixels;
    _lastPointerDownTime = null;
    _points = [];
    _ctrlKeyPressed = false;
    _pointerPosition = { x: 0, y: 0 };
    _lines = [];

    constructor(viewer) {
        super('📐Ruler', ViewerModes.ruler, {
            mouse: {
                'mouse:down': (evt) => this._handleMouseDown(evt),
                'mouse:up': (evt) => this._handleMouseUp(evt),
                'mouse:move': (evt) => this._handleMouseMove(evt),
            },
            keyboard: {
                keydown: (evt) => this._handleKeyDown(evt),
                keyup: (evt) => this._handleKeyUp(evt),
            },
        });
        makeObservable(this, {
            _value: observable,
            _units: observable,

            value: computed,
            units: computed,

            setUnits: action,
            _setValue: action,

            activate: override,
            deactivate: override,
        });
    }

    get value() { return this._value; }
    get units() { return this._units; }
    get store() { return useProofXStore.getState(); }
    get layer() { return this.store.fabricLayer; }

    activate() {
        super.activate();
        this._points = [];
        this._lines = [];
        this._ctrlKeyPressed = false;
        const imgSize = this.store.assets.main?.formatBasedSize;
        this.setUnits(imgSize?.units ?? ImageUnits.pixels);
    }

    deactivate() {
        this._clear();
        super.deactivate();
    }

    rerender(redraw) {
        const layer = this.layer;
        const zoom = layer.getZoom();
        layer.getObjects('rulerPoint').forEach(obj => {
            obj.remove();
        });
        const drawCross = this._drawCross.bind(this);
        const addPoint = this._addPoint.bind(this);

        if (redraw) {
            layer.getObjects('rulerLine').forEach(obj => {
                obj.remove();
            });
            const updatedPoints = this._points.map(point => getRotatedPoint(point));
            this._clear();
            updatedPoints.forEach(point => addPoint(point));
        } else {
            this._points.forEach(point => drawCross(point));
            this._lines.forEach(line => line.set({
                strokeWidth: 1 / zoom,
                strokeDashArray: [5 / zoom, 5 / zoom],
            }));
        }
        this._update();
    }

    _setValue(value) {
        this._value = value;
    }

    setUnits(units) {
        this._units = units;
        this._update();
    }

    // #region Event handlers

    _handleMouseDown(evt) {
        this._lastPointerDownTime = new Date();
    }

    _handleMouseUp(evt) {
        const releaseTime = new Date();
        const delta = releaseTime - this._lastPointerDownTime;
        this._lastPointerDownTime = null;
        const pointer = this.layer.getPointer(evt.e);
        if (delta < clickThreshold && isMouseOnImage(pointer)) {
            if (!evt.e.ctrlKey && this._points.length > 1) {
                this._clear();
            }
            this._addPoint(pointer);
        }
    }

    _handleMouseMove(evt) {
        this._adjustCursor(evt);
        this._pointerPosition = getUnrotatedPoint(this.layer.getPointer(evt.e));
        this._update();
    }

    _handleKeyDown(evt) {
        if (evt.key === 'Control') {
            this._ctrlKeyPressed = true;
        }
        this._update();
    }

    _handleKeyUp(evt) {
        if (evt.key === 'Control' && !evt.ctrlKey) {
            this._ctrlKeyPressed = false;
        }
        this._update();
    }

    // #endregion

    // #region Private methods

    _addPoint(point) {
        log('➕', point);
        const newPoint = getUnrotatedPoint(point);
        this._points.push(newPoint);
        this._drawCross(newPoint);
        const pointsNum = this._points.length;
        if (pointsNum > 1) {
            this._drawLine(this._points[pointsNum - 1], this._points[pointsNum - 2], pointsNum - 2);
        }
        this._calculateValue();
    }

    _drawCross(point) {
        const layer = this.layer;
        const zoom = layer.getZoom();
        const pos = getRotatedDimensions(point, { x: 0, y: 0 }).position;
        const crossSize = options.crossSize / zoom;
        const lineOptions = {
            type: 'rulerPoint',
            strokeWidth: 1 / zoom,
            stroke: options.rulerColor,
            selectable: false,
            hoverCursor: 'crosshair',
            hasControls: false,
        };
        const crossLine1 = new fabric.Line([], lineOptions);
        crossLine1.set({
            x1: pos.x - crossSize,
            y1: pos.y - crossSize,
            x2: pos.x + crossSize,
            y2: pos.y + crossSize,
        });
        const crossLine2 = new fabric.Line([], lineOptions);
        crossLine2.set({
            x1: pos.x - crossSize,
            y1: pos.y + crossSize,
            x2: pos.x + crossSize,
            y2: pos.y - crossSize,
        });
        crossLine1.setShadow({
            color: options.shadowColor,
            blur: 5,
        });
        crossLine2.setShadow({
            color: options.shadowColor,
            blur: 5,
        });
        layer.add(crossLine1);
        layer.add(crossLine2);
        layer.renderAll();
    }

    _drawLine(point1, point2, index, isFloating) {
        const layer = this.layer;
        const zoom = layer.getZoom();
        const angle = getRotationAngle();
        const rotatedFirstPoint = rotatePoint(point1, angle);
        const rotatedSecondPoint = rotatePoint(point2, angle);

        let line = this._lines[index];
        if (!line) {
            line = new fabric.Line([], {
                type: `rulerLine${isFloating ? 'Floating' : ''}`,
                stroke: options.rulerColor,
                selectable: false,
                evented: false,
                hoverCursor: 'crosshair',
                hasControls: false,
            });
            line.setShadow({
                color: options.shadowColor,
                blur: 5,
            });
            layer.add(line);
            this._lines[index] = line;
        }
        line.set({
            type: `rulerLine${isFloating ? 'Floating' : ''}`,
            visible: true,
            strokeWidth: 3 / zoom,
            strokeDashArray: [8 / zoom, 4 / zoom],
            x1: rotatedFirstPoint.x,
            y1: rotatedFirstPoint.y,
            x2: rotatedSecondPoint.x,
            y2: rotatedSecondPoint.y,
        });
    }

    _update() {
        if (this._points.length > 0) {
            const pointsNum = this._points.length;
            if (this._ctrlKeyPressed || pointsNum < 2) {
                this._calculateValue(this._pointerPosition);
                this._drawLine(this._pointerPosition, this._points[pointsNum - 1], pointsNum - 1, true);
            } else {
                this._hideFloatingLine();
                this._calculateValue();
            }
            this.layer.renderAll();
        }
    }

    _calculateValue(point) {
        const points = point ? [...this._points, point] : this._points;

        const displayProps = {
            [ImageUnits.inches]: { unit: 'in', factor: 1 },
            [ImageUnits.centimeters]: { unit: 'cm', factor: 2.54 },
            [ImageUnits.millimeters]: { unit: 'mm', factor: 25.4 },
        };

        const imgSize = this.store.assets.main?.formatBasedSize;
        const imgSizePx = getImageDimensions();
        const ratio = imgSize.width / imgSizePx.x * (displayProps[this._units]?.factor ?? 1);
        const unit = displayProps[this._units]?.unit ?? 'px';

        const distancePx = points.reduce((accumulator, currentValue) => ({
            prevValue: currentValue,
            len: accumulator.len + (accumulator.prevValue
                ? getDistance(accumulator.prevValue, currentValue)
                : 0),
        }), { prevValue: null, len: 0 });

        const distance = (distancePx.len * ratio).toFixed(3);
        this._setValue(`${distance} ${unit}`);
    }

    _clear() {
        this.layer.getObjects('rulerLineFloating').forEach(obj => obj.remove());
        this.layer.getObjects('rulerLine').forEach(obj => obj.remove());
        this.layer.getObjects('rulerPoint').forEach(obj => obj.remove());
        this._points = [];
        this._lines = [];
        this._setValue(null);
        this.layer.renderAll();
    }

    _hideFloatingLine() {
        this.layer.getObjects('rulerLineFloating').forEach(obj => { obj.visible = false; });
    }

    _adjustCursor(evt) {
        const layer = this.layer;
        if (!layer) return;
        layer.defaultCursor = isMouseOnImage(layer.getPointer(evt.e))
            ? 'crosshair'
            : 'default';
    }

    // #endregion
}
