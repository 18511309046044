import { observable, computed, action, makeObservable } from 'mobx';
import UsersDropdownMenuCubit from './UsersDropdownMenuCubit';
import { useProofXStore } from '../../Store/ProofXStore';

export default class EditorCubit {
    // #region Fields

    _id = null;
    _html = '';
    _text = '';
    _initialHtml = '';
    _usersDropdown = null;
    _savedCursorPosition = null;
    _editorElement = null;
    _shouldSelectAll = false;
    _onSubmit = null;
    _onCancel = null;
    _omitAutoFocus = false;

    // #endregion

    constructor(id, onSubmit, onCancel, omitAutoFocus = false) {
        makeObservable(this, {
            _html: observable,
            _text: observable,
            _initialHtml: observable,
            _usersDropdown: observable,
            _editorElement: observable.ref,
            _shouldSelectAll: observable,

            initialHtml: computed,
            html: computed,
            text: computed,
            usersDropdown: computed,

            initWithHtml: action,
            onInput: action,
            setText: action,
        });
        this._usersDropdown = new UsersDropdownMenuCubit({
            menuOptions: { options: [] },
            onSelectOption: (user) => this._insertUserTag(user),
        });
        this._id = id;
        this._onSubmit = onSubmit;
        this._onCancel = onCancel;
        this._omitAutoFocus = omitAutoFocus;
    };

    // #region Interface

    // #region getters

    get id() { return this._id; }
    get containerId() { return `editor-${this.id}`; }
    get container() { return document.getElementById(this.containerId); }
    get initialHtml() { return this._initialHtml; }
    get html() { return this._html; }
    get text() { return this._text; }
    get usersDropdown() { return this._usersDropdown; }
    get store() { return useProofXStore.getState(); }

    get _allUsers() {
        return this.store.proofX?.environment?.allClientUsers ?? [];
    }

    get _currentUserUid() {
        return this.store.proofX?.environment?.userUid;
    }

    // #endregion

    // #region actions

    initWithHtml(html) {
        this._html = this._initialHtml = html;
        if (this._editorElement) {
            this._editorElement.innerHTML = html;
            this.setText(this._editorElement.innerText);
        }
    }

    setText(text) {
        this._text = text;
    }

    onEditorUpdated(element) {
        this._editorElement = element;
        this.setText(element.innerText);
        if (this._shouldSelectAll) {
            this._selectAllText(element);
            this._shouldSelectAll = false;
        } else if (!this._omitAutoFocus) {
            element.focus();
        }
        element.addEventListener('paste', (e) => this._pasteTextOnly(e));
    }

    _pasteTextOnly(event) {
        // Prevent the default action
        event.preventDefault();

        // Get the copied text from the clipboard
        const text = event.clipboardData
            ? (event.originalEvent || event).clipboardData.getData('text/plain')
            : window.clipboardData // for IE
                ? window.clipboardData.getData('Text')
                : '';

        // Insert text at the current position of caret
        const range = document.getSelection().getRangeAt(0);
        range.deleteContents();

        const textNode = document.createTextNode(text);
        range.insertNode(textNode);
        range.selectNodeContents(textNode);
        range.collapse(false);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        this._html = this._editorElement.innerHTML;
        this.setText(this._editorElement.innerText);
    }

    selectAll() {
        if (!this._editorElement || !document.body.contains(this._editorElement)) {
            this._shouldSelectAll = true;
            return;
        };
        this._selectAllText(this._editorElement);
    }

    onInput(evt) {
        this._html = evt.target.innerHTML;
        this._text = evt.target.innerText;
        this._checkForUserTags();
    }

    onKeyDown(e) {
        if (this._usersDropdown?.isOpen) {
            switch (e.keyCode) {
                case 13: // enter
                case 9: // tab
                    this._insertUserTag(this._usersDropdown.highlightedOption);
                    this._stopEvent(e);
                    break;
                case 27: // esc
                    this._usersDropdown.close();
                    break;
                case 38: // up arrow
                    this._usersDropdown.highlightPreviousOption();
                    this._stopEvent(e);
                    break;
                case 40: // down arrow
                    this._usersDropdown.highlightNextOption();
                    this._stopEvent(e);
                    break;
            }
        } else {
            if (e.keyCode === 13 && !e.shiftKey) { // enter
                this._stopEvent(e);
                this._onSubmit();
                // this._textBoxCubit.toggleEditMode(false);
            }
            if (e.keyCode === 27) { // esc
                this._onCancel();
                // this._textBoxCubit.cancelEdit();
            }
        }
    }

    // #endregion

    // #region private methods

    _checkForUserTags() {
        const query = this._getUserQuery();
        if (!query) {
            this._usersDropdown.close();
            return;
        }
        const usersList = this._filterUsersByQuery(query);
        if (usersList.length > 0) {
            this._usersDropdown.open();
            this._usersDropdown.highlightOption(0);
        } else {
            this._usersDropdown.close();
        }
    }

    _getUserQuery() {
        try {
            const sel = document.getSelection();
            if (sel?.anchorNode?.parentElement.id !== this.containerId) {
                return null;
            }
            const cursorPosition = this._savedCursorPosition = this._getCurrentCursorPosition();

            if (!cursorPosition) {
                return null;
            }
            const text = this._text.substring(0, cursorPosition);
            const regex = /[\S\s]*(@[^@]*)$/;
            let query = null;
            const m = regex.exec(text);
            m && m.forEach((match, groupIndex) => {
                if (groupIndex === 1) {
                    query = match;
                }
            });
            return query?.toLowerCase() ?? null;
        } catch (error) {
            console.error(error);
        }
    }

    _filterUsersByQuery(query) {
        this._usersDropdown.clear();
        const currentUserUid = this._currentUserUid;

        const usersToInclude = [];
        this._allUsers.forEach(user => {
            const [uid, name] = [user.key, user.value.toLowerCase()];
            const nameParts = name.split(' ');
            if (uid !== currentUserUid) {
                let userMatches = false;
                nameParts.forEach(np => {
                    if (np.indexOf(query.substring(1)) === 0) userMatches = true;
                });
                if (userMatches) {
                    usersToInclude.push(user);
                    this._usersDropdown.addOption({ key: user.key, value: user.value });
                }
            }
        });
        return usersToInclude;
    }

    _insertUserTag(user) {
        if (!this._usersDropdown?.isOpen || !user) return;
        this._usersDropdown.close();

        let cursorPosition = this._getCurrentCursorPosition();
        if (cursorPosition < 0) {
            cursorPosition = this._savedCursorPosition;
        }

        this._setCurrentCursorPosition(cursorPosition);

        const query = this._getUserQuery();
        if (!query) return;

        let updatedHtml = '';
        let userTag = `<span class='user-tag' contenteditable='false' data-uid="${user.key}">${user.value}</span> `;
        if (cursorPosition === 1 && navigator.userAgent.indexOf('Firefox') !== -1) userTag = userTag + '&nbsp';
        updatedHtml = this.container.innerHTML.replace('@', userTag);
        this.container.innerHTML = this._html = updatedHtml;
        this._setCurrentCursorPosition(cursorPosition - query.length + user.value.length + 1);
        this._savedCursorPosition = null;
    }

    _selectAllText(element) {
        const range = document.createRange();
        range.selectNodeContents(element);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    // #region  utility methods

    _getCurrentCursorPosition() {
        const selection = document.getSelection();

        if (!selection.focusNode || !this._isChild(selection.focusNode)) return -1;

        let charCount = selection.focusOffset;
        let node = selection.focusNode;

        while (node) {
            if (node.id === this.containerId) {
                break;
            }

            if (node.previousSibling) {
                node = node.previousSibling;
                charCount += node.textContent.length;
            } else {
                node = node.parentNode;
                if (node === null) {
                    break;
                }
            }
        }

        return charCount;
    };

    _setCurrentCursorPosition(chars) {
        if (chars >= 0) {
            const selection = document.getSelection();
            const range = this._createRange(this.container, { count: chars });
            if (range) {
                range.collapse(false);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    };

    _isChild(node) {
        while (node !== null) {
            if (node.id === this.containerId) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    };

    _createRange(node, chars, range) {
        if (!range) {
            range = document.createRange();
            range.selectNode(node);
            range.setStart(node, 0);
        }

        if (chars.count === 0) {
            range.setEnd(node, chars.count);
        } else if (node && chars.count > 0) {
            if (node.nodeType === Node.TEXT_NODE) {
                if (node.textContent.length < chars.count) {
                    chars.count -= node.textContent.length;
                } else {
                    range.setEnd(node, chars.count);
                    chars.count = 0;
                }
            } else {
                for (let lp = 0; lp < node.childNodes.length; lp++) {
                    range = this._createRange(node.childNodes[lp], chars, range);

                    if (chars.count === 0) {
                        break;
                    }
                }
            }
        }

        return range;
    };

    _stopEvent(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    // #endregion

    // #endregion

    // #endregion
}
