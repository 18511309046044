import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import ModalBase, { Footer, FooterButton } from './ModalBase';
import { useProofXStore } from '../Store/ProofXStore';
import parse from 'html-react-parser';

// #region Styles

const Wrapper = styled.div`
    min-width: 300px;
`;
const Body = styled.div`
    max-width: 600px;
    font-size: 14px;
`;
// #endregion

export default observer(function SuccessMessageModal({ cubit }) {
    const strings = useProofXStore.getState().strings;
    return cubit && (
        <ModalBase cubit={cubit}>
            <Wrapper>
                <Body>
                    {parse(cubit.message)}
                </Body>
                {cubit.isCloseable && (
                    <Footer>
                        <FooterButton link onClick={() => cubit.hide()}>
                            {strings.ok}
                        </FooterButton>
                    </Footer>
                )}
            </Wrapper>
        </ModalBase>
    );
});
