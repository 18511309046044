import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import ModalBase, { Footer, FooterButton } from './ModalBase';
import { useProofXStore } from '../Store/ProofXStore';
import { FlexRow } from '../../styles';
import { Input } from '../uicontrols/Input';

// #region Styles

const Wrapper = styled.div``;
const Title = styled.div`
    font-size: 18px;
    color: var(--contrast-text);
    font-weight: 500;
    margin: 0;
`;
const Body = styled.div`
    padding: 24px 0px;
    max-width: 600px;
    font-size: 13px;
`;

const Form = styled.form.attrs(() => ({
    autoComplete: 'off',
}))`
    display: flex;
    flex-grow: 1;
`;

// #endregion

export default observer(function ExternalUserIntroModal({ cubit }) {
    const strings = useProofXStore.getState().strings;
    const inputRef = useCallback((node) => {
        if (node) {
            node.focus();
        }
    }, []);

    return cubit && (
        <ModalBase cubit={cubit}>
            <Wrapper>
                <Title>{strings.introduceYourself}</Title>
                <Body>
                    <FlexRow>
                        <Form onSubmit={(e) => cubit.confirmHandler(e)}>
                            <Input grow
                                ref={inputRef}
                                autoComplete="name"
                                placeholder={strings.enterYourName}
                                value={cubit.name}
                                onChange={(e) => cubit.setName(e.target.value)}
                            />
                        </Form>
                    </FlexRow>
                </Body>
                <Footer>
                    <FooterButton primary disabled={!cubit.valid}
                        onClick={cubit.valid ? () => cubit.confirmHandler() : () => {}}
                    >
                        {strings.confirm}
                    </FooterButton>
                </Footer>
            </Wrapper>
        </ModalBase>
    );
});
