/* global fabric */

import { useProofXStore } from '../../Store/ProofXStore';

export const rotationMatrix = {
    0: { x: 0, y: 0, flip: false },
    90: { x: 1, y: 0, flip: true },
    180: { x: 1, y: 1, flip: false },
    270: { x: 0, y: 1, flip: true },
};

export function rotatePoint(point, angle) {
    angle = normalizeAngle(angle);
    const imageSize = getImageDimensions();
    const origin = new fabric.Point(imageSize.x / 2, imageSize.y / 2);
    const angleRadians = fabric.util.degreesToRadians(angle);
    const fabricPoint = new fabric.Point(point.x, point.y);
    return fabric.util.rotatePoint(fabricPoint, origin, angleRadians);
}

function normalizeAngle(angle) {
    return (angle < 0) ? angle + 360 : (angle >= 360) ? angle - 360 : angle;
}

export function getRotatedDimensions(position, dimensions, backwards, dontFlipDimensions) {
    const angle = normalizeAngle(getRotationAngle() * (backwards ? -1 : 1));
    const rotatedPosition = rotatePoint(position, angle);
    const resDimensions = {
        x: (rotationMatrix[angle].flip && !dontFlipDimensions) ? dimensions.y : dimensions.x,
        y: (rotationMatrix[angle].flip && !dontFlipDimensions) ? dimensions.x : dimensions.y,
    };
    const resPosition = {
        x: rotatedPosition.x - rotationMatrix[angle].x * resDimensions.x,
        y: rotatedPosition.y - rotationMatrix[angle].y * resDimensions.y,
    };
    return { position: resPosition, dimensions: resDimensions };
}

export function getRotatedImageBounds() {
    const dimensions = getImageDimensions();
    const flip = rotationMatrix[getRotationAngle()].flip;
    const resDimensions = {
        x: flip ? dimensions.y : dimensions.x,
        y: flip ? dimensions.x : dimensions.y,
    };
    const d = {
        x: flip ? (dimensions.x - dimensions.y) / 2 : 0,
        y: flip ? (dimensions.y - dimensions.x) / 2 : 0,
    };
    return {
        lt: {
            x: d.x,
            y: d.y,
        },
        rb: {
            x: resDimensions.x + d.x,
            y: resDimensions.y + d.y,
        },
    };
}

export function getRotationAngle() {
    const store = useProofXStore.getState();
    return store.stages.main.angle;
}

export function getImageDimensions() {
    const store = useProofXStore.getState();
    return store.stages.main?.getImageDimensions() ?? { x: 0, y: 0 };
}

export function getRotatedPoint(point) {
    return rotatePoint(point, getRotationAngle());
}

export function getUnrotatedPoint(point) {
    return rotatePoint(point, -getRotationAngle());
}

export function examinePointCloser(pointFrom, pointTo, previousWinner, previousLength) {
    const distance = pointFrom.distanceFrom(pointTo);
    if (isNaN(previousLength)) {
        previousLength = distance;
        previousWinner = pointFrom;
    }
    if (previousLength > distance) {
        return {
            point: pointFrom,
            len: distance,
        };
    }
    return {
        point: previousWinner,
        len: previousLength,
    };
}

export function isMouseOnImage(pointer) {
    const dimensions = getImageDimensions();
    const angle = getRotationAngle();
    const flip = rotationMatrix[angle].flip;
    const resDimensions = {
        x: flip ? dimensions.y : dimensions.x,
        y: flip ? dimensions.x : dimensions.y,
    };
    const d = {
        x: flip ? (dimensions.x - dimensions.y) / 2 : 0,
        y: flip ? (dimensions.y - dimensions.x) / 2 : 0,
    };
    const m = {
        x: pointer.x - d.x,
        y: pointer.y - d.y,
    };
    return m.x >= 0 && m.x <= resDimensions.x && m.y >= 0 && m.y <= resDimensions.y;
}

export function getDistance(point1, point2) {
    return Math.hypot(point2.x - point1.x, point2.y - point1.y);
}

export function getRandomColor(seed, isDarkMode = false) {
    const hash = (seed + '').split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
    return `hsl(${hash % 360}, 50%, ${isDarkMode ? 70 : 30}%)`;
}

export function generateTempUid() {
    return Math.random().toString(36).substring(2, 9);
}

export function validateEmail(email) {
    if (!email) return false;
    const emailRegex = /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])([;,])?)+$/i;
    return emailRegex.test(email.toLowerCase());
}
