import { observable, computed, makeObservable, override, action } from 'mobx';
import ModalCubit from './ModalCubit';

export default class SuccessMessageModalCubit extends ModalCubit {
    _message = '';
    _isCloseable = false;

    constructor() {
        super();
        makeObservable(this, {
            _message: observable,
            _isCloseable: observable,

            message: computed,
            isCloseable: computed,

            _setMessage: action,
            _setIsCloseable: action,

            show: override,
        });
    }

    get message() { return this._message; }
    get isCloseable() { return this._isCloseable; }

    _setMessage(message) { this._message = message; }
    _setIsCloseable(isCloseable) { this._isCloseable = isCloseable; }

    show(message, isCloseable) {
        this._setMessage(message);
        this._setIsCloseable(isCloseable);
        this._closeOnBackdropClick = isCloseable;
        super.show();
    }
}
