import { observable, computed, action, makeObservable } from 'mobx';

export default class DropdownMenuCubit {
    // #region Fields

    _isOpen = false;
    _controllerCubit = null;

    // #endregion

    constructor(controllerCubit) {
        makeObservable(this, {
            _isOpen: observable,

            isOpen: computed,
            controller: computed,

            open: action,
            close: action,
            toggle: action,
        });
        this._controllerCubit = controllerCubit;
    };

    // #region Interface

    // #region getters

    get isOpen() { return this._isOpen; }
    get controller() { return this._controllerCubit; }

    // #endregion

    // #region actions

    open() {
        this._isOpen = true;
    }

    close() {
        this._isOpen = false;
    }

    toggle() {
        this._isOpen = !this._isOpen;
    }

    // #endregion

    // #endregion

    // #region private methods

    // #endregion
}
