import CompareModeCubit from './CompareModeCubit';

export default class FaderCubit extends CompareModeCubit {
    assignDependentStages() {
        const { stages: { main: mainStage, compare: compareStage, middle: middleStage } } = this.store;
        if (!mainStage || !compareStage || !middleStage) return;
        compareStage.setDependentStages([mainStage, middleStage]);
        compareStage.zoomToFitScreen();
        compareStage.synchronizeDependentStages();
    }

    async loadCompareResult() {
        const {
            compareModesManager,
            stages: { main: mainStage, compare: compareStage, middle: middleStage },
            viewer: { mainAsset, compareAsset },
        } = this.store;

        if (!mainAsset || !compareAsset) return;

        const mainUrl = `/api/GetDZIFile/${mainAsset.uid}/${mainAsset.page}/dz.dzi/`;
        const differenceUrl = `/api/GetDZICompareResult/${mainAsset.uid}/${mainAsset.page}/${compareAsset.uid}/${compareAsset.page}/fader/`;
        const compareUrl = `/api/GetDZIFile/${compareAsset.uid}/${compareAsset.page}/dz.dzi/`;

        await Promise.all([
            mainStage.loadImage(mainAsset, mainUrl),
            middleStage.loadImage(compareAsset, differenceUrl),
            compareStage.loadImage(compareAsset, compareUrl),
        ]);

        compareModesManager?.updateFader();
    }
}
