import { observable, computed, action, makeObservable } from 'mobx';
import { api } from '../Api/ProofXApi';

export class WrongLinkCubit {
    constructor() {
        makeObservable(this, {
            _homeUrl: observable,
            homeUrl: computed,

            fetchHomeUrl: action,
        });
    };

    _homeUrl = '';

    get homeUrl() {
        return this._homeUrl;
    }

    fetchHomeUrl() {
        if (!this._homeUrl) {
            api.getConfigValue('HomeUrl')
                .then((json) => { this._homeUrl = (json && json.value) || ''; });
        }
    }
}

const cubit = new WrongLinkCubit();

export default function getWrongLinkCubit() {
    return cubit;
}
