import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLayer } from 'react-laag-v18';
import { FlexRow } from '../../styles';
import { observer } from 'mobx-react-lite';
import { useProofXStore } from '../Store/ProofXStore';
import Button from '../uicontrols/Button';
import Checkbox from '../uicontrols/Checkbox';
import { motion } from 'framer-motion';

const Flyout = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;        
    padding: 24px;  
    font-size: 12px;  
    background-color: var(--tooltip-background);
    box-shadow: var(--glass-shadow-popup);
    transform-origin: center center;
    z-index: 200000;
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
`;

const ListItem = styled.div`
    margin-bottom: 4px;
`;

const Footer = styled(FlexRow)`
    margin-top: 12px;
    justify-content: space-between;
`;

const Prompt = styled(FlexRow)`
    font-family: var(--medium);
    font-size: 14px;
    color: var(--secondary-color);
    margin-bottom: 12px;  
`;

// #endregion

export default observer(function ChecklistConfigurationForm({ checklist, selection, onSave }) {
    const strings = useProofXStore.getState().strings;
    const [open, setOpen] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen: open,
        onOutsideClick: () => setOpen(false), // close the menu when the user clicks outside
        onDisappear: () => setOpen(false), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => setOpen(false),
        overflowContainer: false, // keep the menu positioned outside the container
        auto: true, // automatically find the best placement
        placement: 'top-center', // we prefer to place the menu "top-end"
        possiblePlacements: ['bottom-center', 'top-center'],
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 8, // give the menu some room to breath relative to the container
    });

    useEffect(() => {
        if (open && checklist && selection) {
            setCheckedItems([...selection]);
        }
    }, [open, checklist, selection]);

    const toggleChecklistSelectedItem = useCallback((name) => {
        if (checkedItems.includes(name)) {
            setCheckedItems(checkedItems.filter(i => i !== name));
        } else {
            setCheckedItems([...checkedItems, name]);
        }
    }, [checkedItems]);

    const handleSave = useCallback(() => {
        onSave && onSave(checkedItems);
        setOpen(false);
    }, [checkedItems, onSave]);

    return (<>
        <div {...triggerProps}>
            <Button disabled={!checklist} onClick={() => checklist ? setOpen(true) : {}}>{strings.configure}</Button>
        </div>
        {open && renderLayer(
            <Flyout {...layerProps}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{
                    duration: 0.1,
                    ease: 'easeInOut',
                }}
            >

                <Prompt>{strings.checklistConfigurePrompt}</Prompt>
                <List>
                    {checklist.options.split(';').map((i, k) => (
                        <ListItem key={k}>
                            <Checkbox label={i}
                                color='var(--checkbox-color)'
                                checked={checkedItems.includes(i)}
                                onClick={() => toggleChecklistSelectedItem(i)}
                            ></Checkbox>
                        </ListItem>
                    ))}
                </List>
                <Footer>
                    <Button link onClick={() => setOpen(false)}>{strings.close}</Button>
                    <Button primary onClick={handleSave}>{strings.apply}</Button>
                </Footer>

            </Flyout>,
        )}
    </>);
});
