import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { CaretRight } from '@phosphor-icons/react';

// #region Styles

const IconButton = styled.div`
    position: relative;
    cursor: pointer;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
`;

const MotionDiv = styled(motion.div)`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    color: var(--secondary-color);
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
`;

// #endregion

const variants = {
    open: { rotate: 90 },
    closed: { rotate: 0 },
};

export default function FoldIcon({ isOpen, size, onClick }) {
    return (
        <IconButton {...{ size, onClick }}>
            <MotionDiv {...{ size }}
                animate={isOpen ? 'open' : 'closed'}
                variants={variants}
                transition={{
                    duration: 0.2,
                    ease: 'easeInOut',
                }}
                whileHover={{
                    color: 'var(--primary-color)',
                    transition: { duration: 0.2 },
                }}
            >
                <CaretRight {...{ size }} />
            </MotionDiv>
        </IconButton>
    );
};
