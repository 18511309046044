import {
    Eye, EyeSlash, Paperclip,
    Textbox, Trash,
    X,
} from '@phosphor-icons/react';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLayer } from 'react-laag-v18';
import styled from 'styled-components';
import { FlexRow } from '../../../styles';
import { useProofXStore } from '../../Store/ProofXStore';

const Trigger = styled.div`
    ${({ styles }) => styles}
`;

const Menu = styled(motion.div).attrs(() => ({ layout: true }))`
    border-radius: 8px;
    background-color: var(--popover-background);  
    box-shadow: var(--glass-shadow-popup);
    font-size: 12px;
    display: flex;
    flex-direction: column; 
    transform-origin: top right;
`;

const MenuItem = styled(FlexRow)`
    padding: 6px 12px;

    :hover {
        background-color: var(--highlighted-background);
    }

    :first-child {
        border-radius: 8px 8px 0 0;
        padding-top: 8px;
    }
    :last-child {
        border-radius: 0 0 8px 8px;
        padding-bottom: 8px;
    }
`;

const Icon = styled.div`
    display: flex;
    margin-right: 6px;
`;

const variants = {
    opened: { x: 0, y: 0, opacity: 1, scale: 1 },
    closed: { x: 0, y: 0, opacity: 0, scale: 0 },
};

export default observer(function CommentMenu({ cubit, trigger }) {
    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen: cubit.isOpen,
        onOutsideClick: () => cubit.close(), // close the menu when the user clicks outside
        onDisappear: () => cubit.close(), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => cubit.close(),
        overflowContainer: true, // keep the menu positioned outside the container
        auto: true, // automatically find the best placement
        placement: cubit.placement ?? 'bottom-start', // we prefer to place the menu "top-end"
        possiblePlacements: cubit.possiblePlacements,
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 8, // give the menu some room to breath relative to the container
        // arrowOffset: 16 // let the arrow have some room to breath also
    });
    const strings = useProofXStore(state => state.strings);
    const isNotExternalReview = useProofXStore(state => !state.proofX?.environment?.flags?.isExternalReview);
    const isSimpleMode = useProofXStore((state) => state.proofX?.environment?.task?.simpleMode ?? false);
    const isExternalUser = useProofXStore((state) => !!state.proofX?.environment?.externalUser);
    const commentCubit = cubit?.controller;

    if (!commentCubit.editable &&
        !commentCubit.deletable &&
        (isSimpleMode || isExternalUser || !isNotExternalReview)) {
        return <></>;
    }

    return (<>
        <Trigger {...triggerProps} styles={cubit.triggerStyles ?? ''} className='trigger'>
            {trigger}
        </Trigger>
        {renderLayer(
            <div {...layerProps} onClick={() => { !cubit.stayOnClick && cubit.close(); }}>
                <AnimatePresence>
                    {cubit?.isOpen && (
                        <Menu
                            variants={variants}
                            initial='closed'
                            animate='opened'
                            exit='closed'
                            transition={{
                                delay: 0.02,
                                duration: 0.5,
                                type: 'spring',
                                bounce: 0.35,
                            }}
                        >
                            {commentCubit.editable && <>
                                {!commentCubit.editMode && (
                                    <MenuItem onClick={() => commentCubit.toggleEditMode(true)}>
                                        <Icon><Textbox size={14} color='var(--contrast-text)' /></Icon>
                                        {strings.editText}
                                    </MenuItem>
                                )}
                                {commentCubit.editMode && (
                                    <MenuItem onClick={() => commentCubit.cancelEdit()}>
                                        <Icon><X size={14} color='var(--contrast-text)' /></Icon>
                                        {strings.cancelEditing}
                                    </MenuItem>
                                )}
                                {!commentCubit.attachment && (
                                    <MenuItem onClick={() => commentCubit.chooseAttachmentFile()}>
                                        <Icon><Paperclip size={14} color='var(--contrast-text)' /></Icon>
                                        {strings.attachFile}
                                    </MenuItem>
                                )}
                                {commentCubit.attachment && (
                                    <MenuItem onClick={() => commentCubit.deleteAttachment()}>
                                        <Icon><Paperclip size={14} color='var(--contrast-text)' /></Icon>
                                        {strings.deleteAttachment}
                                    </MenuItem>
                                )}
                            </>}
                            {!isSimpleMode && !isExternalUser && isNotExternalReview && <>
                                {!commentCubit.isInternal && (
                                    <MenuItem onClick={() => commentCubit.toggleInternal(true)}>
                                        <Icon><EyeSlash size={14} color='var(--contrast-text)' /></Icon>
                                        {strings.hideForExternal}
                                    </MenuItem>
                                )}
                                {commentCubit.isInternal && (!commentCubit.parent || !commentCubit.parent?.isInternal) && (
                                    <MenuItem onClick={() => commentCubit.toggleInternal(true)}>
                                        <Icon><Eye size={14} color='var(--contrast-text)' /></Icon>
                                        {strings.unhideForExternal}
                                    </MenuItem>
                                )}
                            </>}
                            {commentCubit.deletable && (
                                <MenuItem onClick={() => commentCubit.deleteComment()}>
                                    <Icon><Trash size={14} color='var(--contrast-text)' /></Icon>
                                    {strings.delete}
                                </MenuItem>
                            )}
                        </Menu>
                    )}
                </AnimatePresence>
            </div>,
        )}
    </>);
});
