/* global fabric, OpenSeadragon */

import { action, computed, makeObservable, observable, override } from 'mobx';
import ExtraToolCubit from '../ExtraToolCubit';
import { ViewerModes } from '../../Viewer/ViewerCubit';
import { getImageDimensions, getRotatedPoint, getUnrotatedPoint, isMouseOnImage } from '../../Viewer/AnnotationLayer/Utils';
import { useProofXStore } from '../../Store/ProofXStore';
import { api } from '../../Api/ProofXApi';

const options = {
    crossSize: 4,
    rectStrokeBase: 1,
    rectColor: '#80b8e4',
};

export default class BarcodeScannerCubit extends ExtraToolCubit {
    _scanResult = null;
    _error = null;
    _hasSnapshot = false;
    _currentPointer = { x: 0, y: 0 };
    _startPoint = null;
    _rect = null;
    _handMode = false;
    _scanning = false;

    constructor() {
        super('🎹 BarcodeScanner', ViewerModes.barcodeScanner, {
            mouse: {
                'mouse:down': (evt) => this._handleMouseDown(evt),
                'mouse:up': (evt) => this._handleMouseUp(evt),
                'mouse:move': (evt) => this._handleMouseMove(evt),
            },
        });
        makeObservable(this, {
            _scanResult: observable,
            _error: observable,
            _hasSnapshot: observable,
            _scanning: observable,
            _handMode: observable,

            scanResult: computed,
            error: computed,
            hasSnapshot: computed,
            scanning: computed,
            handMode: computed,

            _setResult: action,
            _setError: action,
            _setSnapshotExists: action,
            _setScanning: action,
            toggleHandMode: action,

            activate: override,
            deactivate: override,
        });
    }

    get scanResult() { return this._scanResult; }
    get error() { return this._error; }
    get hasSnapshot() { return this._hasSnapshot; }
    get scanning() { return this._scanning; }
    get stage() { return useProofXStore.getState().stages.main; }
    get layer() { return useProofXStore.getState().fabricLayer; }
    get handMode() { return this._handMode; }

    _setResult(scanResult) {
        this._scanResult = scanResult;
    }

    _setError(error) {
        this._error = error;
    }

    _setSnapshotExists() {
        this._hasSnapshot = true;
    }

    _setScanning(scanning) {
        this._scanning = scanning;
    }

    activate() {
        super.activate();
        this._hasSnapshot = false;
        this._scanResult = null;
        this._error = null;
    }

    deactivate() {
        super.deactivate();
    }

    toggleHandMode() {
        this._handMode = !this._handMode;
    }

    _handleMouseDown(evt) {
        this._currentPointer = this.layer.getPointer(evt.e);
        if (!isMouseOnImage(this._currentPointer)) return;

        if (this._handMode) {
            this.stage?.toggleMouseTracker(true);
        } else {
            this.stage?.toggleMouseTracker(false);
            this._startPoint = this._currentPointer;
        }
    }

    _handleMouseUp(evt) {
        if (!this._startPoint) return;
        this._currentPointer = this.layer.getPointer(evt.e);
        if (this._startPoint.x === this._currentPointer.x && this._startPoint.y === this._currentPointer.y) {
            this._clearSelection();
            return;
        };

        this._takeSnapshot();

        this._clearSelection();
        this.stage?.toggleMouseTracker(true);
    }

    _handleMouseMove(evt) {
        this._currentPointer = this.layer.getPointer(evt.e);
        if (this._startPoint) {
            const endPoint = this.layer.getPointer(evt.e);
            this._drawRect(endPoint);
        } else {
            this._adjustCursor();
        };
    }

    _drawRect() {
        const layer = this.layer;
        if (!layer) return;

        const point1 = this._normalizePoint(getUnrotatedPoint(this._startPoint));
        const point2 = this._normalizePoint(getUnrotatedPoint(this._currentPointer));

        const bounds = getImageDimensions();
        const boundedPoint2 = {
            x: Math.min(point2.x, bounds.x),
            y: Math.min(point2.y, bounds.y),
        };

        if (!this._rect) {
            this._rect = new fabric.Rect({
                type: 'barcodeRect',
                strokeWidth: options.rectStrokeBase / layer.getZoom(),
                stroke: options.rectColor,
                fill: options.rectColor + '88',
                selectable: false,
                hoverCursor: 'crosshair',
                hasControls: false,
            });
            layer.add(this._rect);
        }
        const [p1, p2] = [
            getRotatedPoint(point1),
            getRotatedPoint(boundedPoint2),
        ];
        this._rect.set({
            left: Math.min(p1.x, p2.x),
            top: Math.min(p1.y, p2.y),
            width: Math.abs(p1.x - p2.x),
            height: Math.abs(p1.y - p2.y),
        });
        layer.renderAll();
    }

    _clearSelection() {
        this._rect?.remove();
        this._rect = null;
        this._startPoint = null;
        this.layer.renderAll();
    }

    _takeSnapshot() {
        const canvas = document.querySelector('.openseadragon-canvas canvas');

        const p1 = this._normalizePoint(getUnrotatedPoint(this._startPoint));
        const p2 = this._normalizePoint(getUnrotatedPoint(this._currentPointer));
        const dims = {
            x: Math.min(p1.x, p2.x),
            y: Math.min(p1.y, p2.y),
            width: Math.abs(p1.x - p2.x),
            height: Math.abs(p1.y - p2.y),
        };
        const viewport = useProofXStore.getState().stages?.main?.osdViewer?.viewport;
        if (!viewport) return;
        const wp1 = viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(dims.x, dims.y));
        const wp2 = viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(dims.x + dims.width, dims.y + dims.height));
        const wdims = {
            x: Math.min(wp1.x, wp2.x),
            y: Math.min(wp1.y, wp2.y),
            width: Math.abs(wp2.x - wp1.x),
            height: Math.abs(wp2.y - wp1.y),
        };

        const dpr = window.devicePixelRatio || 1;
        if (wdims.width > 0 && wdims.height > 0) {
            const context = canvas.getContext('2d', { willReadFrequently: true });
            const imgData = context.getImageData(wdims.x * dpr, wdims.y * dpr, wdims.width * dpr, wdims.height * dpr);
            this._setSnapshotExists();
            this._setResult(null);
            this._setError(null);
            this._processSnapshot(imgData, wdims.width, wdims.height);
        }
    }

    async _processSnapshot(imgData, width, height) {
        const snapshotCanvas = document.getElementById('barcode-scanner-snapshot');
        const bounds = { width: 250, height: 250 };
        if (!snapshotCanvas || !imgData) return;
        const tempCanvas = document.createElement('canvas');
        const dpr = window.devicePixelRatio || 1;
        tempCanvas.width = width * dpr;
        tempCanvas.height = height * dpr;
        const tempContext = tempCanvas.getContext('2d');
        tempContext.putImageData(imgData, 0, 0);

        const scale = Math.min(
            bounds.width / width / dpr,
            bounds.height / height / dpr,
        );
        snapshotCanvas.width = tempCanvas.width * scale;
        snapshotCanvas.height = tempCanvas.height * scale;

        const context = snapshotCanvas.getContext('2d');
        context.reset();
        context.clearRect(0, 0, snapshotCanvas.width, snapshotCanvas.height);
        context.scale(scale, scale);
        context.drawImage(tempCanvas, 0, 0);

        this._setScanning(true);
        const jsonResult = await api.scanBarcode(tempCanvas.toDataURL());
        const result = JSON.parse(jsonResult?.barcodeScanningResult ?? '{"statusCode": 400}');
        this._setScanning(false);
        if (result?.statusCode === 200) {
            this._setResult(result.result);
        } else {
            this._setError(result?.message ?? 'barcodeServerError');
        }
    }

    _normalizePoint(point) {
        return {
            x: Math.max(0, point.x),
            y: Math.max(0, point.y),
        };
    }

    _adjustCursor() {
        const layer = this.layer;
        if (!layer) return;
        const cursor = isMouseOnImage(this._currentPointer)
            ? (this._handMode ? 'grab' : 'crosshair')
            : 'default';
        layer.defaultCursor = cursor;
        layer.setCursor(cursor);
    }
}
