import { observable, computed, action, makeObservable } from 'mobx';

export default class UsersDropdownMenuCubit {
    // #region Fields

    _isOpen = false;
    _menuOptions = [];
    _stateCubit = null;
    _highlightedOptionIndex = null;
    _placement = null;
    _possiblePlacements = null;
    _stayOnClick = false;
    _handleSelectOption = null;
    _triggerStyles = '';

    // #endregion

    constructor({
        placement,
        possiblePlacements,
        stayOnClick,
        menuOptions,
        triggerStyles,
        onSelectOption,
    }) {
        makeObservable(this, {
            _isOpen: observable,
            _menuOptions: observable.ref,
            _stateCubit: observable.ref,
            _highlightedOptionIndex: observable,
            _handleSelectOption: observable.ref,

            isOpen: computed,
            menuOptions: computed,
            stateCubit: computed,
            highlightedOptionIndex: computed,
            highlightedOption: computed,

            open: action,
            close: action,
            toggle: action,
            clear: action,
            addOption: action,
            highlightOption: action,
            highlightNextOption: action,
            highlightPreviousOption: action,
        });
        this._triggerStyles = triggerStyles ?? '';
        this._placement = placement ?? 'bottom-start';
        this._possiblePlacements = possiblePlacements ?? ['bottom-start', 'bottom-center', 'bottom-end', 'top-start', 'top-center', 'top-end'];
        this._stayOnClick = stayOnClick ?? false;
        this._menuOptions = menuOptions?.options ?? [];
        this._stateCubit = menuOptions?.stateCubit;
        this._handleSelectOption = onSelectOption;
    };

    // #region Interface

    // #region getters

    get isOpen() { return this._isOpen; }
    get placement() { return this._placement; }
    get triggerStyles() { return this._triggerStyles; }
    get possiblePlacements() { return this._possiblePlacements; }
    get menuOptions() { return this._menuOptions; }
    get stateCubit() { return this._stateCubit; }
    get highlightedOptionIndex() { return this._highlightedOptionIndex; }
    get highlightedOption() {
        if (!this._menuOptions || this._menuOptions.length === 0) return null;
        return this._menuOptions[this._highlightedOptionIndex];
    }

    // #endregion

    // #region actions

    open() {
        this._isOpen = true;
    }

    close() {
        this._isOpen = false;
    }

    toggle() {
        this._isOpen = !this._isOpen;
    }

    clear() {
        this._menuOptions = [];
    }

    addOption({ key, value }) {
        this._menuOptions.push({ key, value });
    }

    highlightOption(index) {
        if (this._menuOptions && this._menuOptions.length >= index) {
            this._highlightedOptionIndex = index;
        }
    }

    highlightNextOption() {
        if (!this._menuOptions || this._menuOptions.length === 0) return;

        const currentIndex = this._highlightedOptionIndex;
        if (currentIndex === null || currentIndex === this._menuOptions.length - 1) {
            this._highlightedOptionIndex = 0;
        } else {
            this._highlightedOptionIndex = currentIndex + 1;
        }
    }

    highlightPreviousOption() {
        if (!this._menuOptions || this._menuOptions.length === 0) return;

        const currentIndex = this._highlightedOptionIndex;
        if (currentIndex === null || currentIndex === 0) {
            this._highlightedOptionIndex = this._menuOptions.length - 1;
        } else {
            this._highlightedOptionIndex = currentIndex - 1;
        }
    }

    selectOption(index) {
        this._handleSelectOption && this._handleSelectOption(this._menuOptions[index]);
    }

    // #endregion

    // #region private methods

    // #endregion

    // #endregion
}
