import { CheckCircle, WarningCircle, XCircle } from '@phosphor-icons/react';
import React from 'react';
import styled from 'styled-components';
import { useProofXStore } from '../Store/ProofXStore';
import HoverClarifier from '../uicontrols/HoverClarifier';
import Tooltip from '../uicontrols/Tooltip';
import { observer } from 'mobx-react-lite';
import { FlexRow } from '../../styles';
import ApprovalToolsPanel from './ApprovalToolsPanel';

const Wrapper = styled.div`
    position: relative;
    height: 45px;
    width: ${({ threeButtons }) => threeButtons ? '160px' : '105px'};
    margin: 0 var(--xs);
    padding-top: var(--xs);
`;

const SVGWrapper = styled.div`
    position: absolute;
    left: -34px;
    top: -4px;
    z-index: 10;
    pointer-events: none;
`;

const ButtonsWrapper = styled(FlexRow)`
    position: absolute;
    top: 5px;
    left: 0;
    z-index: 11;
`;

const Button = styled(FlexRow)`
    position: relative;
    margin: 0 var(--xxs);

    &::before {
        position: absolute;
        content: "";
        border-radius: 19px;
        width: 38px;
        height: 38px;
        top: 6px;
        left: 6px;
        z-index: 0;
        background-color: var(--colored-button-text);
    }
`;

const ToolButton = ({ text, Icon, color, disabled, onClick, id }) => {
    return (
        <Button id={id}>
            <Tooltip text={text}>
                <HoverClarifier disabled={disabled} initialOpacity={0.9} scaleFactor={0.1} style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon color={disabled ? 'var(--tool-button-disabled)' : color} weight='fill' size={50} onClick={disabled ? () => { } : onClick} />
                </HoverClarifier>
            </Tooltip>
        </Button>
    );
};

export default observer(function ApprovalTools() {
    const proofX = useProofXStore((state) => state.proofX);
    const strings = useProofXStore((state) => state.strings);
    const isSelectiveApproval = proofX?.isSelectiveApproval ?? false;
    const isApproveWithChanges = proofX?.environment?.approvalSettings?.isApproveWithChanges ?? false;
    const isAnyAssetPageChecked = proofX?.isAnyAssetPageChecked;

    return (
        <>
            <Wrapper threeButtons={isApproveWithChanges} id='approval-tools'>
                <SVGWrapper>
                    <ApprovalToolsPanel
                        width={isApproveWithChanges ? 230 : 175}
                        height={65} upperRadius={30} lowerRadius={30}
                    />
                </SVGWrapper>
                <ButtonsWrapper>
                    <ToolButton id='approve-button'
                        disabled={isSelectiveApproval && !isAnyAssetPageChecked}
                        text={isSelectiveApproval ? strings.approveSelected : strings.approve}
                        color='var(--button-success-background)'
                        Icon={CheckCircle}
                        onClick={() => proofX.handleApprove()}
                    />
                    {isApproveWithChanges && (
                        <ToolButton id='approve-with-changes-button'
                            disabled={isSelectiveApproval && !isAnyAssetPageChecked}
                            text={isSelectiveApproval ? strings.approveWithChangesSelected : strings.approveWithChanges}
                            color='var(--button-warning-background)'
                            Icon={WarningCircle}
                            onClick={() => proofX.handleApproveWithChanges()}
                        />
                    )}
                    <ToolButton id='request-changes-button'
                        disabled={isSelectiveApproval && isAnyAssetPageChecked}
                        text={strings.requestChanges}
                        color='var(--button-danger-background)'
                        Icon={XCircle}
                        onClick={() => proofX.handleRequestChanges()}
                    />
                </ButtonsWrapper>
            </Wrapper>
        </>
    );
});
