import { action, computed, makeObservable, observable, override } from 'mobx';
import { ViewerModes } from '../../Viewer/ViewerCubit';
import ExtraToolCubit from '../ExtraToolCubit';
import { api } from '../../Api/ProofXApi';
import { useProofXStore } from '../../Store/ProofXStore';
import { getUnrotatedPoint, isMouseOnImage } from '../../Viewer/AnnotationLayer/Utils';
import log from '../../ProofX/Logger';
import { colorSchemes, hexToPANTONE, rgbToHEX, rgbToHSL, rgbToHSV, rgbToLAB } from './EyedropperUtils';

export const ColorModels = ['RGB', 'HEX', 'HSL', 'HSV', 'LAB', 'CMYK', 'PANTONE'];

function toPercent(val) {
    const res = (val * 100) / 255;
    return res;
}

export default class EyedropperCubit extends ExtraToolCubit {
    _ready = false;
    _isGettingColor = false;
    _value = null;
    _allColors = {};
    _colorModel = ColorModels[0];
    _areColorsGenerated = false;
    _assetsInProgress = {};

    constructor(viewer) {
        super('🎨 Eyedropper', ViewerModes.eyedropper, {
            mouse: {
                'mouse:down': (evt) => this._handleMouseDown(evt),
                'mouse:move': (evt) => this._handleMouseMove(evt),
            },
        });
        makeObservable(this, {
            _ready: observable,
            _areColorsGenerated: observable,
            _allColors: observable,
            _value: observable,
            _colorModel: observable,
            _assetsInProgress: observable,
            _isGettingColor: observable,

            ready: computed,
            value: computed,
            areColorsGenerated: computed,
            colorModel: computed,
            isGettingColor: computed,

            setReady: action,
            setColorModel: action,
            setColorsGenerated: action,
            generateColors: action,
            _setValue: action,
            _setAllColors: action,
            _setAssetPageInProgress: action,
            _setAssetPageReady: action,
            _setIsGettingColor: action,

            activate: override,
            deactivate: override,
        });
    }

    get value() { return this._value; }
    get areColorsGenerated() { return this._areColorsGenerated; }
    get colorModel() { return this._colorModel; }
    get asset() { return this.store.assets?.main; }
    get layer() { return this.store.fabricLayer; }
    get store() { return useProofXStore.getState(); }
    get isGettingColor() { return this._isGettingColor; }

    get ready() {
        const asset = this.asset;
        return this._ready && asset && !this.isAssetPageInProgress(asset.uid, asset.page);
    }

    async activate() {
        this.setReady(false);
        super.activate();
        this._areColorsGenerated = await api.hasPixelInfoGenerated(this.asset?.uid, this.asset?.page);
        this.setReady(true);
    }

    isAssetPageInProgress(uid, page) {
        const currentPages = this._assetsInProgress[uid] ?? [];
        return currentPages.find(p => p === page) !== undefined;
    }

    _setAssetPageInProgress() {
        const asset = this.asset;
        const currentPages = this._assetsInProgress[asset.uid] ?? [];
        this._assetsInProgress[asset.uid] = [...currentPages, asset.page];
    }

    _setAssetPageReady() {
        const asset = this.asset;
        const currentPages = this._assetsInProgress[asset.uid] ?? [];
        this._assetsInProgress[asset.uid] = currentPages.filter(p => p !== asset.page);
    }

    _setIsGettingColor(isGettingColor) {
        this._isGettingColor = isGettingColor;
    }

    async generateColors() {
        const asset = this.asset;
        if (!asset) return;
        this._setAssetPageInProgress();
        const result = await api.getPixelInfo(asset.uid, asset.page, { x: 0, y: 0 });
        this._setAssetPageReady();
        if (!result) {
            this.store.proofX.showAlert(this.store.strings.failedGenerateColorScheme, 'error');
            console.error('Failed to generate colors for asset', asset);
            return;
        }
        this.setColorsGenerated(true);
    }

    deactivate() {
        super.deactivate();
    }

    setColorModel(colorModel) {
        this._colorModel = colorModel;
        this._setValue(this._allColors[colorModel]?.str ?? '');
    }

    setReady(ready) {
        this._ready = ready;
    }

    setColorsGenerated(areColorsGenerated) {
        this._areColorsGenerated = areColorsGenerated;
    }

    _setValue(value) {
        this._value = value;
    }

    _setAllColors(allColors) {
        this._allColors = allColors;
    }

    _handleMouseDown(evt) {
        const mouse = this.layer.getPointer(evt.e);
        if (!isMouseOnImage(mouse)) return;
        const pointer = getUnrotatedPoint(mouse);
        this._getPixelInfo({
            x: Math.round(Math.max(pointer.x, 0)),
            y: Math.round(Math.max(pointer.y, 0)),
        });
    }

    _handleMouseMove(evt) {
        if (this.ready && this._areColorsGenerated) {
            this._adjustCursor(evt);
        }
    }

    async _getPixelInfo(pointer) {
        const asset = this.asset;
        if (!asset) return;
        this._setValue('');
        this._setIsGettingColor(true);
        const result = await api.getPixelInfo(asset.uid, asset.page, pointer);
        if (!result) {
            this.store.proofX.showAlert(this.store.strings.failedGetPixelInfo, 'error');
            console.error('Failed to get pixel info for asset', asset, pointer);
            return;
        }
        if (asset.uid !== result.assetUID || asset.page !== result.pageNum) return;
        let rgbColor = null;
        let cmykColor = null;

        if (result.colors?.rgb) {
            const rgb = result.colors.rgb;
            rgbColor = colorSchemes.RGB(rgb.r, rgb.g, rgb.b);
        }
        if (result.colors?.cmyk) {
            const cmyk = result.colors.cmyk;
            const c = Math.round(toPercent(cmyk.c));
            const m = Math.round(toPercent(cmyk.m));
            const y = Math.round(toPercent(cmyk.y));
            const k = Math.round(toPercent(cmyk.k));
            cmykColor = colorSchemes.CMYK(c, m, y, k);
        }
        if (rgbColor && cmykColor) {
            const hexColor = rgbToHEX(rgbColor.val);
            this._setAllColors({
                RGB: rgbColor,
                CMYK: cmykColor,
                HSV: rgbToHSV(rgbColor.val),
                HSL: rgbToHSL(rgbColor.val),
                LAB: rgbToLAB(rgbColor.val),
                HEX: hexColor,
                PANTONE: hexToPANTONE(hexColor.val),
            });
            log('🎨', this._allColors);
            this._setValue(this._allColors[this._colorModel]?.str ?? '');
            this._setIsGettingColor(false);
        }
    }

    _adjustCursor(evt) {
        const layer = this.layer;
        if (!layer) return;
        layer.defaultCursor = isMouseOnImage(layer.getPointer(evt.e))
            ? 'crosshair'
            : 'default';
    }
}
