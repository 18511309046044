import { observable, computed, makeObservable, override, action } from 'mobx';
import ModalCubit from './ModalCubit';

export default class ExternalUserIntroModalCubit extends ModalCubit {
    _name = '';
    _confirmHandler = () => {};

    constructor() {
        super();
        makeObservable(this, {
            _name: observable,
            _confirmHandler: observable,

            name: computed,
            valid: computed,

            setName: action,
            confirmHandler: action,

            show: override,
        });
        this._closeOnBackdropClick = false;
    }

    get name() { return this._name; }
    get valid() { return this._name.length > 0; }

    confirmHandler(e) {
        if (e) e.preventDefault();

        this._confirmHandler(this._name);
        this.hide();
    }

    show(onNameObtained) {
        this._confirmHandler = onNameObtained;
        super.show();
    }

    setName(name) {
        this._name = name;
    }
}
