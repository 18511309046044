import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import ModalBase, { Footer as FooterBase, FooterButton } from './ModalBase';
import { useProofXStore } from '../Store/ProofXStore';
import { FlexRow } from '../../styles';
import SelectBox from '../uicontrols/SelectBox';
import { ArrowClockwise, Translate, WarningCircle } from '@phosphor-icons/react';
import SimpleBar from 'simplebar-react';
import HTMLString from 'react-html-string';
import Button from '../uicontrols/Button';
import Spinner from '../uicontrols/Spinner';
import Word from './SpellcheckerWord';

// #region Styles

const Wrapper = styled.div``;

const Header = styled(FlexRow)`
    font-size: 18px;
    color: var(--contrast-text);
    font-weight: 500;
    margin: 0;
    justify-content: space-between;
`;

const GrowColumn = styled.div`
    flex-grow: 1;
    padding-right: 12px;
`;

const Body = styled.div`
    padding: 24px 0px;
    min-width: 800px;
    max-width: 1500px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const WaitingBlock = styled(FlexRow)`
    justify-content: center;
`;

const SpellChecker = styled.div`
border: 1px solid var(--input-border-color);
border-radius: 8px;
    background-color: var(--transparent-dimmed);
    box-shadow: inset 2px 2px 10px var(--glass-shadow-color);
    padding: 12px 8px 12px 24px;
`;

const Content = styled.code`
    padding-right: 16px;
    white-space: pre;
`;

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ErrorMessage = styled(FlexRow)`
    color: var(--error-color);
    font-size: 14px    
`;

const RetryBtnContainer = styled(FlexRow)`
    margin-top: 24px;
`;

const Footer = styled(FooterBase)`
    justify-content: end;
`;

const components = {
    span: props => <Word {...props} />,
};

export default observer(function SpellcheckerModal({ cubit }) {
    const strings = useProofXStore.getState().strings;

    return cubit && (
        <ModalBase cubit={cubit}>
            <Wrapper>
                <Header>
                    {/* Modal title */}
                    <GrowColumn>
                        {strings.spellchecker}
                    </GrowColumn>

                    {/* Language selector */}
                    {cubit?.languages.length > 0 && !cubit.error && (
                        <SelectBox scroller nowrap
                            options={cubit.languages}
                            value={cubit?.language}
                            Icon={<Translate size={20} weight='bold' style={{ color: 'var(--secondary-color)', marginRight: 8 }} />}
                            valueHolderStyle={{ fontSize: '10px', padding: '4px 6px', marginRight: '8px', minWidth: '150px' }}
                            onChange={(value) => cubit?.setLanguage(value)}
                        />
                    )}
                </Header>
                <Body>
                    {!cubit.error
                        ? (cubit.ready
                            ? (
                                <SpellChecker>
                                    <SimpleBar style={{ maxHeight: '400px' }}>
                                        <Content>
                                            <HTMLString html={cubit.formattedText} components={components} />
                                        </Content>
                                    </SimpleBar>
                                </SpellChecker>
                            )
                            : (
                                <WaitingBlock>
                                    <Spinner size={20} style={{ marginRight: 8 }}/>
                                    {strings.processing}
                                </WaitingBlock>
                            ))
                        : (
                            <ErrorContainer>
                                <ErrorMessage>
                                    <WarningCircle weight='bold' size={20} style={{ marginRight: 8 }}/>
                                    {strings[cubit.error] ?? strings.spellcheckerServiceError}
                                </ErrorMessage>
                                <RetryBtnContainer>
                                    <Button danger icon={<ArrowClockwise size={14} style={{ marginRight: 8 }}/>}
                                        onClick={() => cubit.load()}>
                                        {strings.retry}
                                    </Button>
                                </RetryBtnContainer>
                            </ErrorContainer>
                        )}

                </Body>
                <Footer>
                    <FooterButton link noPadding onClick={() => cubit.hide()}>
                        {strings.close}
                    </FooterButton>
                </Footer>
            </Wrapper>
        </ModalBase>
    );
});
