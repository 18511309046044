/* global OpenSeadragon */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { observable, computed, action, makeObservable } from 'mobx';
import log from '../ProofX/Logger';
import { useProofXStore } from '../Store/ProofXStore';
import ZoomToolbar from './ZoomToolbar';
import TextBoxUnderlay from './AnnotationLayer/TextBox/TextBoxUnderlay';
import { ViewerModes } from './ViewerCubit';
import PercentageIndicator from './PercentageIndicator';
import sendGTMEvent from '../GTM';

export const StageTypes = {
    main: 'main',
    compare: 'compare',
    middle: 'middle',
};

export default class StageCubit {
    // #region Fields

    _asset = null;
    _hasFabricLayer = false;
    _osdViewer = null;
    _overlay = null;
    _isTileLoaded = false;
    _container = null;
    _error = null;
    _stageType = null;
    _imagingHelper = null;
    _angle = 0;
    _zoom = 1;
    _resizeHandler = this._onResize.bind(this);
    _dependentStages = [];
    _syncPanZoom = true;
    _percentage = 0;
    _showControls = true;
    _forceZoom = false;
    _controlsCreated = false;

    // #endregion

    constructor(containerElement, stageType, showControls) {
        this._stageType = stageType;
        this._container = containerElement;
        log('✨ Creating stage with name ' + this.stageName);
        makeObservable(this, {
            _asset: observable.ref,
            _osdViewer: observable.ref,
            _container: observable.ref,
            _imagingHelper: observable.ref,
            _isTileLoaded: observable,
            _showControls: observable,
            _zoom: observable,
            _error: observable,

            osdViewer: computed,
            container: computed,
            isTileLoaded: computed,
            percentage: computed,
            error: computed,

            loadImage: action,
            _setAsset: action,
            _setTileLoaded: action,
            _setRotation: action,
            _setError: action,
            _onZoom: action,
        });
        this._showControls = showControls;
    };

    // #region Interface

    // #region getters

    get stageName() { return this._stageType + 'Stage'; }
    get stageType() { return this._stageType; }
    get osdViewer() { return this._osdViewer; }
    get container() { return this._container; }
    get tiledImage() { return this._osdViewer?.world.getItemAt(0); }
    get isTileLoaded() { return this._isTileLoaded; }
    get percentage() { return `${Math.round(this._zoom * 100)}%`; }
    get zoom() { return this._zoom; }
    get angle() { return this._angle; }
    get error() { return this._error; }
    get store() { return useProofXStore.getState(); }
    get viewport() { return this._osdViewer?.viewport; }

    // #endregion

    // #region setters

    _setAsset(newAsset) { this._asset = newAsset; }
    _setContainer(element) { this._container = element; }
    _setOsdViewer(osd) { this._osdViewer = osd; }
    _setTileLoaded(isLoaded) { this._isTileLoaded = isLoaded; }
    _setRotation(angle) { this._angle = angle; }
    _setError(message) { this._error = message; }
    _setSyncPanZoom(isOn) { this._syncPanZoom = isOn; }
    _setZoom(zoom) { this._zoom = zoom; }
    setDependentStages(stages) { this._dependentStages = stages ?? []; }

    // #endregion

    // #region actions

    loadImage(asset, url) {
        return new Promise((resolve) => {
            this._setTileLoaded(false);
            this._initializeViewer();
            this._asset = asset;
            this._controlsCreated = false;
            log('🖼️ Loading image for stage ' + this.stageName, url);
            this._container.style.visibility = 'hidden';

            this._osdViewer.open({
                tileSource: url,
                success: () => {
                    this.tiledImage?.addHandler('fully-loaded-change', ({ fullyLoaded }) => {
                        if (!fullyLoaded || this._controlsCreated) return;
                        this._container.style.visibility = 'visible';
                        this._createImageControls();
                        this._osdViewer?.setMouseNavEnabled(true);
                        this._setTileLoaded(true);
                        this._onZoom();
                        this._controlsCreated = true;
                        resolve();
                    });
                },
            });
        });
    };

    getImageDimensions() {
        const image = this.tiledImage;
        return image ? image.getContentSize() : { x: 0, y: 0 };
    }

    toggleMouseTracker(isOn) {
        this._osdViewer.setMouseNavEnabled(isOn);
        this._osdViewer.outerTracker.setTracking(isOn);
        this._osdViewer.zoomPerScroll = isOn ? 1.2 : 1;
        this._osdViewer.altControls.element.style.display = isOn ? 'block' : 'none';
    }

    createAnnotationLayer() {
        log('🎨 Creating annotation layer for stage ' + this.stageName);
        const osd = this._osdViewer;
        const imageSize = this.getImageDimensions();
        if (imageSize.x === 0 || imageSize.y === 0) return;
        const overlay = osd.fabricjsOverlay({
            scale: imageSize.x,
            onZoom: () => this._onZoom(),
            onPan: () => this._onPan(),
        });
        osd.overlaysContainer.style.zIndex = 10000;
        osd.overlaysContainer.style.position = 'absolute';
        osd.innerTracker.keyDownHandler = null;
        osd.innerTracker.keyHandler = null;
        addEventListener('resize', this._resizeHandler);
        this._overlay = overlay;
        useProofXStore.setState({ fabricLayer: this._overlay.fabricCanvas() });
        this.store.annotationManager?.initializeForAsset(this._asset, this);
        this._hasFabricLayer = true;
        this._createTextBoxUnderlay();
        this._onResize();
    }

    zoomToFitScreen() {
        const viewport = this._osdViewer.viewport;
        const homeZoom = viewport.getHomeZoom();
        const homeZoomByImgCoords = Math.min(viewport.viewportToImageZoom(homeZoom), 1);
        viewport.fitBounds(viewport.getHomeBounds(), true);
        viewport.zoomTo(viewport.imageToViewportZoom(homeZoomByImgCoords), viewport.getCenter(true), true);
        viewport.applyConstraints();
        this._forceZoom = true;
        sendGTMEvent('ImageControls-Fit-to-stage');
    }

    synchronizeDependentStages() {
        const {
            _viewportWidth: viewportWidth,
            _viewportHeight: viewportHeight,
            _viewportCenter: viewportCenter,
        } = this._imagingHelper;

        if (this._syncPanZoom) {
            this._dependentStages.forEach(stage => {
                stage._syncronizePanZoom(viewportWidth, viewportHeight, viewportCenter);
            });
        }
    }

    rotate(direction, doNotPropagate) {
        const directionFactor = (direction === 'left') ? -1 : (direction === 'right') ? 1 : 0;
        const image = this._osdViewer.world.getItemAt(0);
        let angle = image.getRotation() + 90 * directionFactor;
        angle = (angle < 0) ? angle + 360 : (angle >= 360) ? angle - 360 : angle;
        image.setRotation(angle, true);
        this._setRotation(angle);
        if (this._hasFabricLayer) {
            this.store.annotationManager?.rerender();
        }
        if (this.store.viewer.currentMode === 'ruler') {
            this.store.viewer.ruler.rerender(true);
        }
        if (!doNotPropagate) {
            this._dependentStages.forEach(stage => {
                stage.rotate(direction, true);
            });
        }
    }

    dispose() {
        this._osdViewer?.destroy();
        this._overlay = null;
        removeEventListener('resize', this._resizeHandler);
    }
    // #endregion

    // #region private methods

    _initializeViewer() {
        if (!this.container) return;
        this._osdViewer?.destroy();
        const osd = OpenSeadragon({
            element: this.container,
            showRotationControl: false,
            showNavigationControl: false,
            showFullPageControl: false,
            preserveImageSizeOnResize: true,
            minZoomImageRatio: 0.3,
            maxZoomPixelRatio: 10,
            mouseNavEnabled: false,
            zoomPerClick: 1,
            animationTime: 0.5,
            subPixelRoundingForTransparency: OpenSeadragon.SUBPIXEL_ROUNDING_OCCURRENCES.ALWAYS,
        });
        this._imagingHelper = osd.activateImagingHelper({
            onImageViewChanged: (opts) => this._onImageViewChanged(opts),
        });
        this._rgbPlugin = osd.rgb();
        osd.addHandler('animation-finish', () => this._handleFinishPanZoomAnimation());
        this._setOsdViewer(osd);
    }

    _createTextBoxUnderlay() {
        const container = document.createElement('div');
        container.setAttribute('id', 'annotation-underlay-container');
        this._osdViewer.addOverlay({
            element: container,
            location: new OpenSeadragon.Point(0, 0),
        });
        const root = createRoot(container);
        root.render(<TextBoxUnderlay />);
        this._mouseTracker = new OpenSeadragon.MouseTracker({
            userData: 'annotationUnderlay.Tracker',
            element: container,
            preProcessEventHandler: function (eventInfo) {
                switch (eventInfo.eventType) {
                    case 'pointerdown':
                    case 'pointerup':
                    case 'click':
                        eventInfo.stopPropagation = true;
                        eventInfo.preventDefault = false;
                        eventInfo.preventGesture = true;
                        break;
                    default:
                        break;
                }
            },
        });
    }

    _createImageControls() {
        if (!this._showControls) return;

        this._createZoomToolbar();
    }

    _createZoomToolbar() {
        const osd = this._osdViewer;
        osd.altControls = new OpenSeadragon.ButtonGroup({
            buttons: [],
            clickTimeThreshold: osd.clickTimeThreshold,
            clickDistThreshold: osd.clickDistThreshold,
        });
        const toolbar = createRoot(osd.altControls.element);
        osd.altControls.element.style.zIndex = 100001;
        toolbar.render(<ZoomToolbar
            PercentageIndicator={<PercentageIndicator cubit={this} />}
            onRotateLeft={() => this._handleRotateLeft()}
            onRotateRight={() => this._handleRotateRight()}
            onZoomIn={() => this._handleZoomIn()}
            onZoomOut={() => this._handleZoomOut()}
            onZoomToFit={() => this.zoomToFitScreen()}
        />);
        osd.addControl(
            osd.altControls.element,
            { anchor: osd.sequenceControlAnchor || OpenSeadragon.ControlAnchor.BOTTOM_RIGHT },
        );
    }

    _onResize() {
        if (!this._overlay) return;
        this._overlay.resize();
        this._overlay.resizeCanvas();
    }

    _onZoom() {
        const zoom = this._getViewportZoom();
        const isForced = (zoom === this._previousZoom && this._forceZoom);
        if (zoom !== this._previousZoom || isForced) {
            this._setZoom(zoom);
            this.store.annotationManager?.setZoom(this._zoom);
            if (this.store.viewer.currentMode === ViewerModes.ruler) {
                this.store.viewer.ruler.rerender();
            }
            this._previousZoom = zoom;
        }
        if (isForced) {
            this._forceZoom = false;
        }
    }

    _getViewportZoom() {
        const viewport = this._osdViewer.viewport;
        const viewportZoom = viewport.getZoom(true);
        const imageSize = this.getImageDimensions();
        return viewport._containerInnerSize.x * viewportZoom / imageSize.x;
    }

    _onPan() { }

    _handleZoomIn() {
        this._osdViewer.viewport.zoomBy(2);
        this._osdViewer.viewport.applyConstraints();
        sendGTMEvent('ImageControls-Zoom-In');
    }

    _handleZoomOut() {
        this._osdViewer.viewport.zoomBy(0.5);
        this._osdViewer.viewport.applyConstraints();
        sendGTMEvent('ImageControls-Zoom-Out');
    }

    _handleRotateLeft() {
        this.rotate('left');
        sendGTMEvent('ImageControls-Rotate-Left');
    }

    _handleRotateRight() {
        this.rotate('right');
        sendGTMEvent('ImageControls-Rotate-Right');
    }

    _onImageViewChanged() {
        this.synchronizeDependentStages();
        this._onZoom();
    }

    _handleFinishPanZoomAnimation() {
        this._setSyncPanZoom(true);
        this._dependentStages.forEach(stage => {
            stage._setSyncPanZoom(true);
        });
    }

    _syncronizePanZoom(viewportWidth, viewportHeight, viewportCenter) {
        this._setSyncPanZoom(false);
        this._imagingHelper?.setView(
            viewportWidth,
            viewportHeight,
            new OpenSeadragon.Point(viewportCenter.x, viewportCenter.y),
            true);
        this._onZoom();
    }

    // #endregion

    // #endregion
}
