import styled from 'styled-components';
import { FlexRow } from '../../styles';

export const Wrapper = styled.div`
    padding: 8px;
    display: flex;
    flex-direction: column;
`;

export const Header = styled(FlexRow)`    
    font-size: 14px;
    font-family: var(--medium);
    padding: 8px;
    border-bottom: 1px solid var(--discussion-asset-active);
    border-top: 1px solid var(--glass-border);
    background-color: var(--discussion-asset-hover);
    margin-bottom: 8px;
    justify-content: space-between;
`;

export const Column = styled.div`
    display: flex;
    padding: 0 8px;
    flex-direction: column;
`;

export const Row = styled(FlexRow)`
    margin-bottom: 8px;
    font-size: 12px;
`;
