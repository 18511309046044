import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Container = styled(motion.div)`
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
`;

export default function HoverClarifier({ initialOpacity, scaleFactor, tapScaleFactor, duration, style, children, disabled, onClick }) {
    const initialState = { opacity: initialOpacity ?? 0.7, scale: 1 };
    return (
        <Container
            style={style}
            disabled={disabled}
            initial={initialState}
            whileHover={disabled ? initialState : { opacity: 1, scale: 1 + (scaleFactor ?? 0.05) }}
            whileTap={disabled ? initialState : { scale: 1 - (tapScaleFactor ?? scaleFactor ?? 0.05) }}
            transition={{ duration: duration ?? 0.1 }}
            onClick={disabled ? () => { } : onClick}
        >
            {children}
        </Container>
    );
};
