import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { FlexRow } from '../../../styles';
import { useProofXStore } from '../../Store/ProofXStore';

const Wrapper = styled.div`
    min-width: 250px;
    max-width: 500px;
    overflow: auto;
    display: flex;
    user-select: text;
    flex-direction: column;
    height: calc(var(--app-height) - var(--appbar-height) - var(--right-panel-header-height));
    padding: var(--sm);
`;

const InfoItem = styled(FlexRow)`
    font-size: ${({ big }) => big ? 14 : 12}px;
    align-items: start;
    margin-bottom:  ${({ big }) => big ? 12 : 4}px;
    white-space: nowrap;
`;

const InfoKey = styled.div`
    font-family: var(--medium);
    margin-right: 4px;
`;

const ExifInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: var(--sm);
`;

const ExifInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: var(--sm);
`;

const ExifInfoHeader = styled.div`
    font-family: var(--medium);
    margin: 4px 0;
    font-size: 14px;
`;

const FileInfoFormatted = ({ json, onWidthChanged }) => {
    const strings = useProofXStore((state) => state.strings);
    const atomicKeys = Object.keys(json).filter(k => k !== 'exifInfo');
    const exifInfo = json.exifInfo?.map(item => (
        <InfoItem key={item.key}>
            <InfoKey>{item.key}:</InfoKey>{`${item.value}`}
        </InfoItem>
    )) ?? [];

    return [
        ...atomicKeys.map(k => (
            <InfoItem key={k}>
                <InfoKey>{k}</InfoKey>: {`${json[k]}`}
            </InfoItem>
        )),
        exifInfo.length > 0 && (
            <ExifInfoContainer key="exifInfo">
                <ExifInfoHeader>{strings.exifInfo}:</ExifInfoHeader>
                <ExifInfo>
                    {exifInfo}
                </ExifInfo>
            </ExifInfoContainer>
        ),
    ];
};

export default observer(function FileInfoPanel() {
    const cubit = useProofXStore((state) => state.fileInfo);
    const proofX = useProofXStore((state) => state.proofX);
    const strings = useProofXStore((state) => state.strings);
    return (
        <Wrapper>
            {proofX.isMobile && (
                <InfoItem big key={strings.projectName}>
                    <InfoKey>{strings.projectName}</InfoKey><strong>{proofX?.environment?.project?.name}</strong>
                </InfoItem>
            )}
            <FileInfoFormatted json={cubit.fileInfo} />
        </Wrapper>
    );
});
