import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useProofXStore } from '../Store/ProofXStore';
import Stage from '../Viewer/Stage';
import { StageTypes } from '../Viewer/StageCubit';
import { StageContainer } from '../Viewer/Viewer';

export default observer(function DiffLayout() {
    const stageContainerRef = useRef();
    const mainAsset = useProofXStore((state) => state.assets.main);
    const compareAsset = useProofXStore((state) => state.assets.compare);
    if (!mainAsset) return;

    return (
        <StageContainer ref={stageContainerRef}>
            <Stage key={`stage-${mainAsset.uid}-${mainAsset.page ?? 0}-${compareAsset?.uid}-${compareAsset?.page ?? 0}`}
                asset={compareAsset}
                wrapperRef={stageContainerRef}
                stageType={StageTypes.compare}
                showControls={true}
            />
        </StageContainer>
    );
});
