import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { FlexRow } from '../../styles';
import { CaretDown } from '@phosphor-icons/react';
import { useLayer } from 'react-laag-v18';
import { motion } from 'framer-motion';
import SimpleBar from 'simplebar-react';

// #region Styles

const ValueHolder = styled(FlexRow)`
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    flex-grow: ${({ grow }) => grow ? '1' : ''};    
`;

const Value = styled(FlexRow)`
    flex-grow: 1;
    color: ${({ $placeholder }) => $placeholder ? 'var(--placeholder-text)' : ''};
`;

const Menu = styled(motion.div).attrs(() => ({ layout: true }))`
    border-radius: 8px;
    background-color: var(--popover-background);  
    box-shadow: var(--glass-shadow-popup);
    font-size: 12px;
    display: flex;
    max-height: 300px;
    min-width: 100px;
    flex-direction: column; 
    transform-origin: center top;
`;

const Placeholder = styled(FlexRow)`
    color: var(--dimmed-text);
    border-radius: 8px 8px 0 0;
    padding: 8px 12px 6px 12px;
    border-bottom: 1px solid var(--control-border);
`;

const MenuItem = styled(FlexRow)`
    padding: 6px 12px;

    white-space: ${({ nowrap }) => nowrap ? 'nowrap' : 'normal'};

    :hover {
        background-color: var(--highlighted-background);
    }

    ${({ noTitle }) => noTitle
        ? `
        :first-child {
            border-radius: 8px 8px 0 0;
            padding-top: 8px;
        }
    `
        : ''}

    :last-child {
        border-radius: 0 0 8px 8px;
        padding-bottom: 8px;
    }
`;

// #endregion

const variants = {
    opened: { opacity: 1, scale: 1 },
    closed: { opacity: 0, scale: 0 },
};

export default function SelectBox({ options, value, placeholder, Icon, valueHolderStyle, grow, scroller, nowrap, onChange }) {
    const [open, setOpen] = useState(false);
    const refFrame = useRef();

    // how to adjust element width according to the frame width (if it's smaller)

    const close = () => setOpen(false);

    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen: open,
        onOutsideClick: () => close(), // close the menu when the user clicks outside
        onDisappear: () => close(), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => close(),
        overflowContainer: true, // keep the menu positioned outside the container
        auto: true, // automatically find the best placement
        placement: 'bottom-center', // we prefer to place the menu "top-end"
        possiblePlacements: ['bottom-center', 'top-center'],
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 8, // give the menu some room to breath relative to the container
    });

    const MenuContents = (<>
        {placeholder &&
            <Placeholder>
                {placeholder}
            </Placeholder>
        }
        {options.map(option => (
            <MenuItem key={option} noTitle={!placeholder} nowrap={nowrap} onClick={() => onChange(option)}>
                {option}
            </MenuItem>
        ))}
    </>);

    return (<>
        <ValueHolder ref={refFrame} {...triggerProps} grow={grow} style={valueHolderStyle} onClick={() => setOpen(true)}>
            {Icon ?? null}
            <Value $placeholder={!value && placeholder}>{value ?? placeholder}</Value>
            <CaretDown size={18} weight='fill' color='var(--secondary-color)' />
        </ValueHolder>
        {open && renderLayer(
            <Menu
                {...layerProps} onClick={() => close()}
                variants={variants}
                initial='closed'
                animate={open ? 'opened' : 'closed'}
                exit='closed'
                transition={{
                    duration: 0.3,
                    type: 'spring',
                    bounce: 0.35,
                }}
            >
                {scroller
                    ? (
                        <SimpleBar style={{ maxHeight: 300, minWidth: valueHolderStyle?.minWidth ?? 100 }}>
                            {MenuContents}
                        </SimpleBar>
                    )
                    : MenuContents}
            </Menu>,
        )}
    </>);
}
