import { motion } from 'framer-motion';
import styled, { createGlobalStyle } from 'styled-components';
import bgPatternGrayCells from './assets/img/backgrounds/gray-cells.png';
import bgPatternRedCells from './assets/img/backgrounds/red-cells.png';
import bgPatternWhiteCells from './assets/img/backgrounds/white-cells.png';

export const Themes = {
    Light: {
        Name: 'Light',
        defaultBackground: '#8c98d9',
        defaultBackgroundGradient: 'radial-gradient(at 0 0, rgba(255,0,131,0.66) 0, rgba(189,117,155,0.46) 25%, rgba(189,117,155,0.27) 32%, rgba(189,117,155,0.06) 54%, rgba(189,117,155,0) 63%, rgba(189,117,155,0) 100%), radial-gradient(at 100% 100%, #1e2389 0, rgba(87,94,214,0.76) 24%, rgba(87,94,214,0.59) 31%, rgba(55,58,125,0.11) 50%, rgba(55,58,125,0) 60%, rgba(55,58,125,0) 100%), linear-gradient(45deg, #89065e 0, #bf3f94 17%, rgba(198,111,190,1) 30%, rgba(113,188,188,1) 48%, rgba(65,204,204,1) 56%, rgba(40,204,204,1) 60%, #21b4c4 67%, #28a9c9 76%, #4884c4 89%, #255a96 100%);',
        glassBackground: 'rgba(229, 230, 255, 0.8)',
        glassBackgroundLight: '#f9f9fd',
        glassBorder: 'rgba(255, 255, 255, 0.18)',
        glassShadow: '0 8px 18px 0 var(--glass-shadow-color)',
        glassShadowColor: 'rgba(31, 38, 135, 0.4)',
        contrastText: 'rgba(0, 0, 0, 0.8)',
        dimmedText: 'rgba(40, 52, 184, 0.8)',
        transparentDimmed: 'rgba(255, 255, 255, 0.4)',
        errorColor: '#db0000',
        errorBackground: '#FFE5E5',
        waitingBlockBackground: 'rgba(31, 38, 135, 0.1)',
        secondaryColor: '#7982b6',
        primaryColor: '#5E69E7',
        buttonContrastColor: '#ffffff',
        buttonLinkColor: '#7982b6',
        buttonDefaultBackground: '#ffffff',
        buttonSuccessBackground: '#209d1a',
        buttonWarningBackground: '#ccb800',
        buttonDangerBackground: '#c53131',
        buttonAnnotateBackground: '#7982b6',
        coloredButtonText: '#ffffff',
        backgroundGradientOuterColor: '#2e3a4a',
        backgroundGradientMiddleColor: '#51565b',
        backgroundGradientInnerColor: '#545360',
        stageButtonBackground: '#f2f3fd',
        stageButtonColor: '#5e69e7',
        stageButtonActive: '#5e69e7',
        stageButtonActiveColor: '#f2f3fd',
        textboxBackground: 'rgba(229, 230, 255, 0.8)',
        controlBorder: 'rgba(69, 78, 194, 0.3)',
        annotationCompleteBackground: 'rgba(217, 255, 229, 0.7)',
        textboxCompleteBackground: 'rgba(217, 255, 229, 0.7)',
        discussionCommentBackground: '#ffffff',
        discussionCommentCompleteBackground: '#d9ffe5',
        discussionPageBadge: '#E2E5FF',
        discussionAssetActive: 'rgba(31, 38, 135, 0.1)',
        discussionAssetHover: 'rgba(31, 38, 135, 0.05)',
        figureResizerBackground: '#FFEB3B',
        highlightedBackground: '#c4c8ec',
        toolbarPanelBackground: '#c4d8f2',
        toolbarButtonBackground: '#f2f3fd',
        toolbarButtonActiveBackground: '#f2f3fd',
        popoverBackground: '#F5F4FA',
        tooltipBackground: '#edeafb',
        modalShadowColor: '#1f2687',
        modalFooterLinkColor: 'rgba(0, 0, 0, 0.4)',
        modalBackdropColor: 'rgba(31, 35, 56, 0.6)',
        placeholderText: '#777777',
        highlightedColor: '#ffed1a',
        highlightedShadow: '0 0px 8px 2px rgba(255, 237, 26, 0.62)',
        inputBorderColor: 'rgba(69, 78, 194, 0.3)',
        inputBackgroundColor: 'rgba(255, 255, 255, 0.4)',
        alertDefault: '#6f7297aa',
        alertDanger: '#ef8989aa',
        alertDangerText: '#620404',
        alertShadow: '0 0 8px 0 var(--glass-shadow-color)',
        toolButtonDisabled: '#a8aac1',
        checkboxColor: '#7478a6',
        backgroundImg: 'url(/img/wallpaper-proofX-light.png)',
        selectedAssetBorderColor: 'var(--secondary-color)',
        pureBgColor: '#ffffff',
    },
    Dark: {
        Name: 'Dark',
        defaultBackground: '#222426',
        defaultBackgroundGradient: 'radial-gradient(at 0px 0px,rgb(30 6 18 / 66%) 0px,rgb(152 82 119 / 46%) 25%,rgba(189,117,155,0.27) 32%,rgba(189,117,155,0.06) 54%,rgba(189,117,155,0) 63%,rgba(189,117,155,0) 100%) 50% 50%,radial-gradient(at 100% 100%,rgb(4 102 70) 0px,rgb(15 41 76 / 76%) 24%,rgb(8 24 28 / 59%) 31%,rgba(55,58,125,0.11) 50%,rgba(55,58,125,0) 60%,rgba(55,58,125,0) 100%),linear-gradient(45deg,rgb(55 33 48) 0px,rgb(0 0 0) 17%,rgb(0 0 0) 30%,rgb(1 44 44) 48%,rgb(16 68 68) 56%,rgb(0 72 72) 60%,rgb(17 78 70) 67%,rgb(0 51 60) 76%,rgb(7 33 50) 89%,rgb(0 5 10) 100%)',
        glassBackground: 'rgba(58, 57, 76, 0.85)',
        glassBackgroundLight: '#464b69',
        glassBorder: 'rgba(170, 170, 182, 0.2)',
        glassShadow: '0 8px 18px 0 var(--glass-shadow-color)',
        glassShadowColor: 'rgba(0, 0, 0, 0.3)',
        contrastText: 'rgba(255, 255, 255, 0.8)',
        dimmedText: 'rgba(255, 255, 255, 0.35)',
        transparentDimmed: 'rgba(0, 0, 0, 0.4)',
        errorColor: '#f35454',
        errorBackground: '#682929',
        waitingBlockBackground: 'rgba(195, 199, 255, 0.1)',
        secondaryColor: '#8c98d9',
        primaryColor: '#6e79f7',
        buttonContrastColor: 'rgba(255, 255, 255, 0.6)',
        buttonLinkColor: 'rgba(255, 255, 255, 0.6)',
        buttonDefaultBackground: '#464b69',
        buttonSuccessBackground: '#209d1a',
        buttonWarningBackground: '#ccb800',
        buttonDangerBackground: '#c53131',
        buttonAnnotateBackground: '#8c98d9',
        coloredButtonText: '#ffffff',
        backgroundGradientOuterColor: '#18181b',
        backgroundGradientMiddleColor: '#222426',
        backgroundGradientInnerColor: '#24282d',
        textboxBackground: 'rgba(45, 50, 83, 0.8)',
        stageButtonBackground: '#363647',
        stageButtonActive: '#a8b3f0',
        stageButtonActiveColor: '#363647',
        stageButtonColor: '#a8b3f0',
        controlBorder: 'rgba(113, 118, 148, 0.7)',
        annotationCompleteBackground: 'rgba(41, 96, 59, 0.7)',
        textboxCompleteBackground: 'rgba(31, 71, 44, 0.8)',
        discussionCommentBackground: '#464b69',
        discussionCommentCompleteBackground: '#31503b',
        discussionPageBadge: '#343445',
        discussionAssetActive: 'rgba(195, 199, 255, 0.1)',
        discussionAssetHover: 'rgba(60, 66, 151, 0.5)',
        figureResizerBackground: '#FFEB3B',
        highlightedBackground: 'rgba(90, 100, 207, 0.5)',
        toolbarPanelBackground: '#363b4d',
        toolbarButtonBackground: '#464b69',
        toolbarButtonActiveBackground: '#363647',
        popoverBackground: '#252525',
        tooltipBackground: '#252525',
        modalShadowColor: '#101340',
        modalFooterLinkColor: '#7982b6',
        modalBackdropColor: 'rgba(31, 35, 56, 0.6)',
        placeholderText: 'rgba(255, 255, 255, 0.4)',
        highlightedColor: '#ffed1a',
        highlightedShadow: '0 0px 8px 2px rgba(255, 237, 26, 0.62)',
        inputBorderColor: 'rgba(113, 118, 148, 0.7)',
        inputBackgroundColor: 'rgba(0, 0, 0, 0.4)',
        alertDefault: '#5f669b',
        alertDanger: '#b32323',
        alertDangerText: '#ffbbbb',
        alertShadow: '0 0 8px 0 var(--glass-shadow-color)',
        toolButtonDisabled: '#a8aac1',
        checkboxColor: '#7478a6',
        backgroundImg: 'url(/img/wallpaper-proofX-dark.png)',
        selectedAssetBorderColor: 'var(--primary-color)',
        pureBgColor: '#000000',
    },
};

export const GlobalStyle = createGlobalStyle`
    #root {
        height: inherit;
    }

    .glass-surface {
        background-color: var(--glass-background);
        border: 1px solid var(--glass-border);
        border-radius: 10px;
        box-shadow: var(--glass-shadow);
        backdrop-filter: var(--glass-blur);
    }

    .glass-surface-reflected {
        background-color: var(--glass-background);
        border: 1px solid var(--glass-border);
        border-radius: 10px;
        box-shadow: var(--glass-shadow-reflected);
        backdrop-filter: var(--glass-blur);
    }

    .user-tag {
        color: var(--primary-color);
        font-family: var(--medium);
    }

    .datepicker, .datepicker:focus-visible {
        border: none;
        background: transparent;
        outline: none;
        font-family: 'Montserrat';
        color: var(--contrast-text);
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        color: var(--contrast-text);
    };

    .react-datepicker {
        border-radius: 8px;
        box-shadow: var(--glass-shadow);
        border: 1px solid var(--control-border);
        font-family: 'Montserrat';
    }

    .react-datepicker__month-container {
        background-color: var(--glass-background);
        border-radius: 8px;
    }

    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
        color: var(--primary-color);    
    }

    .react-datepicker__year-read-view--down-arrow, 
    .react-datepicker__month-read-view--down-arrow, 
    .react-datepicker__month-year-read-view--down-arrow, 
    .react-datepicker__navigation-icon::before {
        border-color: var(--secondary-color);
    }
    
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, 
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
        border-top-color: var(--glass-background);
    }

    .react-datepicker__header {
        background-color: var(--glass-background);
        border-bottom: 1px solid var(--glass-border);
    }

    .react-datepicker__input-container input::placeholder {
        color: var(--placeholder-text);
        font-family: 'Montserrat';
    }

    .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
        border-radius: 50%;
        background-color: var(--glass-background);
        filter: brightness(1.5);
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        border-radius: 50%;
        background-color: var(--primary-color);
        color: var(--colored-button-text);
        outline: none;
    }

    .react-datepicker__day--selected:focus-visible,
    .react-datepicker__day--in-selecting-range:focus-visible,
    .react-datepicker__day--in-range:focus-visible,
    .react-datepicker__month-text--selected:focus-visible,
    .react-datepicker__month-text--in-selecting-range:focus-visible,
    .react-datepicker__month-text--in-range:focus-visible,
    .react-datepicker__quarter-text--selected:focus-visible,
    .react-datepicker__quarter-text--in-selecting-range:focus-visible,
    .react-datepicker__quarter-text--in-range:focus-visible,
    .react-datepicker__year-text--selected:focus-visible,
    .react-datepicker__year-text--in-selecting-range:focus-visible,
    .react-datepicker__year-text--in-range:focus-visible,
    .react-datepicker__month-text--keyboard-selected:focus-visible,
    .react-datepicker__quarter-text--keyboard-selected:focus-visible,
    .react-datepicker__year-text--keyboard-selected:focus-visible {
        outline: none;
    } 

    [contenteditable=true]:empty:before{
        content: attr(placeholder);
        color: var(--placeholder-text);
        pointer-events: none;
        display: block; /* For Firefox */
    }

    .simplebar-track .simplebar-scrollbar:before {
        background-color: rgba(0, 0, 0, 0.4);
    }

    #layers {
        z-index: 200000;
    }

    body {
        --xxs: 2px;
        --xs: 4px;
        --sm: 8px;
        --md: 16px;
        --lg: 24px;

        --font-size-md: 12px;
        --font-size-lg: 14px;

        --medium: 'Montserrat Medium';
        --app-height: 100%;
        
        --background-gradient-outer-color: ${({ theme }) => (theme.backgroundGradientOuterColor)};
        --background-gradient-middle-color: ${({ theme }) => (theme.backgroundGradientMiddleColor)};
        --background-gradient-inner-color: ${({ theme }) => (theme.backgroundGradientInnerColor)};
        
        --primary-color: ${({ theme }) => (theme.primaryColor)};
        --secondary-color:${({ theme }) => (theme.secondaryColor)};
        
        --glass-background: ${({ theme }) => (theme.glassBackground)};
        --glass-background-light: ${({ theme }) => (theme.glassBackgroundLight)};
        --glass-border: ${({ theme }) => (theme.glassBorder)};    
        --glass-shadow-color: ${({ theme }) => (theme.glassShadowColor)};
        --glass-shadow:  ${({ theme }) => (theme.glassShadow)};
        --glass-shadow-lite: 2px 2px 4px 1px var(--glass-shadow-color);
        --glass-shadow-lite-inset: 2px 2px 4px 0px var(--glass-shadow-color) inset;
        --glass-shadow-popup: 0px 0px 15px 2px var(--glass-shadow-color);
        --glass-blur: blur(3px);
        --textbox-background: ${({ theme }) => (theme.textboxBackground)};
        --textbox-complete-background: ${({ theme }) => (theme.textboxCompleteBackground)};

        --highlighted-color: ${({ theme }) => (theme.highlightedColor)};    
        --highlighted-shadow: ${({ theme }) => (theme.highlightedShadow)};
            
        --contrast-text: ${({ theme }) => (theme.contrastText)};
        --dimmed-text: ${({ theme }) => (theme.dimmedText)};
        --transparent-dimmed: ${({ theme }) => (theme.transparentDimmed)};
        --waiting-block-background: ${({ theme }) => (theme.waitingBlockBackground)};
        --placeholder-text: ${({ theme }) => (theme.placeholderText)}; 

        --button-contrast-color: ${({ theme }) => (theme.buttonContrastColor)}; 
        --button-link-color: ${({ theme }) => (theme.buttonLinkColor)}; 
        --button-default-background: ${({ theme }) => (theme.buttonDefaultBackground)};
        --button-success-background: ${({ theme }) => (theme.buttonSuccessBackground)};
        --button-warning-background: ${({ theme }) => (theme.buttonWarningBackground)};
        --button-danger-background: ${({ theme }) => (theme.buttonDangerBackground)};
        --button-annotate-background: ${({ theme }) => (theme.buttonAnnotateBackground)};
        --colored-button-text: ${({ theme }) => (theme.coloredButtonText)};
        --tool-button-disabled: ${({ theme }) => (theme.toolButtonDisabled)};
        --checkbox-color: ${({ theme }) => (theme.checkboxColor)};
        --stage-button-background: ${({ theme }) => (theme.stageButtonBackground)};
        --stage-button-active: ${({ theme }) => (theme.stageButtonActive)};
        --stage-button-color: ${({ theme }) => (theme.stageButtonColor)};
        --stage-button-active-color: ${({ theme }) => (theme.stageButtonActiveColor)};
        --selected-asset-border-color: ${({ theme }) => (theme.selectedAssetBorderColor)};
        --figure-resizer-background: ${({ theme }) => (theme.figureResizerBackground)};
        --annotation-complete-background: ${({ theme }) => (theme.annotationCompleteBackground)};
        --error-color:${({ theme }) => (theme.errorColor)};
        --error-background:${({ theme }) => (theme.errorBackground)};
        --control-border: ${({ theme }) => (theme.controlBorder)}; 

        --alert-default: ${({ theme }) => (theme.alertDefault)}; 
        --alert-danger: ${({ theme }) => (theme.alertDanger)}; 
        --alert-danger-text: ${({ theme }) => (theme.alertDangerText)};
        --alert-shadow: ${({ theme }) => (theme.alertShadow)};

        --discussion-comment-background: ${({ theme }) => (theme.discussionCommentBackground)};
        --discussion-comment-complete-background: ${({ theme }) => (theme.discussionCommentCompleteBackground)};
        --discussion-page-badge: ${({ theme }) => (theme.discussionPageBadge)};
        --discussion-asset-active: ${({ theme }) => (theme.discussionAssetActive)};
        --discussion-asset-hover:  ${({ theme }) => (theme.discussionAssetHover)};
        --highlighted-background: ${({ theme }) => (theme.highlightedBackground)};
        --toolbar-panel-background: ${({ theme }) => (theme.toolbarPanelBackground)};
        --toolbar-button-background: ${({ theme }) => (theme.toolbarButtonBackground)};
        --toolbar-button-active-background: ${({ theme }) => (theme.toolbarButtonActiveBackground)};
        --popover-background: ${({ theme }) => (theme.popoverBackground)};
        --tooltip-background: ${({ theme }) => (theme.tooltipBackground)};
        --modal-shadow-color: ${({ theme }) => (theme.modalShadowColor)};
        --modal-footer-link-color: ${({ theme }) => (theme.modalFooterLinkColor)};
        --modal-backdrop-color: ${({ theme }) => (theme.modalBackdropColor)};
        --input-border-color: ${({ theme }) => (theme.inputBorderColor)};
        --input-background-color: ${({ theme }) => (theme.inputBackgroundColor)};
        
        --appbar-height: 50px;
        --right-panel-header-height: 41px;
        --drawer-width: 250px;
        --thumbnail-width: 150px;

        --glass-tone-cold: rgb(135 150 255 / 20%);
        --background-gradient: linear-gradient( 44deg,var(--background-gradient-outer-color) 0,var(--background-gradient-middle-color) 20%,var(--background-gradient-inner-color) 45%, var(--background-gradient-middle-color) 80%, var(--background-gradient-outer-color) 100%);
        --background-image: ${({ theme }) => (theme.backgroundImg)};
        --default-background-gradient: ${({ theme }) => (theme.defaultBackgroundGradient)};
        --default-background: ${({ theme }) => (theme.defaultBackground)};
        --pure-bg-color: ${({ theme }) => (theme.pureBgColor)};

        --bg-pattern-default: var(--background-gradient);
        --bg-pattern-red: #682929;
        --bg-pattern-white: #eeeeee;
        --bg-pattern-black: #000000;
        --bg-pattern-gray-cells: url(${bgPatternGrayCells});
        --bg-pattern-white-cells: url(${bgPatternWhiteCells});
        --bg-pattern-red-cells: url(${bgPatternRedCells});

        --emoji-font: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "EmojiOne Color", "Android Emoji"!important;

        background: var(--default-background);
        color: var(--contrast-text);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        user-select: none;
    }  
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: ${({ grow }) => grow ? '1' : ''}    
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: ${({ grow }) => grow ? '1' : ''}    
`;

export const FlexMotionRow = styled(motion.div)`
    display: flex;
    flex-direction: row;
    align-items: center;    
`;

export const FlexMotionColumn = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;    
`;

export const tinyIconSize = 16;
export const smallIconSize = 24;
export const mediumIconSize = 30;
export const largeIconSize = 40;
