import React, { useEffect } from 'react';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import { observer } from 'mobx-react-lite';
import { useProofXStore } from '../../Store/ProofXStore';
import { CommentBody, CommentFooter, FooterButton } from '../DiscussionPanel/Comment';
import { PaperPlaneRight, X } from '@phosphor-icons/react';
import MarkdownView from 'react-showdown';

// #region Styles

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - var(--right-panel-header-height));
    min-width: 450px;
`;

const ScrollWrapper = styled.div`
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    padding: 8px 0;
`;

const NewComment = styled.div`
    border-top: 1px solid var(--control-border);
    background-color: var(--discussion-comment-background);    
    padding: 8px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
`;

const QuestionInput = styled.textarea.attrs(() => ({
    maxLength: 200,
}))`
    border: transparent;
    background-color: transparent;
    font-size: 14px;
    font-family: var(--medium);
    outline: none;
    padding: 0;
    resize: none;
    width: 100%;
`;

const History = styled.div`
    display: flex;
    flex-direction: column;
`;

const Message = styled.div`
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: ${props => props.isUser ? '24px' : '8px'};
    margin-right: ${props => props.isUser ? '8px' : '24px'};
    border-radius: 10px;
    background-color: var(--discussion-comment-background);
    box-shadow: 0 6px 6px -4px var(--glass-shadow-color);    
    color: var(--contrast-text);
    font-size: 14px;
    font-family: var(--medium);
    width: calc(100%-32px);
    user-select: text;
    cursor: text;

    p {
        margin: 0;
        margin-bottom: 8px;
    }
`;

const WaitingIndicator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin-top: 12px;
    margin-left: 8px;
    border-radius: 10px;
    width: 50px;
    background-color: var(--discussion-comment-background);
    box-shadow: 0 6px 6px -4px var(--glass-shadow-color);
`;

const Dot = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--primary-color);
    margin-right: 4px;
    animation: 1s ease-in-out infinite alternate dot;
    &:nth-child(2) {
        animation-delay: 0.2s;
    }
    &:nth-child(3) {
        animation-delay: 0.4s;
    }
    @keyframes dot {
        from {
            transform: scale(0.5);
        }
        to {
            transform: scale(1);
        }
    }    
`;

// #endregion

export default observer(function DiscussionPanel() {
    const cubit = useProofXStore((state) => state.viewer?.aiAssistant);
    const shouldScrollDown = useProofXStore((state) => state.viewer?.aiAssistant?.shouldScrollDown ?? false);
    const strings = useProofXStore((state) => state.strings);
    const scrollAnchorRef = React.useRef(null);

    useEffect(() => {
        if (shouldScrollDown) {
            scrollAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
            cubit.setShouldScrollDown(false);
        }
    }, [shouldScrollDown, cubit]);

    return (
        <Wrapper >
            <ScrollWrapper >
                <SimpleBar style={{ width: 450, maxHeight: '100%', display: 'flex' }}>
                    <History>
                        {cubit.chatHistory.map((item, index) => (
                            <Message key={index} isUser={item.isUser}>
                                <MarkdownView
                                    markdown={item.message}
                                    options={{ tables: true, emoji: true }}
                                />
                            </Message>
                        ))}
                        {cubit.isWaitingForResponse && <WaitingIndicator><Dot /><Dot /><Dot /></WaitingIndicator>}
                        <div ref={scrollAnchorRef} />
                    </History>
                </SimpleBar>
            </ScrollWrapper>
            <NewComment>
                <CommentBody>
                    <QuestionInput value={cubit.question} placeholder={strings.enterYourQuestion}
                        ref={inputRef => inputRef && inputRef.focus()}
                        onChange={(e) => cubit.setQuestion(e.target.value)}
                        onKeyDown={e => { if (e.key === 'Enter') { cubit.askQuestion(); e.preventDefault(); } }}
                    />
                </CommentBody>
                <CommentFooter>
                    {cubit.newCommentEditor?.text
                        ? (
                            <FooterButton link fontSize={10} onClick={() => cubit.cleanNewProjectComment()}>
                                <X size={12} weight='bold' color='var(--button-link-color)' style={{ marginRight: 2 }} />
                                {strings.cancel}
                            </FooterButton>
                        )
                        : <div />}
                    <FooterButton link fontSize={10} onClick={() => cubit.askQuestion()}>
                        {strings.send}
                        <PaperPlaneRight size={12} weight='bold' color='var(--button-link-color)' style={{ marginLeft: 2 }} />
                    </FooterButton>
                </CommentFooter>
            </NewComment>
        </Wrapper>
    );
});
