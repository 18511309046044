import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { FlexRow, smallIconSize } from '../../styles';
import CompareToolsPanel from './CompareToolsPanel';
import DiscussionPanel from './DiscussionPanel/DiscussionPanel';
import FileInfoPanel from './FileInfo/FileInfoPanel';
import RulerPanel from './Ruler/RulerPanel';
import EyedropperPanel from './Eyedropper/EyedropperPanel';
import BarcodeScannerPanel from './BarcodeScanner/BarcodeScannerPanel';
import { useProofXStore } from '../Store/ProofXStore';
import { ViewerModes } from '../Viewer/ViewerCubit';
import { Barcode, ChatCircleText, Eyedropper, Info, IntersectSquare, ChatsCircle, Ruler, Robot } from '@phosphor-icons/react';
import AIChatPanel from './AIAssistant/AIChatPanel';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;    
    height: 100%; 
    overflow: auto;
    position: relative;
`;

const Icon = styled(FlexRow)`
    color: var(--primary-color);
    margin-right: var(--sm);
`;

const Title = styled(FlexRow)`
    padding: 8px;
    border-bottom: 1px solid var(--discussion-asset-active);
    background-color: var(--discussion-asset-hover);
    margin-bottom: var(--sm));
    color: var(--contrast-text);
    font-size: var(--font-size-lg);
    font-family: var(--medium);
    justify-content: space-between;
`;

const modes = {
    [ViewerModes.fileInfo]: {
        titleLabel: 'fileInfo',
        icon: <Info size={smallIconSize} weight='fill' />,
        panel: <FileInfoPanel />,
    },
    [ViewerModes.annotations]: {
        titleLabel: 'assetDiscussion',
        icon: <ChatsCircle size={smallIconSize} />,
        panel: <DiscussionPanel />,
    },
    [ViewerModes.projectDiscussion]: {
        titleLabel: 'projectDiscussion',
        icon: <ChatCircleText size={smallIconSize} weight='fill' />,
        panel: <DiscussionPanel />,
    },
    [ViewerModes.compare]: {
        titleLabel: 'compareTools',
        icon: <IntersectSquare size={smallIconSize} weight='fill' />,
        panel: <CompareToolsPanel />,
    },
    [ViewerModes.ruler]: {
        titleLabel: 'ruler',
        icon: <Ruler size={smallIconSize} />,
        panel: <RulerPanel />,
    },
    [ViewerModes.eyedropper]: {
        titleLabel: 'eyedropper',
        icon: <Eyedropper size={smallIconSize} weight='fill' />,
        panel: <EyedropperPanel />,
    },
    [ViewerModes.barcodeScanner]: {
        titleLabel: 'barcodeScanner',
        icon: <Barcode size={smallIconSize} />,
        panel: <BarcodeScannerPanel />,
    },
    [ViewerModes.aiAssistant]: {
        titleLabel: 'talkToAi',
        icon: <Robot size={smallIconSize} />,
        panel: <AIChatPanel />,
    },
};

export default observer(function RightPanel() {
    const viewer = useProofXStore((state) => state.viewer);
    const strings = useProofXStore((state) => state.strings);

    return (
        <Wrapper>
            <Title>
                <FlexRow>
                    <Icon>{modes[viewer.currentMode]?.icon}</Icon>
                    {strings[modes[viewer.currentMode]?.titleLabel]}
                </FlexRow>
                <div id='header-tool'></div>
            </Title>
            {modes[viewer.currentMode]?.panel}
        </Wrapper>
    );
});
