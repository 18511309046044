import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { FlexRow } from '../../styles';
import { CalendarBlank } from '@phosphor-icons/react';

// #region Styles

const InputWrapper = styled(FlexRow)`
    margin-top: -1px;
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    border-radius: 8px;
    padding: 10px 12px;
`;

const Date = styled.div`
    margin-right: 8px;
`;

// #endregion

export default function DatePicker({ value, placeholder, onChange }) {
    const ref = React.useRef();
    const open = () => {
        ref.current?.setOpen(true);
    };

    return (
        <InputWrapper onClick={open}>
            <Date>
                <ReactDatePicker
                    ref={ref}
                    selected={value}
                    dateFormat='dd MMM yyyy'
                    placeholderText={placeholder}
                    disabledKeyboardNavigation
                    onChange={onChange}
                    className='datepicker'
                />
            </Date>
            <CalendarBlank size={18} color='var(--secondary-color)' />
        </InputWrapper>
    );
}
