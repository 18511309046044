import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import ModalBase, { Footer, FooterButton } from './ModalBase';
import { useProofXStore } from '../Store/ProofXStore';

// #region Styles

const Wrapper = styled.div``;
const Title = styled.div`
    font-size: 18px;
    color: var(--contrast-text);
    font-weight: 500;
    margin: 0;
`;
const Body = styled.div`
    padding: 24px 0px;
    max-width: 600px;
    font-size: 13px;
`;

// #endregion

export default observer(function ConfirmModal({ cubit }) {
    const strings = useProofXStore.getState().strings;
    return cubit && (
        <ModalBase cubit={cubit}>
            <Wrapper>
                <Title>{cubit.title}</Title>
                <Body>
                    {cubit.message}
                </Body>
                <Footer>
                    {cubit.confirmHandler
                        ? (<>
                            <FooterButton link onClick={() => cubit.hide()}>
                                {strings.cancel}
                            </FooterButton>
                            <FooterButton primary onClick={() => cubit.confirmHandler()}>
                                {cubit.confirmText}
                            </FooterButton>
                        </>)
                        : (
                            <FooterButton primary onClick={() => cubit.hide()}>
                                {cubit.confirmText}
                            </FooterButton>
                        )
                    }
                </Footer>
            </Wrapper>
        </ModalBase>
    );
});
