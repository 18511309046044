import { action, computed, makeObservable, observable, override } from 'mobx';
import ModalCubit from './ModalCubit';
import { useProofXStore } from '../Store/ProofXStore';
import { api } from '../Api/ProofXApi';
import log from '../ProofX/Logger';
import { getLocaleCode } from '../../localization/strings';

export default class SpellcheckerModalCubit extends ModalCubit {
    _language = null;
    _languages = {};
    _recognizedText = '';
    _wrongWords = [];
    _error = null;
    _ready = false;

    constructor() {
        super();
        makeObservable(this, {
            _language: observable,
            _languages: observable,
            _recognizedText: observable,
            _wrongWords: observable,
            _error: observable,
            _ready: observable,

            language: computed,
            languageCode: computed,
            languages: computed,
            recognizedText: computed,
            formattedText: computed,
            error: computed,

            setLanguage: action,
            _setLanguage: action,
            _setLanguages: action,
            _setRecognizedText: action,
            _setWrongWords: action,
            _setError: action,
            _setReady: action,

            show: override,
        });
    }

    get language() { return this._languages[this._language]; }
    get languageCode() { return this._language; }
    get languages() { return this._languages ? Object.keys(this._languages).map(k => this._languages[k]) : []; }
    get recognizedText() { return this._recognizedText; }
    get formattedText() { return this._recognizedText ? this._formatText(this._recognizedText) : ''; }
    get ready() { return this._ready; }
    get error() { return this._error; }
    get store() { return useProofXStore.getState(); }

    show() {
        super.show();
        this._setRecognizedText(null);
        this.load();
    }

    async load() {
        try {
            this._setError(null);
            if (!this.languages.length) {
                this._setReady(false);
                await this._fetchLanguagesList();
            }
            await this._checkSpelling();
        } catch (error) {
            log('❌ Spellchecker service error', error);
            this._setError(error);
        }
    }

    setLanguage(language) {
        this._language = Object.keys(this._languages).find(l => this._languages[l] === language);
        this.load();
    }

    _setLanguage(language) {
        this._language = language;
    }

    _setLanguages(languages) {
        this._languages = languages;
    }

    _setRecognizedText(text) {
        this._recognizedText = text;
    }

    _setWrongWords(words) {
        this._wrongWords = words;
    }

    _setError(error) {
        this._error = error;
    }

    _setReady(ready) {
        this._ready = ready;
    }

    async _fetchLanguagesList() {
        try {
            const result = await api.getSpellcheckerLanguages();
            if (result.error) {
                throw (result.error);
            }
            this._setLanguages(result);
            this._setLanguage(getLocaleCode(this.store.strings.getLanguage()) ?? 'en-US');
        } catch (error) {
            log(error);
        }
    }

    async _checkSpelling() {
        if (this.languages.length === 0) return;
        const asset = this.store.assets.main;
        if (!asset) return;

        if (!this._recognizedText) {
            this._setReady(false);
            await this._fetchRecognizedText(asset);
        }
        const wrongWords = await api.spellcheckerGetWrongWords(asset.uid, asset.page, this._language);
        if (wrongWords.error) {
            throw wrongWords.error;
        }

        this._setWrongWords(wrongWords);
        this._setReady(true);
    }

    async _fetchRecognizedText(asset) {
        const recognizedText = await api.spellcheckerRecognizeText(asset.uid, asset.page);
        if (recognizedText.error) {
            throw recognizedText.error;
        }
        this._setRecognizedText(recognizedText);
    }

    _formatText(text) {
        let html = text.replace(/([^.,/#!$%^&*;:{}=\-_`~()\s\d]+)/g, '<span>$1</span>').replace(/\n\r/g, '<br/>');
        const highlighted = this._wrongWords?.map((word) => {
            const escapedWord = word.replaceAll('+', '\\+');
            try {
                return {
                    regexp: new RegExp(`<span>${escapedWord}<\\/span>`, 'g'),
                    word: word,
                };
            } catch (error) {
                console.error('Error in regexp', error);
                return null;
            }
        }).filter(w => w !== null);
        highlighted.forEach(item => {
            html = html.replace(item.regexp, `<span wrong='true'>${item.word}</span>`);
        });
        return html;
    }
}
