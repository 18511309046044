import styled from 'styled-components';

export const Input = styled.input.attrs(({ password }) => ({
    type: password ? 'password' : 'text',
}))`
    color: var(--contrast-text);
    border: 1px solid ${({ error }) => error ? 'var(--error-color)' : 'var(--input-border-color)'};
    background-color: var(--input-background-color);
    border-radius: 8px;
    padding: 12px;
    outline: none;
    font-family: 'Montserrat';
    flex-grow: ${({ grow }) => grow ? 1 : ''};

    ::placeholder {
        color: var(--placeholder-text);
        font-family: 'Montserrat';
    }
`;

export const TextArea = styled.textarea.attrs(() => ({
    rows: '3',
    maxlength: '2000',
}))`
    color: var(--contrast-text);
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    border-radius: 8px;
    padding: 12px;
    outline: none;
    font-family: 'Montserrat';
    flex-grow: ${({ grow }) => grow ? 1 : ''};
    max-width:  ${({ strictWidth }) => `${strictWidth}px` ?? ''};
    min-width:  ${({ strictWidth }) => `${strictWidth}px` ?? ''};
    min-height: 30px;
    ::placeholder {
        color: var(--placeholder-text);
        font-family: 'Montserrat';
    }
`;
