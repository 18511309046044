
import './App.css';
import React from 'react';
import { useProofXStore } from './components/Store/ProofXStore';
import ProofXCubit from './components/ProofX/ProofXCubit';
import ProofX from './components/ProofX/ProofX';

const proofX = new ProofXCubit();
useProofXStore.setState({ proofX });

function App() {
    return (
        <ProofX />
    );
}

export default App;
