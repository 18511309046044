/* global dataLayer */

import { api } from './Api/ProofXApi';
import log from './ProofX/Logger';

const GTM = {
    'Decision-buttons-Approve-button-clicked': { action: 'Decision buttons', label: 'Approve button clicked' },
    'Decision-buttons-Reject-button-clicked': { action: 'Decision buttons', label: 'Reject button clicked' },
    'Decision-buttons-Approve-with-changes-button-clicked': { action: 'Decision buttons', label: 'Approve with changes button clicked' },
    'Annotatate-Annotation-Clicked-on-Annotate': { action: 'Annotate', label: 'Clicked on Annotate' },
    'Annotatate-Point': { action: 'Annotate', label: 'Point' },
    'Annotatate-Square': { action: 'Annotate', label: 'Square' },
    'Annotatate-Circle': { action: 'Annotate', label: 'Circle' },
    'Annotatate-Free': { action: 'Annotate', label: 'Free' },
    'Annotatate-Arrow': { action: 'Annotate', label: 'Arrow' },
    'Annotatate-Pick-Color': { action: 'Annotate', label: 'Pick Color' },
    'Annotatate-Show-Annotations': { action: 'Annotate', label: 'Show Annotations' },
    'Annotatate-Hide-Annotations': { action: 'Annotate', label: 'Hide Annotations' },
    'Annotatate-Annotation-Added': { action: 'Annotate', label: 'Annotation Added' },
    'Annotatate-Annotation-Edited': { action: 'Annotate', label: 'Annotation Edited' },
    'Annotatate-Annotation-Deleted': { action: 'Annotate', label: 'Annotation Deleted' },
    'Discussion-Discussion-Clicked': { action: 'Discussion', label: 'Discussion Clicked' },
    'Discussion-Discussion-Added': { action: 'Discussion', label: 'Discussion Added' },
    'Discussion-Discussion-Edited': { action: 'Discussion', label: 'Discussion Edited' },
    'Discussion-Discussion-Deleted': { action: 'Discussion', label: 'Discussion Deleted' },
    'Compare-Clicked-on-Compare': { action: 'Compare', label: 'Clicked on Compare' },
    'Compare-SideBySide': { action: 'Compare', label: 'SideBySide' },
    'Compare-SideBySide-Difference': { action: 'Compare', label: 'SideBySide Difference' },
    'Compare-Fader': { action: 'Compare', label: 'Fader' },
    'Compare-Difference': { action: 'Compare', label: 'Difference' },
    'Compare-Toggle': { action: 'Compare', label: 'Toggle' },
    'RTUtilities-FileInfo': { action: 'RTUtilities', label: 'File info' },
    'RTUtilities-Ruler': { action: 'RTUtilities', label: 'Ruler' },
    'RTUtilities-EyeDropper': { action: 'RTUtilities', label: 'EyeDropper' },
    'RTUtilities-BarcodeReader': { action: 'RTUtilities', label: 'BarcodeReader' },
    'RTUtilities-PDF-Layers': { action: 'RTUtilities', label: 'PDF Layers' },
    'RTUtilities-SpellCheck': { action: 'RTUtilities', label: 'SpellChecker' },
    'RTUtilities-Download-Asset': { action: 'RTUtilities', label: 'Download Asset' },
    'RTUtilities-AIAssistant': { action: 'RTUtilities', label: 'AI Assistant' },
    'ShareMenu-Clicked-on-Share': { action: 'ShareMenu', label: 'Clicked on Share' },
    'ShareMenu-Generate-Shortlink': { action: 'ShareMenu', label: 'Generate Shortlink' },
    'ShareMenu-Click-Advanced': { action: 'ShareMenu', label: 'Click Advanced in Share' },
    'ShareMenu-Send-External-task': { action: 'ShareMenu', label: 'Send External task' },
    'ImageControls-Zoom-In': { action: 'ImageControls', label: 'Zoom In' },
    'ImageControls-Zoom-Out': { action: 'ImageControls', label: 'Zoom Out' },
    'ImageControls-Fit-to-stage': { action: 'ImageControls', label: 'Fit to stage' },
    'ImageControls-Rotate-Left': { action: 'ImageControls', label: 'Rotate Left' },
    'ImageControls-Rotate-Right': { action: 'ImageControls', label: 'Rotate Right' },
    'Reactions-Reaction-Toggled': { action: 'Reactions', label: 'Reaction Toggled' },
};

export default function sendGTMEvent(key, action, label) {
    if (!window.isGTMEnabled) return;
    const entry = GTM[key];
    if (!entry) {
        console.error('Unknown GTM entry: ' + key);
        return;
    }
    action = action ?? entry.action;
    label = label ?? entry.label;
    const userUid = window.dontChangeAnything ? 'demo' : window.userUid;
    const eventType = window.dontChangeAnything ? 'DemoReviewTool' : 'ReviewTool';

    log(`📝 GTM log: ${eventType}, ${action}, ${label}, ${userUid}`);

    if (!dataLayer) {
        console.error('no dataLayer object found');
        return;
    }
    if (!userUid) {
        console.error('no userUid found');
        return;
    }

    dataLayer.push({
        event: eventType,
        action: action,
        label: label,
        UID: userUid,
    });
    api.logGTMEvent('Event-' + eventType + ';Action-' + action + ';Label-' + label);
};
